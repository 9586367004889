export type BlockEmbed = {
	block: number
	gThumbUrl: string
	thumbUrl: string
	imageUrl: string
	imageWidth: number
	imageHeight: number
	attribution: string
	cats: string[]
	title?: string
	description?: string
	link?: string
	buttonLabel?: string
	videoSrc?: string
}

type CategoryColors = {
	[key: string]: string
}

export type GalleryFilter = 'ASC' | 'DESC' | 'BLOCK_ASC' | 'BLOCK_DESC'
export const GALLERY_FILTERS = {
	asc: 'ASC',
	desc: 'DESC',
	blockAsc: 'BLOCK_ASC',
	blockDesc: 'BLOCK_DESC',
}

export const CATEGORIES = {
	physical: 'physical',
	digital: 'digital',
	literature: 'literature',
	video: 'video',
	music: 'music',
}

export const CATEGORY_COLORS: CategoryColors = {
	physical: '#ae0642',
	digital: '#781b92',
	literature: '#582bc2',
	video: '#2c43b6',
	music: '#0359a6',
}

const ARTISTS = {
	adamkadmon: {
		name: 'AdamKadmon',
		baseUrl: '/art/adamkadmon',
		link: 'https://www.adamkadmon.eu/',
		cats: [CATEGORIES.physical],
	},
	alexschaefer: {
		name: 'Alex Schaefer',
		baseUrl: '/art/alexschaefer',
		link: 'https://paintwithalex.com/',
		cats: [CATEGORIES.physical],
	},
	apex: {
		name: 'Bitcoin Apex Art',
		baseUrl: '/art/apex',
		link: 'https://twitter.com/bitcoin__apex',
		cats: [CATEGORIES.physical],
	},
	asanoha: {
		name: 'Asanoha',
		baseUrl: '/art/asanoha',
		link: 'https://asanoha.gold',
		cats: [CATEGORIES.physical],
	},
	bitcoincartoons: {
		name: 'Bitcoin Cartoons',
		baseUrl: '/art/bitcoincartoons',
		link: 'https://www.bitcoincartoons.io/',
		cats: [CATEGORIES.physical],
	},
	bitko: {
		name: 'Bitko Yinowsky',
		baseUrl: '/art/bitko',
		link: 'https://bitko.cc/',
		cats: [CATEGORIES.physical, CATEGORIES.digital],
	},
	brekkie: {
		name: 'Brekkie von Bitcoin',
		baseUrl: '/art/brekkie',
		link: 'https://www.vonbitcoin.com',
		cats: [CATEGORIES.physical, CATEGORIES.literature],
	},
	chiefmonkey: {
		name: 'Chiefmonkey',
		baseUrl: '/art/chiefmonkey',
		link: 'https://hodlr.rocks',
		cats: [CATEGORIES.physical],
	},
	crayonsmell: {
		name: 'crayonsmell',
		baseUrl: '/art/crayonsmell',
		link: 'https://twitter.com/crayonsmell',
		cats: [CATEGORIES.physical, CATEGORIES.digital],
	},
	cryptograffiti: {
		name: 'cryptograffiti',
		baseUrl: '/art/cryptograffiti',
		link: 'https://cryptograffiti.com',
		cats: [CATEGORIES.physical],
	},
	flomontoya: {
		name: 'Flo Montoya',
		baseUrl: '/art/flomontoya',
		link: 'https://twitter.com/flomontoya_',
		cats: [CATEGORIES.physical],
	},
	fractal: {
		name: 'FractalEncrypt',
		baseUrl: '/art/fractal',
		link: 'https://twitter.com/FractalEncrypt',
		cats: [CATEGORIES.physical, CATEGORIES.literature],
	},
	hackzero: {
		name: 'HackZero',
		baseUrl: '/art/hackzero',
		link: 'https://twitter.com/hack355',
		cats: [CATEGORIES.digital],
	},
	kanuto: {
		name: 'Kanuto',
		baseUrl: '/art/kanuto',
		link: 'https://kanuto.io/art',
		cats: [CATEGORIES.physical],
	},
	madex: {
		name: 'MADEX',
		baseUrl: '/art/madex',
		link: 'https://madex.art/collections',
		cats: [CATEGORIES.physical],
	},
	modeotec: {
		name: 'modeotecArt',
		baseUrl: '/art/modeotec',
		link: 'https://store.modeotec.art',
		cats: [CATEGORIES.physical, CATEGORIES.digital],
	},
	pop: {
		name: 'Proof of Paint',
		baseUrl: '/art/pop',
		link: 'https://proofofpaint.art',
		cats: [CATEGORIES.physical, CATEGORIES.digital],
	},
	rebelmoney: {
		name: 'Rebel Money',
		baseUrl: '/art/rebelmoney',
		link: 'https://www.rebelmoney.art',
		cats: [CATEGORIES.physical],
	},
	richard: {
		name: 'Richard',
		baseUrl: '/art/richard',
		link: 'https://www.youtube.com/playlist?list=PLj9lDruPFtBljCFcLcoD3bd5pGUGmHZWV',
		cats: [CATEGORIES.video, CATEGORIES.music],
	},
	streetcyber: {
		name: 'Street Cyber',
		baseUrl: '/art/streetcyber',
		link: 'https://streetcyber.store',
		cats: [CATEGORIES.physical],
	},
	tipnz: {
		name: 'Tip NZ',
		baseUrl: '/art/tipnz',
		link: 'https://tipnz.notion.site/tipnz/TIP_NZ-02d3fc51c8e149eb853aba3ce8b8ed29',
		cats: [CATEGORIES.video, CATEGORIES.music],
	},
	yegor: {
		name: 'Yegor Petrov',
		baseUrl: '/art/yegor',
		link: 'https://purplenostrich.com',
		cats: [CATEGORIES.digital],
	},
	yonatvaks: {
		name: 'Yonat Vaks',
		baseUrl: '/art/yonatvaks',
		link: 'https://www.yonatvaks.com/',
		cats: [CATEGORIES.physical],
	},
}

const AUTHORS = {
	brekkie: {
		name: 'Brekkie von Bitcoin',
		baseUrl: '/author/brekkie',
		cats: [CATEGORIES.literature],
	},
	dergigi: {
		name: 'Der Gigi',
		baseUrl: '/author/dergigi',
		cats: [CATEGORIES.literature],
	},
	knut: {
		name: 'Knut Svanholm',
		baseUrl: '/author/knut',
		cats: [CATEGORIES.literature],
	},
}

export const BLOCK_EMBEDS: BlockEmbed[] = process.env.REACT_APP_SELF_HOSTED !== 'true'
	? [
		// ADAM KADMON
		{
			block: 808080,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-VIII-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-VIII-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-VIII.jpg`,
			imageWidth: 866,
			imageHeight: 1280,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Religion VIII',
			description: 'Created on block 808080 The Bitcoin Religion VIII like every piece from the series before, has been marked by numerous changes and metamorphoses in its conception. Bitcoin Religion VIII is the second holder of the Orenda coin 008.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 800000,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Coinjoin-Panel-800000-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Coinjoin-Panel-800000-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Coinjoin-Panel-800000.jpg`,
			imageWidth: 1280,
			imageHeight: 854,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Coinjoin Panel 800000',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 797979,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-VI-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-VI-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-VI.jpg`,
			imageWidth: 900,
			imageHeight: 1280,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Religion VI',
			description: 'Created on block 797979 The Bitcoin Religion VI like every piece from the series before, has been marked by numerous changes and metamorphoses in its conception. Bitcoin Religion VI is the first holder of the Orenda coin 006 by itself.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 763636,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-15-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-15-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-15.jpg`,
			imageWidth: 1170,
			imageHeight: 849,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Mandala 15',
			description: 'Created on block 763636 the Bitcoin Mandala 15 embodies a cyberpunk sound built on top of a classical design, reminiscent of ornamentation established from earliest antiquity, and created for the future.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 760000,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-12-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-12-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-12.jpg`,
			imageWidth: 1280,
			imageHeight: 854,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Mandala 12',
			description: 'Created on block 760000 the Bitcoin Mandala 12 embodies a cyberpunk sound built on top of a classical design, reminiscent of ornamentation established from earliest antiquity, and created for the future.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 755555,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-5-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-5-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-5.jpg`,
			imageWidth: 1280,
			imageHeight: 854,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Mandala 5',
			description: 'Created on block 755555 the Bitcoin Mandala 5 embodies a cyberpunk sound built on top of a classical design, reminiscent of ornamentation established from earliest antiquity, and created for the future.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 750000,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-10-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-10-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-10.jpg`,
			imageWidth: 1196,
			imageHeight: 854,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Mandala 10',
			description: 'Created on block 750000 the Bitcoin mandala 10 embodies a cyberpunk sound built on top of a classical design, reminiscent of ornamentation established from earliest antiquity, and created for the future.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 746993,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_We-Are-All-Satoshi-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_We-Are-All-Satoshi-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_We-Are-All-Satoshi.jpg`,
			imageWidth: 985,
			imageHeight: 1280,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'We Are All Satoshi',
			description: 'Created on block 746993 "We are all Satoshi" is an artwork illustrating scenes from the early history of Bitcoin. In chronological order, 6 of the most important events in Bitcoin\'s history are presented, and the main scene is the introduction of the technology to the world by Satoshi himself.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 740000,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-1-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-1-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Mandala-1.jpg`,
			imageWidth: 1190,
			imageHeight: 764,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Mandala 1',
			description: 'Created on block 740000 the Bitcoin mandala 1 embodies a cyberpunk sound built on top of a classical design, reminiscent of ornamentation established from earliest antiquity, and created for the future.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 732999,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Halving-Block-II-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Halving-Block-II-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Halving-Block-II.jpg`,
			imageWidth: 1280,
			imageHeight: 967,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: '210000',
			description: 'Created on block 732999 "210000" is the second artwork from the "Halving blocks" series dedicated to the block where the first Bitcoin halving in history happened.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 725527,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-V-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-V-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-V.jpg`,
			imageWidth: 1014,
			imageHeight: 1230,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Religion V',
			description: 'Created on block 725527. The artwork is dedicated to one of the most significant man who ever lived - Leonardo da Vinci. The laser-engraved text on the handmade paper expresses very well the interconnection between the old days of handmade paper, and the new times of technological progress and mechanization. We\'ve come this far and we are building on top of all the old discoveries humanity has work through. We are at the dawn of the Bitcoin renaissance.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 722722,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-IV-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-IV-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Bitcoin-Religion-IV.jpg`,
			imageWidth: 845,
			imageHeight: 1190,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Religion IV',
			description: 'Created on block 722722 the Bitcoin Religion IV is quite different from the first two. The design changes are easily noticeable, one of the most significant however is the using of acrylic on both sides of the composition, closing off a hollow space that can be reached by removing the two covers at the back which are attached to the panel via magnets. The quote "Vires in Numeris" is gilded with 23 carat gold, just like the Bitcoin symbol on the top.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 717171,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Genesis-Cube-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Genesis-Cube-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Genesis-Cube.jpg`,
			imageWidth: 1280,
			imageHeight: 855,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Genesis Cube',
			description: 'Created on block 717171 the Genesis cube is my first panel of the  "Halving blocks" series . The artworks will be dedicated to the blocks in the network such as 210,000, 420,000, 630,000 which have marked all 4 year cycles so far reducing the reward per block mined with 50%.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 694821,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON-Bitcoin-Religion-III-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON-Bitcoin-Religion-III-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON-Bitcoin-Religion-III.jpg`,
			imageWidth: 837,
			imageHeight: 1100,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Religion III',
			description: 'Created on block 694821 the Bitcoin Religion III panel doesn\'t have many differences from the Genesis panel, the most significant being the overall palette of the artwork and that there are two new Historical Blocks added.',
			link: ARTISTS.adamkadmon.link,
		},
		{
			block: 685167,
			gThumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Genesis-Panel-I-gthumb.jpg`,
			thumbUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Genesis-Panel-I-thumb.jpg`,
			imageUrl: `${ARTISTS.adamkadmon.baseUrl}/ADAM-KADMON_Genesis-Panel-I.jpg`,
			imageWidth: 723,
			imageHeight: 1080,
			attribution: ARTISTS.adamkadmon.name,
			cats: ARTISTS.adamkadmon.cats,
			title: 'Bitcoin Religion I',
			description: 'Created on block 685167 the Bitcoin Religion I panel is the genesis panel presented to the Bitcoin community in Miami in 2021.',
			link: ARTISTS.adamkadmon.link,
		},
		// ALEX SCHAEFER
		{
			block: 138854,
			gThumbUrl: `${ARTISTS.alexschaefer.baseUrl}/ALEX-SCHAFER_Chase-VanNuys-gthumb.jpg`,
			thumbUrl: `${ARTISTS.alexschaefer.baseUrl}/ALEX-SCHAFER_Chase-VanNuys-thumb.jpg`,
			imageUrl: `${ARTISTS.alexschaefer.baseUrl}/ALEX-SCHAFER_Chase-VanNuys.jpg`,
			imageWidth: 1152,
			imageHeight: 905,
			attribution: ARTISTS.alexschaefer.name,
			cats: ARTISTS.alexschaefer.cats,
			title: 'Chase Van Nuys',
			description: 'Oil on canvas',
			link: ARTISTS.alexschaefer.link,
		},
		{
			block: 137867,
			gThumbUrl: `${ARTISTS.alexschaefer.baseUrl}/ALEX-SCHAEFER_Chase-Fire-gthumb.jpg`,
			thumbUrl: `${ARTISTS.alexschaefer.baseUrl}/ALEX-SCHAEFER_Chase-Fire-thumb.jpg`,
			imageUrl: `${ARTISTS.alexschaefer.baseUrl}/ALEX-SCHAEFER_Chase-Fire.jpg`,
			imageWidth: 1080,
			imageHeight: 900,
			attribution: ARTISTS.alexschaefer.name,
			cats: ARTISTS.alexschaefer.cats,
			title: 'Chase',
			description: 'Oil on canvas',
			link: ARTISTS.alexschaefer.link,
		},
		// ASANOHA
		{
			block: 781781,
			gThumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Asanostrich-I-gthumb.jpg`,
			thumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Asanostrich-I-thumb.jpg`,
			imageUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Asanostrich-I.jpeg`,
			imageWidth: 1280,
			imageHeight: 1066,
			attribution: ARTISTS.asanoha.name,
			cats: ARTISTS.asanoha.cats,
			title: 'Asanostrich I',
			description: 'Metallic Gold Ink on Metallic Purple Paper. Created for the Inaugural Nostrica Unconference 2023',
			link: 'https://asanoha.gold/products/asanostrich-i-limited-edition-fine-art-serigraph-8-x-10',
		},
		{
			block: 764216,
			gThumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Exit-Fiat-Enter-Bitcoin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Exit-Fiat-Enter-Bitcoin-thumb.jpg`,
			imageUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Exit-Fiat-Enter-Bitcoin.jpeg`,
			imageWidth: 967,
			imageHeight: 1280,
			attribution: ARTISTS.asanoha.name,
			cats: ARTISTS.asanoha.cats,
			title: 'Exit Fiat: Enter Bitcoin',
			description: 'Cover art for Citadel 21 vol.20 by Asanoha & Andrii Pylypenko',
			link: 'https://www.citadel21.com/vol20',
		},
		{
			block: 749740,
			gThumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Vivant-Reliquiae-Panel-II-gthumb.jpg`,
			thumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Vivant-Reliquiae-Panel-II-thumb.jpg`,
			imageUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Vivant-Reliquiae-Panel-II.jpg`,
			imageWidth: 984,
			imageHeight: 504,
			attribution: ARTISTS.asanoha.name,
			cats: ARTISTS.asanoha.cats,
			title: 'Vivant Reliquiae - Panel II',
			link: `${ARTISTS.asanoha.link}/blogs/originals/vivant-reliquiae-panel-ii`,
		},
		{
			block: 731165,
			gThumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Satoshi-Rx-Daihi-1-gthumb.jpg`,
			thumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Satoshi-Rx-Daihi-1-thumb.jpg`,
			imageUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Satoshi-Rx-Daihi-1.jpeg`,
			imageWidth: 1280,
			imageHeight: 853,
			attribution: ARTISTS.asanoha.name,
			cats: ARTISTS.asanoha.cats,
			title: 'Satoshi Rx Daiji #1',
			link: `${ARTISTS.asanoha.link}/blogs/originals/satoshi-rx-daiji-1-of-3-guy-satoshi-daiji-2-of-3`,
		},
		{
			block: 731164,
			gThumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Guy-Satoshi-Daiji-2-gthumb.jpg`,
			thumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Guy-Satoshi-Daiji-2-thumb.jpg`,
			imageUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Guy-Satoshi-Daiji-2.jpg`,
			imageWidth: 853,
			imageHeight: 1019,
			attribution: ARTISTS.asanoha.name,
			cats: ARTISTS.asanoha.cats,
			title: 'Guy Satoshi Daiji #2',
			link: `${ARTISTS.asanoha.link}/blogs/originals/satoshi-rx-daiji-1-of-3-guy-satoshi-daiji-2-of-3`,
		},
		{
			block: 615851,
			gThumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Viva-La-Remenente-I-gthumb.jpg`,
			thumbUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Viva-La-Remenente-I-thumb.jpg`,
			imageUrl: `${ARTISTS.asanoha.baseUrl}/ASANOHA_Viva-La-Remenente-I.jpg`,
			imageWidth: 795,
			imageHeight: 795,
			attribution: ARTISTS.asanoha.name,
			cats: ARTISTS.asanoha.cats,
			title: 'Viva La Remanente I',
			link: `${ARTISTS.asanoha.link}/blogs/originals/proof-of-work`,
		},
		// APEX
		{
			block: 801511,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_PARADIGM-SHIFT-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_PARADIGM-SHIFT-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_PARADIGM-SHIFT.jpg`,
			imageWidth: 1273,
			imageHeight: 1800,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Paradigm Shift',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1689358282735976449`,
		},
		{
			block: 797606,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_VICTORY-OF-REASON-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_VICTORY-OF-REASON-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_VICTORY-OF-REASON.jpg`,
			imageWidth: 1274,
			imageHeight: 1800,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Victory of Reason',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1678494977150578705`,
		},
		{
			block: 794890,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_PEACEMAKER-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_PEACEMAKER-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_PEACEMAKER.jpg`,
			imageWidth: 1600,
			imageHeight: 1131,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Peacemaker',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1670881834417246215`,
		},
		{
			block: 789868,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_DESPISED-FREEDOM-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_DESPISED-FREEDOM-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_DESPISED-FREEDOM.jpg`,
			imageWidth: 1133,
			imageHeight: 1600,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Despised Freedom',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1660356780570836993`,
		},
		{
			block: 785921,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_BE-SATOSHI-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_BE-SATOSHI-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_BE-SATOSHI.jpg`,
			imageWidth: 1274,
			imageHeight: 1800,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Be Satoshi',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1650983169699598336`,
		},
		{
			block: 781977,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ERA-OF-HOPE-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ERA-OF-HOPE-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ERA-OF-HOPE.jpg`,
			imageWidth: 1274,
			imageHeight: 1800,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Era of Hope',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1642238591798595586`,
		},
		{
			block: 776051,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_CREATION-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_CREATION-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_CREATION.jpg`,
			imageWidth: 2048,
			imageHeight: 1448,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Creation of Human ₿',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1625190307473068050`,
		},
		{
			block: 772647,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_THE-FIRST-PROCLAMATION-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_THE-FIRST-PROCLAMATION-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_THE-FIRST-PROCLAMATION.jpg`,
			imageWidth: 1600,
			imageHeight: 1131,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - The First Proclamation',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1616512847336378381`,
		},
		{
			block: 768978,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_LES-FEMMES-ORANGE-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_LES-FEMMES-ORANGE-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_LES-FEMMES-ORANGE.jpg`,
			imageWidth: 1133,
			imageHeight: 1600,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Les Femmes Orange',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1607776175858020353`,
		},
		{
			block: 766139,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ENLIGHTENMENT-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ENLIGHTENMENT-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ENLIGHTENMENT.jpg`,
			imageWidth: 1274,
			imageHeight: 1800,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Enlightenment',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1625190307473068050`,
		},
		{
			block: 762575,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_UTOPIA-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_UTOPIA-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_UTOPIA.jpg`,
			imageWidth: 1133,
			imageHeight: 1600,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Utopia',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1590768797295968256`,
		},
		{
			block: 759340,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_FREE-ASSANGE-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_FREE-ASSANGE-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_FREE-ASSANGE.jpg`,
			imageWidth: 1133,
			imageHeight: 1600,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Free Assange',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1583157453490774018`,
		},
		{
			block: 756570,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_RUINA-MERCENARII-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_RUINA-MERCENARII-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_RUINA-MERCENARII.jpg`,
			imageWidth: 1800,
			imageHeight: 1273,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Ruina Mercenarii',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1577327687865143296`,
		},
		{
			block: 753177,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_HUMAN-PERFECTION-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_HUMAN-PERFECTION-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_HUMAN-PERFECTION.jpg`,
			imageWidth: 1133,
			imageHeight: 1600,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Human Perfection',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1568767562128793600`,
		},
		{
			block: 747898,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_DEFIATED-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_DEFIATED-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_DEFIATED.jpg`,
			imageWidth: 1080,
			imageHeight: 1521,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Defiated',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1556676282888462337`,
		},
		{
			block: 743174,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_MANIFESTO-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_MANIFESTO-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_MANIFESTO.jpg`,
			imageWidth: 1080,
			imageHeight: 1523,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Manifesto',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1543983916457017347`,
		},
		{
			block: 737414,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ORANGE-PILLED-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ORANGE-PILLED-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_ORANGE-PILLED.jpg`,
			imageWidth: 1077,
			imageHeight: 1600,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Orange Pilled',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1669060177490747393`,
		},
		{
			block: 736512,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_GENERATIONAL-WEALTH-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_GENERATIONAL-WEALTH-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_GENERATIONAL-WEALTH.jpg`,
			imageWidth: 1271,
			imageHeight: 1800,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Generational Wealth',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1693314062497546718`,
		},
		{
			block: 734404,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_APEX-MONEY-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_APEX-MONEY-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_APEX-MONEY.jpg`,
			imageWidth: 1271,
			imageHeight: 1800,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Apex Money',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1669060171971043330`,
		},
		{
			block: 732810,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_BEAUTY-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_BEAUTY-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_BEAUTY.jpg`,
			imageWidth: 1273,
			imageHeight: 1800,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Beauty',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1691886458578079945`,
		},
		{
			block: 731233,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_2140-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_2140-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_2140.jpg`,
			imageWidth: 1132,
			imageHeight: 1600,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - 2140',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1516897829482074113`,
		},
		{
			block: 727021,
			gThumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_STANDARD-gthumb.jpg`,
			thumbUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_STANDARD-thumb.jpg`,
			imageUrl: `${ARTISTS.apex.baseUrl}/Bitcoin-Apex-Art_STANDARD.jpg`,
			imageWidth: 1600,
			imageHeight: 1148,
			attribution: ARTISTS.apex.name,
			cats: ARTISTS.apex.cats,
			title: 'Bitcoin - Standard',
			description: 'Graphite on drawing cardboard',
			link: `${ARTISTS.apex.link}/status/1517464582217994241`,
		},
		// BITCOIN CARTOONS
		{
			block: 740891,
			gThumbUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Ride-The-Lightning-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Ride-The-Lightning-thumb.jpg`,
			imageUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Ride-The-Lightning.jpg`,
			imageWidth: 1155,
			imageHeight: 825,
			attribution: ARTISTS.bitcoincartoons.name,
			cats: ARTISTS.bitcoincartoons.cats,
			title: 'RIDE THE LIGHTNING',
			description: 'Bitcoin Cartoons is bringing the world of Bitcoin to Political Cartoons. Each cartoon in the collection comes equipped with a puzzle-like message, pun, or point of view for which the viewer must solve. Like Political Cartoonists, the artist peers through the bitcoin lens, bringing a sense of humor to the political, cultural, and financial climate of today.',
			link: ARTISTS.bitcoincartoons.link,
		},
		{
			block: 739583,
			gThumbUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Dont-Tread-on-B-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Dont-Tread-on-B-thumb.jpg`,
			imageUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Dont-Tread-on-B.jpg`,
			imageWidth: 1011,
			imageHeight: 874,
			attribution: ARTISTS.bitcoincartoons.name,
			cats: ARTISTS.bitcoincartoons.cats,
			title: 'DONT TREAD ON BITCOIN',
			description: 'Bitcoin Cartoons is bringing the world of Bitcoin to Political Cartoons. Each cartoon in the collection comes equipped with a puzzle-like message, pun, or point of view for which the viewer must solve. Like Political Cartoonists, the artist peers through the bitcoin lens, bringing a sense of humor to the political, cultural, and financial climate of today.',
			link: ARTISTS.bitcoincartoons.link,
		},
		{
			block: 719215,
			gThumbUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Separate-Money-and-State-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Separate-Money-and-State-thumb.jpg`,
			imageUrl: `${ARTISTS.bitcoincartoons.baseUrl}/BITCOIN-CARTOONS_Separate-Money-and-State.jpg`,
			imageWidth: 1015,
			imageHeight: 770,
			attribution: ARTISTS.bitcoincartoons.name,
			cats: ARTISTS.bitcoincartoons.cats,
			title: 'SEPARATE MONEY AND STATE',
			description: 'Bitcoin Cartoons is bringing the world of Bitcoin to Political Cartoons. Each cartoon in the collection comes equipped with a puzzle-like message, pun, or point of view for which the viewer must solve. Like Political Cartoonists, the artist peers through the bitcoin lens, bringing a sense of humor to the political, cultural, and financial climate of today.',
			link: ARTISTS.bitcoincartoons.link,
		},
		// BITKO
		{
			block: 840000,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_halving-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_halving-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_halving.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Halving',
			link: ARTISTS.bitko.link,
		},
		{
			block: 821333,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Plebeian-Market-on-NOSTR-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Plebeian-Market-on-NOSTR-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Plebeian-Market-on-NOSTR.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Plebeian Market on NOSTR',
			link: ARTISTS.bitko.link,
		},
		{
			block: 798007,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_21-world-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_21-world-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_21-world.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: '21 World',
			link: ARTISTS.bitko.link,
		},
		{
			block: 794464,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoin-Halving-Party-2024-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoin-Halving-Party-2024-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoin-Halving-Party-2024.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Bitcoin Halving Party 2024',
			link: ARTISTS.bitko.link,
		},
		{
			block: 794254,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Plebeian-Market-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Plebeian-Market-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Plebeian-Market.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Plebeian Market',
			link: ARTISTS.bitko.link,
		},
		{
			block: 791645,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Running-NOSTR-2-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Running-NOSTR-2-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Running-NOSTR-2.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Running NOSTR 2',
			link: ARTISTS.bitko.link,
		},
		{
			block: 784273,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoiner-Family-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoiner-Family-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoiner-Family.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Bitcoiner Family',
			link: ARTISTS.bitko.link,
		},
		{
			block: 772135,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Kanuto-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Kanuto-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Kanuto.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Kanuto',
			link: ARTISTS.bitko.link,
		},
		{
			block: 770213,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Genesis-Day-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Genesis-Day-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Genesis-Day.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Genesis Day',
			link: ARTISTS.bitko.link,
		},
		{
			block: 769338,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Running-BTC-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Running-BTC-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Running-BTC.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Running BTC',
			link: ARTISTS.bitko.link,
		},
		{
			block: 768906,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Christmas-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Christmas-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Christmas.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Christmas',
			link: ARTISTS.bitko.link,
		},
		{
			block: 765656,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_13-percenters-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_13-percenters-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_13-percenters.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: '13%ers',
			link: ARTISTS.bitko.link,
		},
		{
			block: 752198,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Baltic-Honeybadger-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Baltic-Honeybadger-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Baltic-Honeybadger.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Baltic Honeybadger',
			link: ARTISTS.bitko.link,
		},
		{
			block: 751591,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Orange-Pill-Island-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Orange-Pill-Island-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Orange-Pill-Island.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Orange Pill Island',
			link: ARTISTS.bitko.link,
		},
		{
			block: 750324,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_We-Are-All-Hodlonaut-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_We-Are-All-Hodlonaut-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_We-Are-All-Hodlonaut.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'We Are All Hodlonaut',
			link: ARTISTS.bitko.link,
		},
		{
			block: 747943,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Felix-Arba-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Felix-Arba-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Felix-Arba.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Felix Arba',
			link: ARTISTS.bitko.link,
		},
		{
			block: 716779,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_8-21-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_8-21-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_8-21.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: '8/21',
			link: ARTISTS.bitko.link,
		},
		{
			block: 663744,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoin-Friends-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoin-Friends-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_Bitcoin-Friends.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Bitcoin Friends',
			link: ARTISTS.bitko.link,
		},
		{
			block: 657234,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_dodollar-printer-go-brrr-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_dodollar-printer-go-brrr-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_dodollar-printer-go-brrr.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Dodollar Printer Go Brrr',
			link: ARTISTS.bitko.link,
		},
		{
			block: 653436,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_the-pin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_the-pin-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_the-pin.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'The Pin',
			link: ARTISTS.bitko.link,
		},
		{
			block: 624883,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_BRRR-Sketch-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_BRRR-Sketch-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_BRRR-Sketch.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'BRRR (sketch)',
			link: ARTISTS.bitko.link,
		},
		{
			block: 598046,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_stack-sats-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_stack-sats-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_stack-sats.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Stack Sats',
			link: ARTISTS.bitko.link,
		},
		{
			block: 597304,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_honey-badger-dont-care-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_honey-badger-dont-care-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_honey-badger-dont-care.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Honey Badger Dont Care',
			link: ARTISTS.bitko.link,
		},
		{
			block: 587038,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_fiat-boneyard-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_fiat-boneyard-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_fiat-boneyard.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Fiat Boneyard',
			link: ARTISTS.bitko.link,
		},
		{
			block: 578096,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_gobbling-up-fiat-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_gobbling-up-fiat-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_gobbling-up-fiat.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Gobbling Up Fiat',
			link: ARTISTS.bitko.link,
		},
		{
			block: 563176,
			gThumbUrl: `${ARTISTS.bitko.baseUrl}/BY_apex-predator-gthumb.jpg`,
			thumbUrl: `${ARTISTS.bitko.baseUrl}/BY_apex-predator-thumb.jpg`,
			imageUrl: `${ARTISTS.bitko.baseUrl}/BY_apex-predator.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.bitko.name,
			cats: ARTISTS.bitko.cats,
			title: 'Apex Predator',
			link: ARTISTS.bitko.link,
		},
		// BREKKIE
		{
			block: 800999,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Reborn-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Reborn-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Reborn.jpg`,
			imageWidth: 908,
			imageHeight: 960,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'Reborn',
			description: 'Carrara Marble, Copper leaf, Opendime. "Reborn" is inspired by my Bitcoin journey and the many other people whose lives have been changed by Bitcoin. I\'ve carved the hand in the “Gyan Mudra” or the “Mudra of Wisdom or Knowledge”, with an Opendime grasped between thumb and index finger alluding to the pursuit of Bitcoin knowledge. The hand emerges from rough, choppy water, and I\'ve set the hand against an archway, through which, the copper leaf hints at the bright orange future made possible by Bitcoin.',
			link: ARTISTS.brekkie.link,
		},
		{
			block: 800888,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Everything-There-Is-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Everything-There-Is-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Everything-There-Is.jpg`,
			imageWidth: 768,
			imageHeight: 1024,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'Everything There Is',
			description: 'Colorado Yule marble, Carrara marble, reclaimed slate and 24k gold leaf. This is my largest sculpture to date. It is visual representation of the idea or meme created by Knut Svanholm- that the potential of Bitcoin can be understood as "Everything divided by 21 Million."  To delve deeper into this idea, I highly suggest reading Knut\'s book.',
			link: ARTISTS.brekkie.link,
		},
		{
			block: 800777,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself-Bronze-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself-Bronze-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself-Bronze.jpg`,
			imageWidth: 898,
			imageHeight: 898,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'Free Yourself (Bronze)',
			description: 'Bronze replica of my first sculpture in marble. These are limited to 21 numbered pieces.',
			link: ARTISTS.brekkie.link,
		},
		{
			block: 800666,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself-No2-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself-No2-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself-No2.jpg`,
			imageWidth: 835,
			imageHeight: 1113,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'Free Yourself No. 2',
			description: 'Texas Gold limestone. This piece is a larger variation on my first sculpture in marble, which for me represented both my personal journey to free myself from creative constraints as well as Bitcoin\'s potential to free humanity from the shackles of fiat money. As with "Free Yourself" No. 1, the Bitcoin logo is presented in the act of freeing itself from the block of stone.',
			link: ARTISTS.brekkie.link,
		},
		{
			block: 800555,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Vires-in-Numeris-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Vires-in-Numeris-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Vires-in-Numeris.jpg`,
			imageWidth: 836,
			imageHeight: 762,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'Vires in Numeris',
			description: 'Reclaimed slate and 24k gold leaf. Strength in numbers, a motto of Bitcoin and Bitcoiners alluding to the decentralized, peer-to-peer nature of Bitcoin and the power derived therein. Hand-lettered.',
			link: ARTISTS.brekkie.link,
		},
		{
			block: 800444,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Serif-Bitcoin-Logo-No2-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Serif-Bitcoin-Logo-No2-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Serif-Bitcoin-Logo-No2.jpg`,
			imageWidth: 794,
			imageHeight: 1100,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'Serif Bitcoin Logo No. 2',
			description: 'Reclaimed slate and copper leaf. My own variation on the Bitcoin symbol. Hand lettered.',
			link: ARTISTS.brekkie.link,
		},
		{
			block: 770871,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_The-Great-Wave-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_The-Great-Wave-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_The-Great-Wave.jpg`,
			imageWidth: 1280,
			imageHeight: 1250,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'The Great Wave Off Nakamoto',
			description: 'Hand carved in slate with 24k gold leaf accents, it is intended as an expression of hope and self determination in the turbulent sea of fiat derived injustice that is all around us. Based off Hokusai\'s famous woodblock print "The Great Wave off Kanagawa"',
			link: ARTISTS.brekkie.link,
		},
		{
			block: 753111,
			gThumbUrl: `${AUTHORS.brekkie.baseUrl}/BREKKIE_Rhyming-Bitcoin-gthumb.jpg`,
			thumbUrl: `${AUTHORS.brekkie.baseUrl}/BREKKIE_Rhyming-Bitcoin-thumb.jpg`,
			imageUrl: `${AUTHORS.brekkie.baseUrl}/BREKKIE_Rhyming-Bitcoin.jpg`,
			imageWidth: 1006,
			imageHeight: 1360,
			attribution: AUTHORS.brekkie.name,
			cats: [CATEGORIES.literature],
			title: 'Rhyming Bitcoin',
			description: 'This book seeks to take readers of all ages on a journey of imagination, to leave them feeling more hopeful, and hopefully, more curious about Bitcoin. Illustrated by Chilean Bitcoin Artist Florencia Montoya.',
			link: 'https://www.amazon.com/dp/B0BKMPPTM1',
			buttonLabel: 'Get the Book',
		},
		{
			block: 728267,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Awakening-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Awakening-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Awakening.jpg`,
			imageWidth: 1280,
			imageHeight: 1039,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'Awakening',
			description: 'Carved from Calacatta marble from Carrara, Italy, Awakening is meant to capture the moment of epiphany, when the orange pill hits and you finally understand Bitcoin.',
			link: ARTISTS.brekkie.link,
		},
		{
			block: 714773,
			gThumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself-gthumb.jpg`,
			thumbUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself-thumb.jpg`,
			imageUrl: `${ARTISTS.brekkie.baseUrl}/BREKKIE_Free-Yourself.jpg`,
			imageWidth: 1280,
			imageHeight: 1038,
			attribution: ARTISTS.brekkie.name,
			cats: [CATEGORIES.physical],
			title: 'Free Yourself',
			description: 'A physical embodiment of Bitcoin\'s Proof of Work. "Free Yourself" is carved from Calacatta marble from Carrara, Italy, and represents the struggle and transformation every Bitcoiner goes through to free themselves from the mental and financial shackles of the fiat system.',
			link: ARTISTS.brekkie.link,
		},
		// CHIEF MONKEY
		{
			block: 788480,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Layered-Money-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Layered-Money-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Layered-Money.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Layered Money Land',
			description: 'What happens when a censorship free communications protocol meets a censorship free value protocol? In Layered Money Land The map is the territory, the Herds are free to roam and the Beak shall inherit the earth',
			link: `${ARTISTS.chiefmonkey.link}/humanity/layered-money-land`,
		},
		{
			block: 779453,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Revolutionary-Rendezvous-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Revolutionary-Rendezvous-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Revolutionary-Rendezvous.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Revolutionary Rendezvous',
			description: 'What happens when the Nostrich sticks its head down the Rabbit hole? This is my first piece of Bitcoin Nostr art and another collaboration with mosaic.rocks.',
			link: `${ARTISTS.chiefmonkey.link}/humanity/revolutionary-rendezvous`,
		},
		{
			block: 758057,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_We-Are-All-Hodlonaut-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_We-Are-All-Hodlonaut-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_We-Are-All-Hodlonaut.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: '#WeAreAllHodlonaut',
			description: '3 pieces of laser cut bitcoin art in support of the space cat... one very humble and brave bitcoiner... probably the first and last time I\'ll ever laser cut a cock and bollocks... 100% profits went to Open Sats Defending Bitcoin.',
			link: `${ARTISTS.chiefmonkey.link}/humanity/weareallhodlonaut`,
		},
		{
			block: 740902,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Forever-Laura-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Forever-Laura-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Forever-Laura.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Forever Laura',
			link: `${ARTISTS.chiefmonkey.link}/memes/forever-laura`,
		},
		{
			block: 728831,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_SHAmoney-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_SHAmoney-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_SHAmoney.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'SHAmoney',
			description: 'Bitcoin is a religion of human value and far greater than its creator... When ideas take on a life of their own, they become greater than the humans behind them.',
			link: `${ARTISTS.chiefmonkey.link}/humanity/shamanic`,
		},
		{
			block: 719475,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Divine-Calling-Maximalist-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Divine-Calling-Maximalist-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Divine-Calling-Maximalist.jpg`,
			imageWidth: 675,
			imageHeight: 1200,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Divine Calling - The Maximalist',
			description: 'The moment when the penny really drops with any regular pleb… it\'s when you realise that you have in your possession something that is more than than stocks\'n\'shares, more than a digital asset, more than money. It\'s the moment you realise the stark contrast between what you were once told you must have, to what you now can chose to have. You have been consumed by the orange pill passed on by Satoshi... it is now your responsibility to pass it on to others... this is your Divine Calling.',
			link: `${ARTISTS.chiefmonkey.link}/humanity/divine-calling`,
		},
		{
			block: 719178,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Divine-Calling-Satoshi-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Divine-Calling-Satoshi-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Divine-Calling-Satoshi.jpg`,
			imageWidth: 675,
			imageHeight: 1200,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Divine Calling - Satoshi',
			description: 'The moment the universal consciousness entered Satoshi, filling him with the purest orange intelligence... this was our divine intervention, it came from us. This is the moment when everything converged, when he stood on the shoulders of giants that came before him, the knowledge of the cypherpunks, the previous attempts at digital money, the economics, and the decentralisation of record, space and time.',
			link: `${ARTISTS.chiefmonkey.link}/humanity/divine-calling`,
		},
		{
			block: 707114,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_SHAmanic-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_SHAmanic-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_SHAmanic.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'SHAmanic',
			description: 'SHAmanic was a collaboration with Tomer Strolight for his book to explore what bitcoin means to humanity. SHAmoney was a Bitcoin is a religion of human value and far greater than its creator. When ideas take on a life of their own, they become greater than the humans behind them.',
			link: `${ARTISTS.chiefmonkey.link}/humanity/shamanic`,
		},
		{
			block: 688395,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_NGU-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_NGU-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_NGU.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'NgU',
			description: 'Number go up technology.',
			link: `${ARTISTS.chiefmonkey.link}/memes/ngu`,
		},
		{
			block: 686222,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Dip-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Dip-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Dip.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Dip a Dee Doo Dah',
			description: 'The party\'s just getting started... A Prototype - a 1-off... I will not be making anymore simply because I have too many ideas I want to crack on with.',
			link: `${ARTISTS.chiefmonkey.link}/satoshi-series/dip-a-dee-doo-dah`,
		},
		{
			block: 682085,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Mana-From-Heaven-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Mana-From-Heaven-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Mana-From-Heaven.jpg`,
			imageWidth: 675,
			imageHeight: 1200,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Mana from Heaven',
			description: 'Inspired by The Emerald Tablet, another mysterious gift that was also said to contain the secrets of the universe.',
			link: `${ARTISTS.chiefmonkey.link}/rocks`,
		},
		{
			block: 680999,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Infinite-Potential-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Infinite-Potential-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Infinite-Potential.jpg`,
			imageWidth: 1135,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Psychedelic Satoshi',
			description: 'Pretty Satoshi enjoyed mushrooms.',
			link: `${ARTISTS.chiefmonkey.link}/psychedelic-satoshi`,
		},
		{
			block: 679366,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Sacred-Hope-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Sacred-Hope-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Sacred-Hope.jpg`,
			imageWidth: 1200,
			imageHeight: 675,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Sacred Hope',
			description: 'With hope on the horizon Satoshi wanders through the Sacred Torii gate of human value.',
			link: `${ARTISTS.chiefmonkey.link}/rocks`,
		},
		{
			block: 677024,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Bitcoin-Bavacakra-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Bitcoin-Bavacakra-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Bitcoin-Bavacakra.jpg`,
			imageWidth: 769,
			imageHeight: 1200,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'The Bitcoin Bhavacakra',
			description: 'This was a collaboration between Yonat Vaks & Chiefmonkey & mosaic.rocks. Legend has it that the Bhavacakra, also known as the Wheel of Life, was created by the Buddha himself. It depicts the journey of the human soul through the trappings of the Samsara (cyclic existence of life) until they are able to ascend to Nirvana. In our interpretation we have applied the teachings of Satoshi and shown how humans are trapped in the fiat samsara and how we may escape, evolve and ascend as a species through the adoption of a bitcoin standard.',
			link: 'https://hodlr.rocks/humanity/the-bitcoin-bhavacakra',
		},
		{
			block: 659799,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Relentless-Optimism-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Relentless-Optimism-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Relentless-Optimism.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Relentless Optimism',
			description: 'With a final beat of its wings the phoenix pulled away from the burning embers of the fiat monetary system. This was the first piece bitcoin art ever sold on a traditional styled auction powered by Lightning. The platform in question was Scare.city... I gave them idea to do auctions using Lightning as the mechanism to bid and to ensure skin-in-the-game to keep the participants honest... I then presented the idea to a dozen of the most prominent artists at the time to get things going. Chris and Aryan made me honorary founder. It was my first collaboration with mosaic.rocks.',
			link: 'https://x.com/HodlrDotRocks/status/1334492800071716866',
		},
		{
			block: 653934,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Invisible-Hand-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Invisible-Hand-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Invisible-Hand.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Invisible Hand',
			description: 'Humble Satoshi had finished tending his land and took a few moments to enjoy the view of his creation... in the distance he saw the new range of a metropolis cityscape emerging Bitcoin had started to enter fully into the consciousnesses of the masses and was beginning to be acknowledged by the all consuming city slickers.',
			link: `${ARTISTS.chiefmonkey.link}/satoshi-series/the-invisible-hand-series-of-12`,
		},
		{
			block: 648289,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Fuck-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Fuck-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Fuck.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Fuck The Fucking Fuckers',
			description: 'Fuck\'em... the dirty rotten lot of \'em',
			link: `${ARTISTS.chiefmonkey.link}/laser-cut-crypto-craft/fuck-the-fucking-fuckers`,
		},
		{
			block: 636708,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Omnipotence-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Omnipotence-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Omnipotence.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Omnipotence No Difference',
			description: 'Bitcoin is omnipotence it knows no difference... It has no boundaries... It has no prejudice... It protects privacy... It enables self sovereignty… It\'s the last bastion of humanity... This piece is about the equality of bitcoin and the human spirit.',
			link: `${ARTISTS.chiefmonkey.link}/cypherpunk-series/omnipotence-no-difference`,
		},
		{
			block: 628127,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Cryptoanarchy-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Cryptoanarchy-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Cryptoanarchy.jpg`,
			imageWidth: 1200,
			imageHeight: 450,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Cryptoanarchy',
			description: 'The pill for socioeconomic ill... this is a two sided piece.',
			link: `${ARTISTS.chiefmonkey.link}/cypherpunk-series/cryptoanarchy-bitcoin-pill`,
		},
		{
			block: 626084,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Whistleblower-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Whistleblower-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Whistleblower.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Whistleblower',
			description: 'Whistle blowing is not a crime… unless you’re telling the truth.',
			link: `${ARTISTS.chiefmonkey.link}/cypherpunk-series/whistleblower`,
		},
		{
			block: 624326,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Sousveillance-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Sousveillance-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Sousveillance.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Sousveillance',
			description: 'This is "Sousveillance" and depicts a near future where we have turned surveillance on its head... we keep an eye on the establishment... we have privacy they don\'t have secrecy.',
			link: `${ARTISTS.chiefmonkey.link}/cypherpunk-series/sousveillance`,
		},
		{
			block: 614302,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Beigeme-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Beigeme-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Beigeme.jpg`,
			imageWidth: 1024,
			imageHeight: 768,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Beigeme - The Beige Regime',
			description: 'Mass surveillance is literally draining the colour from life... The more people allow their private lives to be monitored by the big corporations, the more they feed their live personal data into the advertising networks, the easier we are making it for corporations and the deep state to mould and manipulate society into buying certain products or believing certain narratives of propaganda.',
			link: `${ARTISTS.chiefmonkey.link}/cypherpunk-series/beigeme-the-beige-regime`,
		},
		{
			block: 612990,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Truth-Will-Out-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Truth-Will-Out-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Truth-Will-Out.jpg`,
			imageWidth: 1000,
			imageHeight: 761,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Truth Will Out',
			description: 'In the west it is a common misbelief that we have free speech and we do not have political prisoners. This piece is a record of 3 three courageous people who have categorically exposed the truth and have, as a result, been punished by their Governments and paid with their freedom.',
			link: `${ARTISTS.chiefmonkey.link}/cypherpunk-series/truth-will-out`,
		},
		{
			block: 580648,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Goes-Down-the-Rabbit-Hole-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Goes-Down-the-Rabbit-Hole-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Goes-Down-the-Rabbit-Hole.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Satoshi Goes Down the Rabbit Hole',
			description: 'Capturing the moments before the birth of the genius known as bitcoin... The egg shape represents the birth of great things and new ideas... The laptop preceding him represents the only tool he needed to learn and to create as he drifted into the realm of universal consciousness.',
			link: `${ARTISTS.chiefmonkey.link}/satoshi-series/satoshi-goes-down-the-rabbit-hole-series-of-10`,
		},
		{
			block: 569857,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Rides-the-Waves-of-Change-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Rides-the-Waves-of-Change-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Rides-the-Waves-of-Change.jpg`,
			imageWidth: 1200,
			imageHeight: 896,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Satoshi Rides the Waves of Change',
			description: 'After the correction, developers carried on doing what they do best, improve and innovate... in March 2019 the fruits of the community began to be realised... it is now impossible to ignore the tsunami of blockchain innovation that is heading towards society.',
			link: `${ARTISTS.chiefmonkey.link}/satoshi-series/satoshi-rides-the-waves-of-change-series-of-10`,
		},
		{
			block: 557140,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Summons-the-Bulls-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Summons-the-Bulls-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Satoshi-Summons-the-Bulls.jpg`,
			imageWidth: 1000,
			imageHeight: 750,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Satoshi Summons the Bulls',
			description: 'After the hype and the correction, in the closing weeks of 2018 capitulation set in and the market saw an unexpected additional big drop... Satoshi, pointing towards this sudden decrease in price summons the bulls... NOTE: No hodlrs were harmed during the correction and capitulation.',
			link: `${ARTISTS.chiefmonkey.link}/satoshi-series/satoshi-goes-down-the-rabbit-hole-series-of-10`,
		},
		{
			block: 549826,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Society-3-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Society-3-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Society-3.jpg`,
			imageWidth: 1009,
			imageHeight: 728,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Society 3.0',
			description: 'Privacy is the foundation upon which the 4 pillars of an open, fair, and decentralised society sit... here they are: Communication, Law, Production, Finance... Privacy is at the root and is represented by the meditating Buddha.',
			link: `${ARTISTS.chiefmonkey.link}/humanity/society-3-0-privacy-the-4-pillars-of-a-decentralized-society`,
		},
		{
			block: 548287,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Ginko-Crypto-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Ginko-Crypto-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Ginko-Crypto.jpg`,
			imageWidth: 1200,
			imageHeight: 900,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Ginko Crypto',
			description: 'The Ginko tree is famed for living 1000\'s of years... the leaves signify a change... over the horizon we see the change coming... the onlooker (satoshi) watches his work unfurl... the mountains might look familiar, they\'re the bitcoin price over the last year.',
			link: `${ARTISTS.chiefmonkey.link}/satoshi-series/gingko-crypto`,
		},
		{
			block: 544378,
			gThumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Backgammon-gthumb.jpg`,
			thumbUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Backgammon-thumb.jpg`,
			imageUrl: `${ARTISTS.chiefmonkey.baseUrl}/CHIEFMONKEY_Backgammon.jpg`,
			imageWidth: 1000,
			imageHeight: 750,
			attribution: ARTISTS.chiefmonkey.name,
			cats: ARTISTS.chiefmonkey.cats,
			title: 'Backgammoon - bitcoin backgammon',
			description: 'It\'s bitcoin vs shitcoin backgammon... aka Backgammoon.',
			link: `${ARTISTS.chiefmonkey.link}/laser-cut-crypto-craft/backgammoon-the-crypto-backgammon-set`,
		},
		// CRAYONSMELL
		{
			block: 785829,
			gThumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_age-of-fiat-gthumb.jpg`,
			thumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_age-of-fiat-thumb.jpg`,
			imageUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_age-of-fiat.jpg`,
			imageWidth: 2048,
			imageHeight: 1122,
			attribution: ARTISTS.crayonsmell.name,
			cats: ARTISTS.crayonsmell.cats,
			title: 'Age of Fiat',
			link: `${ARTISTS.crayonsmell.link}/status/1648001289526059008`,
		},
		{
			block: 722222,
			gThumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_hodler-gthumb.jpg`,
			thumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_hodler-thumb.jpg`,
			imageUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_hodler.jpg`,
			imageWidth: 1280,
			imageHeight: 1600,
			attribution: ARTISTS.crayonsmell.name,
			cats: ARTISTS.crayonsmell.cats,
			title: 'Hodler',
			link: 'https://habel.net',
		},
		{
			block: 684213,
			gThumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_mandala-wood-gthumb.jpg`,
			thumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_mandala-wood-thumb.jpg`,
			imageUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_mandala-wood.jpg`,
			imageWidth: 1600,
			imageHeight: 1200,
			attribution: ARTISTS.crayonsmell.name,
			cats: ARTISTS.crayonsmell.cats,
			title: 'Mandala - Wood',
			link: 'https://habel.net',
		},
		{
			block: 684142,
			gThumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_mandala-gthumb.jpg`,
			thumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_mandala-thumb.jpg`,
			imageUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_mandala.jpg`,
			imageWidth: 1600,
			imageHeight: 1600,
			attribution: ARTISTS.crayonsmell.name,
			cats: ARTISTS.crayonsmell.cats,
			title: 'Mandala',
			link: 'https://habel.net',
		},
		{
			block: 554734,
			gThumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_bull-vs-bear-gthumb.jpg`,
			thumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_bull-vs-bear-thumb.jpg`,
			imageUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_bull-vs-bear.jpg`,
			imageWidth: 1200,
			imageHeight: 1500,
			attribution: ARTISTS.crayonsmell.name,
			cats: ARTISTS.crayonsmell.cats,
			title: 'Bull vs Bear',
			link: 'https://habel.net',
		},
		{
			block: 486525,
			gThumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_moon-gthumb.jpg`,
			thumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_moon-thumb.jpg`,
			imageUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_moon.jpg`,
			imageWidth: 1280,
			imageHeight: 1024,
			attribution: ARTISTS.crayonsmell.name,
			cats: ARTISTS.crayonsmell.cats,
			title: 'Moon',
			link: 'https://habel.net',
		},
		{
			block: 324080,
			gThumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_The-Slaying-of-the-Bearwhale-gthumb.jpg`,
			thumbUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_The-Slaying-of-the-Bearwhale-thumb.jpg`,
			imageUrl: `${ARTISTS.crayonsmell.baseUrl}/crayonsmell_The-Slaying-of-the-Bearwhale.jpg`,
			imageWidth: 900,
			imageHeight: 1118,
			attribution: ARTISTS.crayonsmell.name,
			cats: ARTISTS.crayonsmell.cats,
			title: 'The Slaying of the Bearwhale',
			description: 'Piece commissioned to commemorate the slaying of the Bitfinex bearwhale.',
			link: `${ARTISTS.crayonsmell.link}/status/1577877987445317634`,
		},
		// CRYPTOGRAFFITI
		{
			block: 630000,
			gThumbUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Black-Swan-gthumb.jpg`,
			thumbUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Black-Swan-thumb.jpg`,
			imageUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Black-Swan.jpg`,
			imageWidth: 648,
			imageHeight: 558,
			attribution: ARTISTS.cryptograffiti.name,
			cats: ARTISTS.cryptograffiti.cats,
			title: 'Black Swan',
			link: ARTISTS.cryptograffiti.link,
		},
		{
			block: 420000,
			gThumbUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Running-bitcoin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Running-bitcoin-thumb.jpg`,
			imageUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Running-bitcoin.jpg`,
			imageWidth: 1920,
			imageHeight: 1749,
			attribution: ARTISTS.cryptograffiti.name,
			cats: ARTISTS.cryptograffiti.cats,
			title: 'Running Bitcoin',
			link: ARTISTS.cryptograffiti.link,
		},
		{
			block: 210000,
			gThumbUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Orange-Pilled-gthumb.jpg`,
			thumbUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Orange-Pilled-thumb.jpg`,
			imageUrl: `${ARTISTS.cryptograffiti.baseUrl}/CRYPTOGRAFFITI_Orange-Pilled.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.cryptograffiti.name,
			cats: ARTISTS.cryptograffiti.cats,
			title: 'Orange Pilled',
			link: ARTISTS.cryptograffiti.link,
		},
		// DER GIGI
		{
			block: 785142,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/coal-miners-btc-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/coal-miners-btc-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/coal-miners-btc.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoins Are Not Mined, They Are Issued over Time',
			link: 'https://dergigi.com/2023/04/12/bitcoins-are-not-mined-they-are-issued-over-time/',
			buttonLabel: 'Read Now',
		},
		{
			block: 781847,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/meme-wars-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/meme-wars-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/meme-wars.png`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin\'s Meme Wars',
			link: 'https://dergigi.com/2023/03/21/bitcoin-s-meme-wars/',
			buttonLabel: 'Read Now',
		},
		{
			block: 780464,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/prism-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/prism-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/prism.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Lightning Prisms',
			link: 'https://dergigi.com/2023/03/12/lightning-prisms/',
			buttonLabel: 'Read Now',
		},
		{
			block: 767992,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/vew-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/vew-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/vew.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'A Vision for a Value-Enabled Web',
			link: 'https://dergigi.com/2022/12/18/a-vision-for-a-value-enabled-web/',
			buttonLabel: 'Read Now',
		},
		{
			block: 765645,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/cave-painting-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/cave-painting-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/cave-painting.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin Is the Rediscovery of Money',
			link: 'https://dergigi.com/2022/12/02/bitcoin-is-the-rediscovery-of-money/',
			buttonLabel: 'Read Now',
		},
		{
			block: 765335,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/coin-tree-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/coin-tree-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/coin-tree.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'The Other Side of the Coin',
			description: 'Computational proof of the chronological order of transactions',
			link: 'https://dergigi.com/2022/11/30/the-other-side-of-the-coin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 763992,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/crypto-bros-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/crypto-bros-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/crypto-bros.jpg`,
			imageWidth: 1000,
			imageHeight: 563,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Dear Crypto & Fiat Bros',
			description: 'An open letter to the confused and dismissive.',
			link: 'https://dergigi.com/2022/11/19/dear-crypto-fiat-bros/',
			buttonLabel: 'Read Now',
		},
		{
			block: 756744,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/goldmaedchen-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/goldmaedchen-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/goldmaedchen.jpg`,
			imageWidth: 1000,
			imageHeight: 563,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin Is Digital Scarcity',
			link: 'https://dergigi.com/2022/10/02/bitcoin-is-digital-scarcity/',
			buttonLabel: 'Read Now',
		},
		{
			block: 753597,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/adams-back-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/adams-back-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/adams-back.jpg`,
			imageWidth: 1000,
			imageHeight: 563,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Cryptography is Not Enough',
			description: 'A long(ish) Speech on Paradoxes and Antinomies',
			link: 'https://dergigi.com/2022/09/10/cryptography-is-not-enough/',
			buttonLabel: 'Read Now',
		},
		{
			block: 746126,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/old-satoshi-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/old-satoshi-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/old-satoshi.jpg`,
			imageWidth: 1035,
			imageHeight: 1035,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'I Miss the Old Satoshi',
			link: 'https://dergigi.com/2022/07/23/i-miss-the-old-satoshi/',
			buttonLabel: 'Read Now',
		},
		{
			block: 742573,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-terminology-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-terminology-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc-terminology.jpg`,
			imageWidth: 1000,
			imageHeight: 563,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'The Words We Use In Bitcoin',
			description: 'Words, Language, Terminology, and Linguistic Attacks',
			link: 'https://dergigi.com/2022/06/27/the-words-we-use-in-bitcoin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 741471,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-privacy-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-privacy-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc-privacy.jpg`,
			imageWidth: 1000,
			imageHeight: 472,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Freedom and Privacy',
			description: 'Two Sides of the Same Coin',
			link: 'https://dergigi.com/2022/06/19/freedom-and-privacy/',
			buttonLabel: 'Read Now',
		},
		{
			block: 730245,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/jefferson-bastiat-hayek-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/jefferson-bastiat-hayek-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/jefferson-bastiat-hayek.jpg`,
			imageWidth: 1000,
			imageHeight: 666,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Inalienable Property Rights',
			description: 'The Law, Language, Money, and Morality of Bitcoin',
			link: 'https://dergigi.com/2022/04/03/inalienable-property-rights/',
			buttonLabel: 'Read Now',
		},
		{
			block: 724099,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/phoenix-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/phoenix-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/phoenix.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'The Egg of the Phoenix',
			link: 'https://dergigi.com/2022/02/19/the-egg-of-the-phoenix/',
			buttonLabel: 'Read Now',
		},
		{
			block: 707909,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/magic-internet-money-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/magic-internet-money-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/magic-internet-money.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Magic Internet Money',
			description: 'The Magical Origins of Bitcoin',
			link: 'https://dergigi.com/2021/11/02/magic-internet-money/',
			buttonLabel: 'Read Now',
		},
		{
			block: 699784,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-staircase-up-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-staircase-up-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc-staircase-up.jpg`,
			imageWidth: 1024,
			imageHeight: 683,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Moving Towards a Bitcoin Standard',
			description: 'The Stairway to Omnipresence',
			link: 'https://dergigi.com/2021/09/09/moving-towards-a-bitcoin-standard/',
			buttonLabel: 'Read Now',
		},
		{
			block: 693819,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-law-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-law-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc-law.jpg`,
			imageWidth: 1000,
			imageHeight: 666,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Implications of Outlawing Bitcoin',
			link: 'https://dergigi.com/2021/08/02/implications-of-outlawing-bitcoin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 687477,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/bitcoin-is-an-idea-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/bitcoin-is-an-idea-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/bitcoin-is-an-idea.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin Is An Idea',
			link: 'https://dergigi.com/2021/06/13/bitcoin-is-an-idea/',
			buttonLabel: 'Read Now',
		},
		{
			block: 674473,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc21-kings-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc21-kings-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc21-kings.jpg`,
			imageWidth: 1000,
			imageHeight: 552,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'The Responsibility of Adopting Bitcoin',
			link: 'https://dergigi.com/2021/03/14/the-responsibility-of-adopting-bitcoin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 671711,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-hats-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-hats-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc-hats.jpg`,
			imageWidth: 1000,
			imageHeight: 625,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin Privacy: Best Practices',
			link: 'https://dergigi.com/2021/03/14/bitcoin-privacy-best-practices/',
			buttonLabel: 'Read Now',
		},
		{
			block: 666053,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/bitcoin-is-time-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/bitcoin-is-time-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/bitcoin-is-time.jpg`,
			imageWidth: 1000,
			imageHeight: 666,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin Is Time',
			link: 'https://dergigi.com/2021/01/14/bitcoin-is-time/',
			buttonLabel: 'Read Now',
		},
		{
			block: 649239,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc19-ux-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc19-ux-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc19-ux.jpg`,
			imageWidth: 1200,
			imageHeight: 628,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'On Bitcoin\'s UX',
			link: 'https://dergigi.com/2020/09/20/on-bitcoin-s-ux/',
			buttonLabel: 'Read Now',
		},
		{
			block: 640147,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc17-true-names-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc17-true-names-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc17-true-names.jpg`,
			imageWidth: 1000,
			imageHeight: 500,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'True Names Not Required',
			link: 'https://dergigi.com/2020/07/21/true-names-not-required/',
			buttonLabel: 'Read Now',
		},
		{
			block: 634843,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc16-bitcoin-journey-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc16-bitcoin-journey-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc16-bitcoin-journey.png`,
			imageWidth: 1000,
			imageHeight: 652,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'The Bitcoin Journey',
			link: 'https://dergigi.com/2020/06/15/the-bitcoin-journey/',
			buttonLabel: 'Read Now',
		},
		{
			block: 631088,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc13-dear-legacy-people-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc13-dear-legacy-people-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc13-dear-legacy-people.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Dear Legacy People',
			link: 'https://dergigi.com/2020/03/20/dear-legacy-people/',
			buttonLabel: 'Read Now',
		},
		{
			block: 627883,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc15-dear-family-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc15-dear-family-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc15-dear-family.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Dear Family, Dear Friends',
			description: 'A letter to all of you who still have no bitcoin.',
			link: 'https://dergigi.com/2020/04/27/dear-family-dear-friends/',
			buttonLabel: 'Read Now',
		},
		{
			block: 623774,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc14-dear-bitcoiners-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc14-dear-bitcoiners-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc14-dear-bitcoiners.jpg`,
			imageWidth: 1000,
			imageHeight: 571,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Dear Bitcoiners',
			description: 'An optimistic letter to friends and foes around the globe.',
			link: 'https://dergigi.com/2020/03/31/dear-bitcoiners/',
			buttonLabel: 'Read Now',
		},
		{
			block: 619664,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc12-habitats-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc12-habitats-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc12-habitats.jpg`,
			imageWidth: 1000,
			imageHeight: 563,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin\'s Habitats',
			description: 'How Bitcoin is surviving and thriving between worlds',
			link: 'https://dergigi.com/2020/03/01/bitcoin-s-habitats/',
			buttonLabel: 'Read Now',
		},
		{
			block: 609583,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/DER-GIGI_21-Lessons-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/DER-GIGI_21-Lessons-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/DER-GIGI_21-Lessons.jpg`,
			imageWidth: 600,
			imageHeight: 900,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: '21 Lessons - What I\'ve Learned From Falling Down the Bitcoin Rabbit Hole',
			description: 'Physical book first published.',
			link: 'https://21lessons.com',
			buttonLabel: 'Read Online',
		},
		{
			block: 608839,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc11-eternal-struggle-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc11-eternal-struggle-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc11-eternal-struggle.jpg`,
			imageWidth: 1000,
			imageHeight: 563,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin\'s Eternal Struggle',
			description: 'How Bitcoin Thrives on the Edge between Order and Chaos',
			link: 'https://dergigi.com/2019/12/22/bitcoin-s-eternal-struggle/',
			buttonLabel: 'Read Now',
		},
		{
			block: 604834,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc10-venezuela-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc10-venezuela-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc10-venezuela.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin Boots on the Ground: Venezuela',
			link: 'https://dergigi.com/2019/11/21/bitcoin-boots-on-the-ground-venezuela/',
			buttonLabel: 'Read Now',
		},
		{
			block: 597715,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc09-kill-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc09-kill-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc09-kill.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'How to Kill Bitcoin',
			link: 'https://dergigi.com/2019/10/03/how-to-kill-bitcoin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 591280,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc08-rise-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc08-rise-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc08-rise.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'The Rise of the Sovereign Individual',
			description: 'How power is re-aligning itself in an internet-native world',
			link: 'https://dergigi.com/2019/08/22/the-rise-of-the-sovereign-individual/',
			buttonLabel: 'Read Now',
		},
		{
			block: 589054,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc07-bitcoin-mitosis-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc07-bitcoin-mitosis-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc07-bitcoin-mitosis.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Proof of Life',
			description: 'Why Bitcoin is a Living Organism',
			link: 'https://dergigi.com/2019/08/07/proof-of-life/',
			buttonLabel: 'Read Now',
		},
		{
			block: 586682,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-waking-up-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc-waking-up-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc-waking-up.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'The World is Waking Up to Bitcoin',
			link: 'https://dergigi.com/2019/07/23/the-world-is-waking-up-to-bitcoin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 578414,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/DER-GIGI_21-Lessons-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/DER-GIGI_21-Lessons-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/DER-GIGI_21-Lessons.jpg`,
			imageWidth: 600,
			imageHeight: 900,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: '21 Lessons - What I\'ve Learned From Falling Down the Bitcoin Rabbit Hole',
			description: 'Online full book first published.',
			link: 'https://21lessons.com',
			buttonLabel: 'Read Online',
		},
		{
			block: 574078,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc06-the-gravity-of-ideas-in-bitcoin-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc06-the-gravity-of-ideas-in-bitcoin-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc06-the-gravity-of-ideas-in-bitcoin.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin\'s Gravity',
			description: 'How idea-value feedback loops are pulling people in',
			link: 'https://dergigi.com/2019/05/01/bitcoins-gravity/',
			buttonLabel: 'Read Now',
		},
		{
			block: 569826,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc05-the-anatomy-of-bitcoin-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc05-the-anatomy-of-bitcoin-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc05-the-anatomy-of-bitcoin.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Technological Teachings of Bitcoin',
			description: 'What I\'ve Learned From Bitcoin: Part III',
			link: 'https://dergigi.com/2019/04/02/technological-teachings-of-bitcoin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 558149,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc04-the-garden-of-bitcoin-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc04-the-garden-of-bitcoin-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc04-the-garden-of-bitcoin.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Economic Teachings of Bitcoin',
			description: 'What I\'ve Learned From Bitcoin: Part II',
			link: 'https://dergigi.com/2019/01/11/economic-teachings-of-bitcoin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 554747,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc03-the-school-of-bitcoin-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc03-the-school-of-bitcoin-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc03-the-school-of-bitcoin.jpg`,
			imageWidth: 1000,
			imageHeight: 667,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Philosophical Teachings of Bitcoin',
			description: 'What I\'ve Learned From Bitcoin: Part I',
			link: 'https://dergigi.com/2018/12/21/philosophical-teachings-of-bitcoin/',
			buttonLabel: 'Read Now',
		},
		{
			block: 526818,
			gThumbUrl: `${AUTHORS.dergigi.baseUrl}/btc01-david-gthumb.jpg`,
			thumbUrl: `${AUTHORS.dergigi.baseUrl}/btc01-david-thumb.jpg`,
			imageUrl: `${AUTHORS.dergigi.baseUrl}/btc01-david.jpg`,
			imageWidth: 667,
			imageHeight: 1000,
			attribution: AUTHORS.dergigi.name,
			cats: AUTHORS.dergigi.cats,
			title: 'Bitcoin\'s Energy Consumption',
			description: 'A shift in perspective',
			link: 'https://dergigi.com/2018/06/10/bitcoin-s-energy-consumption/',
			buttonLabel: 'Read Now',
		},
		// FLO MONTOYA
		{
			block: 803973,
			gThumbUrl: `${ARTISTS.flomontoya.baseUrl}/FLO-MONTOYA_Lovers-Running-From-Fiat-Storm-gthumb.jpg`,
			thumbUrl: `${ARTISTS.flomontoya.baseUrl}/FLO-MONTOYA_Lovers-Running-From-Fiat-Storm-thumb.jpg`,
			imageUrl: `${ARTISTS.flomontoya.baseUrl}/FLO-MONTOYA_Lovers-Running-From-Fiat-Storm.jpg`,
			imageWidth: 857,
			imageHeight: 1202,
			attribution: ARTISTS.flomontoya.name,
			cats: ARTISTS.flomontoya.cats,
			title: 'Bitcoin is Love: Lovers Running from Fiat Storm',
			description: 'Ink and paint on paper. In this piece, lovers run hand in hand through the fiat storm, symbolizing the love infused in Bitcoin, transcending borders and empowering individuals. Love, the driving force, propels them forward through the financial turbulence. "Bitcoin is love" celebrates the love story between humanity and a revolutionary idea, reminding us that love can weather any storm, emerging stronger on the other side.',
			link: ARTISTS.flomontoya.link,
		},
		// FRACTAL ENCRYPT
		{
			block: 771010,
			gThumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Timechain-Codex-Cover-gthumb.jpg`,
			thumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Timechain-Codex-Cover-thumb.jpg`,
			imageUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Timechain-Codex-Cover.jpg`,
			imageWidth: 1268,
			imageHeight: 1500,
			attribution: ARTISTS.fractal.name,
			cats: [CATEGORIES.physical],
			title: 'The Timechain Codex (Cover)',
			description: 'Sculpted cover created for the graphic novel by FractalEncrypt.',
			link: 'https://www.swanbitcoin.com/the-timechain-codex/',
		},
		{
			block: 701555,
			gThumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_The-Timechain-Codex-gthumb.jpg`,
			thumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_The-Timechain-Codex-thumb.jpg`,
			imageUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_The-Timechain-Codex.jpg`,
			imageWidth: 1095,
			imageHeight: 1600,
			attribution: ARTISTS.fractal.name,
			cats: [CATEGORIES.literature, CATEGORIES.digital],
			title: 'The Timechain Codex',
			description: 'A graphic novel by FractalEncrypt based on "Bitcoin Astronomy" by Dhruv Bansal.',
			link: 'https://www.swanbitcoin.com/the-timechain-codex/',
		},
		{
			block: 696821,
			gThumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Infinity-Key-gthumb.jpg`,
			thumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Infinity-Key-thumb.jpg`,
			imageUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Infinity-Key.jpg`,
			imageWidth: 1600,
			imageHeight: 941,
			attribution: ARTISTS.fractal.name,
			cats: [CATEGORIES.physical],
			title: 'Bitcoin Infinity Key',
			link: `${ARTISTS.fractal.link}`,
		},
		{
			block: 691470,
			gThumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_8of10-gthumb.jpg`,
			thumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_8of10-thumb.jpg`,
			imageUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_8of10.jpg`,
			imageWidth: 1600,
			imageHeight: 1201,
			attribution: ARTISTS.fractal.name,
			cats: [CATEGORIES.physical],
			title: 'The Bitcoin Full Node Sculpture',
			description: '#8 of 10',
			link: `${ARTISTS.fractal.link}`,
		},
		{
			block: 684902,
			gThumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_7of10-gthumb.jpg`,
			thumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_7of10-thumb.jpg`,
			imageUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_7of10.jpg`,
			imageWidth: 1600,
			imageHeight: 1461,
			attribution: ARTISTS.fractal.name,
			cats: [CATEGORIES.physical],
			title: 'The Bitcoin Full Node Sculpture',
			description: '#7 of 10',
			link: `${ARTISTS.fractal.link}`,
		},
		{
			block: 631675,
			gThumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_3of10-gthumb.jpg`,
			thumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_3of10-thumb.jpg`,
			imageUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_3of10.jpg`,
			imageWidth: 1400,
			imageHeight: 1050,
			attribution: ARTISTS.fractal.name,
			cats: [CATEGORIES.physical],
			title: 'The Bitcoin Full Node Sculpture',
			description: '#3 of 10',
			link: `${ARTISTS.fractal.link}`,
		},
		{
			block: 612350,
			gThumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_1of10-gthumb.jpg`,
			thumbUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_1of10-thumb.jpg`,
			imageUrl: `${ARTISTS.fractal.baseUrl}/FractalEncrypt_Bitcoin-Full-Node-Sculpture_1of10.jpg`,
			imageWidth: 1600,
			imageHeight: 1200,
			attribution: ARTISTS.fractal.name,
			cats: [CATEGORIES.physical],
			title: 'The Bitcoin Full Node Sculpture',
			description: '#1 of 10',
			link: `${ARTISTS.fractal.link}`,
		},
		// HACKZERO
		{
			block: 828812,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Blossom-of-Satoshi-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Blossom-of-Satoshi-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Blossom-of-Satoshi.jpg`,
			imageWidth: 904,
			imageHeight: 1200,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'Blossom of Satoshi',
			description: 'A digital tapestry of flowers representing the flourishing diversity of the Bitcoin network. Each bloom signifies a unique node, and together they form the Bitcoin symbol, celebrating the decentralized nature of the blockchain. Just as a garden thrives on the varied contributions of each flower, Bitcoin grows stronger with each participant in its network.',
			link: 'https://x.com/hack355/status/1753968770584850432'
		},
		{
			block: 825057,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Bitcoin-Shattering-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Bitcoin-Shattering-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Bitcoin-Shattering.jpg`,
			imageWidth: 1300,
			imageHeight: 729,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'Bitcoin Shattering the Mold',
			description: 'A visual metaphor for the revolutionary impact of Bitcoin. The imagery of a Bitcoin coin bursting through a chaotic array of geometric shapes represents the cryptocurrency\'s role in breaking down the barriers of traditional finance. The explosion of colors and forms symbolizes the release of boundless potential and the reshaping of economic paradigms. This artwork is a bold statement on the power of decentralization and the new possibilities it unlocks.',
			link: 'https://x.com/BitcoinNewsCom/status/1744835980551438783'
		},
		{
			block: 823785,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Bitcoin-Odyssey-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Bitcoin-Odyssey-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Bitcoin-Odyssey.jpg`,
			imageWidth: 1300,
			imageHeight: 699,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'Bitcoin Odyssey',
			description: 'A sprawling sci-fi tableau that envisions a world powered by the revolutionary technology of Bitcoin. In this intricate landscape, the Bitcoin symbol stands as a beacon of progress amidst a burst of technological advancements and vibrant energy. This piece reflects the explosion of innovation spurred by Bitcoin, highlighting a future where finance and technology merge in a kaleidoscope of potential.',
			link: 'https://x.com/hack355/status/1609335855398768640'
		},
		{
			block: 807482,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Echoes-of-Autonomy-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Echoes-of-Autonomy-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Echoes-of-Autonomy.jpg`,
			imageWidth: 870,
			imageHeight: 1200,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'Echoes of Autonomy',
			description: 'A striking assembly of figures in Guy Fawkes masks, an emblematic representation of the quest for personal liberty and privacy in the digital age. Resonating with the spirit of Bitcoin, these figures stand as guardians of individual sovereignty against the backdrop of a system that often prioritizes control over freedom. This painting is a powerful reminder of the collective voice advocating for the decentralization and democratization of financial power.',
			link: 'https://x.com/Spirit_Satoshi/status/1701972388374540347'
		},
		{
			block: 801670,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Radiant-Bitcoin-Resonance-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Radiant-Bitcoin-Resonance-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Radiant-Bitcoin-Resonance.jpg`,
			imageWidth: 1100,
			imageHeight: 1100,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'Radiant Bitcoin Resonance',
			description: 'a visual symphony of warmth and vibrancy, celebrating the luminous core of Bitcoin\'s influence. The concentric patterns emanating from the Bitcoin symbol represent the ripple effect of innovation and change that bitcoin has introduced to the world. This piece is a powerful expression of the energy and optimism that Bitcoin brings to the financial landscape, with its bright hues mirroring the hope for a decentralized and empowered future.',
			link: 'https://x.com/elonmusk/status/1687575127503028224'
		},
		{
			block: 794035,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_In-the-Garden-of-Satoshi-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_In-the-Garden-of-Satoshi-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_In-the-Garden-of-Satoshi.jpg`,
			imageWidth: 1300,
			imageHeight: 729,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'In the Garden of Satoshi',
			description: '"In the Garden of Satoshi," we find a vibrant portrayal of the mythical figure enveloped in an explosion of flora and intricate designs, symbolizing the flourishing ecosystem that has grown from the seed of Bitcoin. The face of Satoshi, a visage known to all yet seen by none, watches over the blooming garden of innovation, ideas, and community that his creation has nurtured. This artwork is a tribute to the mystery and legacy of Bitcoin\'s founder, a celebration of the decentralized growth and vibrant diversity that his invention has fostered in the digital age.',
			link: 'https://thebitcoinmuse.com/hackzero'
		},
		{
			block: 770638,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Navigating-the-Bitcoin-Seas-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Navigating-the-Bitcoin-Seas-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Navigating-the-Bitcoin-Seas.jpg`,
			imageWidth: 1200,
			imageHeight: 800,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'Navigating the Bitcoin Seas',
			description: '"Navigating the Bitcoin Seas" captures the adventurous voyage of Bitcoin as it charts a course through the uncharted waters of the financial world. Each sail, emblazoned with the Bitcoin symbol, reflects the courage and pioneering spirit of the individuals who steer the Bitcoin network towards a new horizon. This piece symbolizes the collective journey of the Bitcoin community, navigating the ebb and flow of markets with resilience and unity. A message from Michael Saylor to HackZero: "The ship is a magnificent machine crafted by skilled engineers empowering the courageous to create a better world."',
			link: 'https://x.com/saylor/status/1611353910404579329'
		},
		{
			block: 770340,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_The-Satoshi-Express-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_The-Satoshi-Express-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_The-Satoshi-Express.jpg`,
			imageWidth: 1200,
			imageHeight: 731,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'The Satoshi Express',
			description: '"The Satoshi Express" roars through the landscape of traditional finance, a vivid representation of Bitcoin\'s unstoppable journey towards a decentralized future. Adorned with the iconic Bitcoin symbol, the steam train is a fitting metaphor for the power and momentum of the blockchain revolution, cutting a path forward with determination and the fiery spirit of innovation. This artwork embodies the relentless advancement of Bitcoin, echoing the transformative impact it has on our perception of value and the mechanisms of economic exchange.',
			link: 'https://x.com/hack355/status/1614382578567454726'
		},
		{
			block: 775732,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Satoshis-Sun-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Satoshis-Sun-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Satoshis-Sun.jpg`,
			imageWidth: 750,
			imageHeight: 1500,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'Satoshi\'s Sun',
			description: 'A vivid homage to the Bitcoin ethos of growth and vitality. The central sunflower, emblazoned with the Bitcoin logo, represents the pioneering spirit of Satoshi Nakamoto, radiating influence and inspiration like the sun nourishing the surrounding flora. The butterfly symbolizes transformation and the elegant evolution of the financial system through Bitcoin. This piece captures the essence of innovation flowering from a single, powerful idea that gives life to an entire ecosystem.',
			link: 'https://x.com/hack355/status/1623671809047420929'
		},
		{
			block: 768158,
			gThumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Starry-Night-over-the-Bitcoin-Network-gthumb.jpg`,
			thumbUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Starry-Night-over-the-Bitcoin-Network-thumb.jpg`,
			imageUrl: `${ARTISTS.hackzero.baseUrl}/HACKZERO_Starry-Night-over-the-Bitcoin-Network.jpg`,
			imageWidth: 1200,
			imageHeight: 800,
			attribution: ARTISTS.hackzero.name,
			cats: ARTISTS.hackzero.cats,
			title: 'Starry Night over the Bitcoin Network',
			description: 'an inspired reenvisioning of Van Gogh\'s masterpiece, symbolizing the harmonious blend of art and technology. Bitcoin at the center, reflecting its role as the pioneering digital asset that continues to capture the world\'s imagination. This artwork is a tribute to the creativity and innovation that Bitcoin has unleashed across industries and communities, painting a future where finance meets art in the tapestry of human achievement.',
			link: 'https://x.com/hack355/status/1605007342495481856'
		},
		// KANUTO
		{
			block: 831200,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Mempool-Multisig-2x67-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Mempool-Multisig-2x67-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Mempool-Multisig-2x67.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Mempool Multisig 2x67',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 831000,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Blue-Lightning-Network-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Blue-Lightning-Network-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Blue-Lightning-Network.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Blue Lightning Network',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 829800,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Abstracted-Lightning-Network-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Abstracted-Lightning-Network-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Abstracted-Lightning-Network.jpg`,
			imageWidth: 1200,
			imageHeight: 800,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Abstracted Lightning Network',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 829500,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_3x15-CoinJoin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_3x15-CoinJoin-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_3x15-CoinJoin.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: '3x15 CoinJoin',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 828800,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Round-Weak-Coinjoin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Round-Weak-Coinjoin-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Round-Weak-Coinjoin.jpg`,
			imageWidth: 1200,
			imageHeight: 1152,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Round Weak CoinJoin',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 827800,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_1496-Different-Ways-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_1496-Different-Ways-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_1496-Different-Ways.jpg`,
			imageWidth: 1200,
			imageHeight: 936,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: '1,496 different ways',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 827500,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Wasabi-Post-Mix-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Wasabi-Post-Mix-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Wasabi-Post-Mix.jpg`,
			imageWidth: 977,
			imageHeight: 1200,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Wasabi post mix',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 827400,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitwise-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitwise-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitwise.jpg`,
			imageWidth: 1200,
			imageHeight: 946,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Bitwise',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 826800,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Hodl-Waves-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Hodl-Waves-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Hodl-Waves.jpg`,
			imageWidth: 1200,
			imageHeight: 952,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'HODL Waves',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 826400,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Whirlpool-Coinjoin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Whirlpool-Coinjoin-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Whirlpool-Coinjoin.jpg`,
			imageWidth: 1200,
			imageHeight: 945,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Whirlpool CoinJoin',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 825800,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Coinjoin-Mempool-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Coinjoin-Mempool-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Coinjoin-Mempool.jpg`,
			imageWidth: 1200,
			imageHeight: 950,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'CoinJoin Mempool',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 819400,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Orange-Pill-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Orange-Pill-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Orange-Pill.jpg`,
			imageWidth: 1200,
			imageHeight: 1476,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Orange Pill',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 818700,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitcoin-Miner-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitcoin-Miner-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitcoin-Miner.jpg`,
			imageWidth: 1529,
			imageHeight: 1200,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Bitcoin Miner',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 816700,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitcoin-Pizza-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitcoin-Pizza-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Bitcoin-Pizza.jpg`,
			imageWidth: 1200,
			imageHeight: 1444,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Bitcoin Pizza',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 816500,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Rabbit-Hole-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Rabbit-Hole-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_Rabbit-Hole.jpg`,
			imageWidth: 1200,
			imageHeight: 1450,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: 'Rabbit Hole',
			link: `${ARTISTS.kanuto.link}`,
		},
		{
			block: 814300,
			gThumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_₿-Letters-gthumb.jpg`,
			thumbUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_₿-Letters-thumb.jpg`,
			imageUrl: `${ARTISTS.kanuto.baseUrl}/KANUTO_₿-Letters.jpg`,
			imageWidth: 1200,
			imageHeight: 1448,
			attribution: ARTISTS.kanuto.name,
			cats: ARTISTS.kanuto.cats,
			title: '₿ Letters',
			link: `${ARTISTS.kanuto.link}`,
		},
		// KNUT
		{
			block: 801179,
			gThumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Praxeology-gthumb.jpg`,
			thumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Praxeology-thumb.jpg`,
			imageUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Praxeology.jpeg`,
			imageWidth: 500,
			imageHeight: 500,
			attribution: AUTHORS.knut.name,
			cats: AUTHORS.knut.cats,
			title: 'Praxeology: The invisible hand that feeds you',
			description: 'Praxeology, the science of human action, is a lens through which truthfulness of the world is revealed. Truth goggles, if you will. Praxeology teaches us that everything going on in our societiy is rooted in the wills and desires of individuals. Starting from an irrefutable set of statements about human beings, we can come to conclusions about human behavior that are true regardless of what any political ideology might assert.',
			link: 'https://www.amazon.com/Praxeology-invisible-hand-that-feeds/dp/9916723060',
			buttonLabel: 'Get the Book',
		},
		{
			block: 778740,
			gThumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Sovereignty-Independence-gthumb.jpg`,
			thumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Sovereignty-Independence-thumb.jpg`,
			imageUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Sovereignty-Independence.jpg`,
			imageWidth: 600,
			imageHeight: 927,
			attribution: AUTHORS.knut.name,
			cats: AUTHORS.knut.cats,
			title: 'Bitcoin: Sovereignty & Independence',
			description: 'Knut Svanholm takes us on a philosophical journey to explore the new language of money that Bitcoin creates, while never losing touch with what is happening in the world.',
			link: 'https://www.amazon.com/Bitcoin-Sovereignty-Independence-Knut-Svanholm/dp/9916697906',
			buttonLabel: 'Get the Book',
		},
		{
			block: 726647,
			gThumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Everything-Divided-by-21M-gthumb.jpg`,
			thumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Everything-Divided-by-21M-thumb.jpg`,
			imageUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Everything-Divided-by-21M.jpg`,
			imageWidth: 600,
			imageHeight: 927,
			attribution: AUTHORS.knut.name,
			cats: AUTHORS.knut.cats,
			title: 'Bitcoin: Everything Divided By 21 Million',
			description: 'An exploration of how we think, our relationship with time, money, and value, and how they are fundamentally intertwined in each of us.',
			link: 'https://www.amazon.com/Bitcoin-Everything-divided-21-million/dp/9916697191',
			buttonLabel: 'Get the Book',
		},
		{
			block: 626788,
			gThumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Independence-Reimagined-gthumb.jpg`,
			thumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Independence-Reimagined-thumb.jpg`,
			imageUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Independence-Reimagined.jpeg`,
			imageWidth: 500,
			imageHeight: 500,
			attribution: AUTHORS.knut.name,
			cats: AUTHORS.knut.cats,
			title: 'Bitcoin: Independence Reimagined',
			description: 'This follow-up to Bitcoin: Sovereignty Through Mathematics describes the old boxes we have been operating within, and how Bitcoin encourages us to challenge and reshape them.',
			link: 'https://www.amazon.com/Bitcoin-Independence-reimagined-Knut-Svanholm/dp/B087638GJT',
			buttonLabel: 'Get the Book',
		},
		{
			block: 576691,
			gThumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Sovereignty-through-mathematics-gthumb.jpg`,
			thumbUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Sovereignty-through-mathematics-thumb.jpg`,
			imageUrl: `${AUTHORS.knut.baseUrl}/KNUT-SVANHOLM_Bitcoin-Sovereignty-through-mathematics.jpeg`,
			imageWidth: 500,
			imageHeight: 500,
			attribution: AUTHORS.knut.name,
			cats: AUTHORS.knut.cats,
			title: 'Bitcoin: Sovereignty Through Mathematics',
			description: 'What makes Bitcoin so special? What sets Bitcoin apart from every other monetary asset that preceded it? What is a monetary asset in the first place? What is money?',
			link: 'https://www.amazon.com/Bitcoin-Sovereignty-mathematics-Knut-Svanholm/dp/1090109911',
			buttonLabel: 'Get the Book',
		},
		// MADEX
		{
			block: 834553,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Running-Bitcoin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Running-Bitcoin-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Running-Bitcoin.jpg`,
			imageWidth: 960,
			imageHeight: 1280,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 834553',
			link: ARTISTS.madex.link,
		},
		{
			block: 825453,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Honey-Badger-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Honey-Badger-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Honey-Badger.jpg`,
			imageWidth: 828,
			imageHeight: 1122,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 825453',
			link: ARTISTS.madex.link,
		},
		{
			block: 808808,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Bear-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Bear-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Bear.jpg`,
			imageWidth: 1000,
			imageHeight: 1333,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 808808',
			link: ARTISTS.madex.link,
		},
		{
			block: 806781,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Satoshi-knight-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Satoshi-knight-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Satoshi-knight.jpg`,
			imageWidth: 990,
			imageHeight: 1298,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 806781',
			link: ARTISTS.madex.link,
		},
		{
			block: 801680,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_V-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_V-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_V.jpg`,
			imageWidth: 1000,
			imageHeight: 1299,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 801680',
			link: ARTISTS.madex.link,
		},
		{
			block: 801679,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Lady-Satoshi-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Lady-Satoshi-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Lady-Satoshi.jpg`,
			imageWidth: 990,
			imageHeight: 1302,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 801679',
			link: ARTISTS.madex.link,
		},
		{
			block: 784880,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Camel-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Camel-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Camel.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 784880',
			link: ARTISTS.madex.link,
		},
		{
			block: 775828,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_21M-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_21M-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_21M.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 775828',
			link: ARTISTS.madex.link,
		},
		{
			block: 752458,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Baltic-Honey-Badger-2022-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Baltic-Honey-Badger-2022-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Baltic-Honey-Badger-2022.jpg`,
			imageWidth: 1000,
			imageHeight: 1375,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 752458',
			link: ARTISTS.madex.link,
		},
		{
			block: 751429,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Bull-Bitcoin-Austin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Bull-Bitcoin-Austin-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Bull-Bitcoin-Austin.jpg`,
			imageWidth: 1000,
			imageHeight: 1273,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 751429',
			description: 'Graphic for BullBitcoin.com shirt released at Bit Block Boom conference in Austin, Texas in 2022.',
			link: ARTISTS.madex.link,
		},
		{
			block: 733858,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Free-Men-Dont-Ask-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Free-Men-Dont-Ask-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Free-Men-Dont-Ask.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 733858',
			link: ARTISTS.madex.link,
		},
		{
			block: 728975,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_End-of-Fiat-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_End-of-Fiat-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_End-of-Fiat.jpg`,
			imageWidth: 900,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 728975',
			link: ARTISTS.madex.link,
		},
		{
			block: 726897,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_21-Million-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_21-Million-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_21-Million.jpg`,
			imageWidth: 1200,
			imageHeight: 1500,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 726897',
			link: ARTISTS.madex.link,
		},
		{
			block: 721850,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_atlasone-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_atlasone-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_atlasone.jpg`,
			imageWidth: 1200,
			imageHeight: 1600,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 721850',
			link: ARTISTS.madex.link,
		},
		{
			block: 677367,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_freemendontaskv1-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_freemendontaskv1-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_freemendontaskv1.jpg`,
			imageWidth: 1200,
			imageHeight: 1500,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 677367',
			link: ARTISTS.madex.link,
		},
		{
			block: 657261,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Vendetta-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Vendetta-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Vendetta.jpg`,
			imageWidth: 857,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 657261',
			link: ARTISTS.madex.link,
		},
		{
			block: 643624,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_End-War-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_End-War-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_End-War.jpg`,
			imageWidth: 1300,
			imageHeight: 975,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 643624',
			link: ARTISTS.madex.link,
		},
		{
			block: 594887,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Hodl-Hodl-Baltic-Honey-Badger-2019-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Hodl-Hodl-Baltic-Honey-Badger-2019-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Hodl-Hodl-Baltic-Honey-Badger-2019.jpg`,
			imageWidth: 1000,
			imageHeight: 1545,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 594887',
			link: ARTISTS.madex.link,
		},
		{
			block: 572985,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_MAIN-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_MAIN-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_MAIN.jpg`,
			imageWidth: 1200,
			imageHeight: 1609,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 572985',
			link: ARTISTS.madex.link,
		},
		{
			block: 563335,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_2-Panels-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_2-Panels-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_2-Panels.jpg`,
			imageWidth: 860,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 563335',
			link: ARTISTS.madex.link,
		},
		{
			block: 542600,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX.jpg`,
			imageWidth: 1100,
			imageHeight: 1533,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 542600',
			link: ARTISTS.madex.link,
		},
		{
			block: 461940,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Fashion-Girl-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Fashion-Girl-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Fashion-Girl.jpg`,
			imageWidth: 960,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 461940',
			link: ARTISTS.madex.link,
		},
		{
			block: 419069,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Red-Girl-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Red-Girl-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Red-Girl.jpg`,
			imageWidth: 960,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 419069',
			link: ARTISTS.madex.link,
		},
		{
			block: 413624,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Winged-Girl-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Winged-Girl-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Winged-Girl.jpg`,
			imageWidth: 960,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 413624',
			link: ARTISTS.madex.link,
		},
		{
			block: 413587,
			gThumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Rifles-gthumb.jpg`,
			thumbUrl: `${ARTISTS.madex.baseUrl}/MADEX_Rifles-thumb.jpg`,
			imageUrl: `${ARTISTS.madex.baseUrl}/MADEX_Rifles.jpg`,
			imageWidth: 960,
			imageHeight: 1200,
			attribution: ARTISTS.madex.name,
			cats: ARTISTS.madex.cats,
			title: 'Block 413587',
			link: ARTISTS.madex.link,
		},
		// MODEOTEC
		{
			block: 837054,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Anon-B-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Anon-B-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Anon-B.jpg`,
			imageWidth: 1277,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Anon_B',
			description: 'A face made from Bitcoin Logo "B". Inspired by the idea that everyone can "be" Bitcoin.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 832359,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Satoshi-Stand-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Satoshi-Stand-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Satoshi-Stand.jpg`,
			imageWidth: 960,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Satoshi Stand',
			description: 'A stand made from a circuit board for my Businesscards - Satoshi counterfeit. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold on black soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 828392,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Nostr-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Nostr-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Nostr.jpg`,
			imageWidth: 1137,
			imageHeight: 873,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Nostr',
			description: 'Inspired by the community chosen Ostrich. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on purple soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 828336,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bookmark-Atlantis-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bookmark-Atlantis-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bookmark-Atlantis.jpg`,
			imageWidth: 1280,
			imageHeight: 831,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Bookmark Atlantis',
			description: 'A bookmark for the Bitcoin Atlantis event on the island of Madeira, Portugal. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on black soldermask. A collab with @Lady_Block_Jane',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 827122,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Genesis-Block-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Genesis-Block-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Genesis-Block.jpg`,
			imageWidth: 1176,
			imageHeight: 899,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Genesis Block',
			description: 'The raw hex Version of the Genesis Block - The hex-numbers are translated into human readable text. You can see the famous sentence written into the Bitcoin Blockchain: "The Times 03/Jan/2009 Cancellor on brink of second bailout for banks". Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on black soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 826506,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Cypherpunk-Tape-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Cypherpunk-Tape-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Cypherpunk-Tape.jpg`,
			imageWidth: 1280,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Cypherpunk Tape',
			description: 'Inspired by the 1993 released paper "A Cypherpunk\'s Manifesto" by Eric Hughes. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on black soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 826004,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Hodlonaut-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Hodlonaut-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Hodlonaut.jpg`,
			imageWidth: 915,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Hodlonaut',
			description: 'A nice tiny keychain with the counterfeit of Hodlonaut. Base Material: Fiberglass/Epoxy and Copper. Surface: copper under red soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 825094,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Decentralized-Rabbit-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Decentralized-Rabbit-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Decentralized-Rabbit.jpg`,
			imageWidth: 960,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Decentralized Rabbit',
			description: 'Since Bitcoin is a decentralized peer to peer system, all creatures in our world are decentralized too, like this beautiful rabbit. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on blue soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 823233,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Assange-Matrix-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Assange-Matrix-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Assange-Matrix.jpg`,
			imageWidth: 672,
			imageHeight: 792,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Assange_Matrix',
			description: 'A counterfeit of Julian Assange, an imprisoned journalist, founder of wikileaks. Inspired by "FREE ASSANG NOW" and the Movies "The Matrix". The Matrix Code consists of letters...',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 822050,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_CBDC-Eye-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_CBDC-Eye-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_CBDC-Eye.jpg`,
			imageWidth: 960,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'CBDC Eye',
			description: 'An animated eye on a LCD Screen. Inspired by the issue with CBDC\'s. Everywhere surveillance, Big Brother. A circuit board with controllers, sensors and LC-Display, modded Animation.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 813437,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Laendle-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Laendle-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Laendle.jpg`,
			imageWidth: 1280,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Laendle',
			description: 'A Commission work for a Bitcoin Event in Germany. You can wear it with a lanyard. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on black soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 810610,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Art-Deco-Bitcoin1-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Art-Deco-Bitcoin1-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Art-Deco-Bitcoin1.jpg`,
			imageWidth: 822,
			imageHeight: 629,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Art Deco Bitcoin I',
			description: 'Inspired by the Art Deco Era in the 1930\'s. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on black soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 808809,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bookmark-Schwyz-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bookmark-Schwyz-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bookmark-Schwyz.jpg`,
			imageWidth: 1200,
			imageHeight: 1200 ,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Bookmark Schwyz',
			description: 'A bookmark for the event Bitcoin Block Schwyz in 2023. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on green soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 803488,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Collab-Asanoha-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Collab-Asanoha-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Collab-Asanoha.jpg`,
			imageWidth: 960,
			imageHeight: 1200 ,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Collab Asanoha',
			description: 'A design collab with Asanoha Gold. His design on a circuit board. Green and black: Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on green/black soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 795277,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bitcoin-Accepted-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bitcoin-Accepted-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Bitcoin-Accepted.jpg`,
			imageWidth: 960,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Bitcoin accepted',
			description: 'A shop sign made from a circuit board. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on green soldermask. A collab with @Lady_Block_Jane',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 787740,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Word-of-Freedom-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Word-of-Freedom-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Word-of-Freedom.jpg`,
			imageWidth: 1280,
			imageHeight: 870,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Words of Freedom',
			description: 'You only need 12 words for your financial freedom. Artwork inspired by the BIP-39 wordlist. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold on black soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 785672,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Head-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Head-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Head.jpg`,
			imageWidth: 1078,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Head',
			description: 'What\'s in your head? As Bitcoin is digital, we are all connected via circuits to Bitcoin\'s network. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on black soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 771078,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Einundzwanzig-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Einundzwanzig-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Einundzwanzig.jpg`,
			imageWidth: 960,
			imageHeight: 1280,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Einundzwanzig',
			description: 'One of my first artworks - it\'s my first ever circuit board! - Testing what\'s possible on a circuit board. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on green soldermask.',
			link: ARTISTS.modeotec.link,
		},
		{
			block: 771075,
			gThumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Honeybadger-gthumb.jpg`,
			thumbUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Honeybadger-thumb.jpg`,
			imageUrl: `${ARTISTS.modeotec.baseUrl}/MODEOTEC_Honeybadger.jpg`,
			imageWidth: 1200,
			imageHeight: 1090,
			attribution: ARTISTS.modeotec.name,
			cats: ARTISTS.modeotec.cats,
			title: 'Honeybadger',
			description: 'My first circuit board as Modeotec - inspired by a honey badger - he looks at Bitcoin. Base Material: Fiberglass/Epoxy and Copper. Surface: Gold and white silkscreen on green soldermask.',
			link: ARTISTS.modeotec.link,
		},
		// PROOF OF PAINT
		{
			block: 813562,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Gradually-Then-Suddenly-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Gradually-Then-Suddenly-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Gradually-Then-Suddenly.jpg`,
			imageWidth: 1280,
			imageHeight: 915,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Gradually, Then Suddenly',
			description: 'Cover art for the book by Parker A. Lewis',
			link: ARTISTS.pop.link,
		},
		{
			block: 809843,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Gilded-Key-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Gilded-Key-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Gilded-Key.jpg`,
			imageWidth: 723,
			imageHeight: 1280,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Gilded Key',
			description: 'Graphite, Watercolor and 23.7k Gold on Paper',
			link: ARTISTS.pop.link,
		},
		{
			block: 756878,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Moonlight-Cornita-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Moonlight-Cornita-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Moonlight-Cornita.jpg`,
			imageWidth: 931,
			imageHeight: 1200,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Moonlight Cornita',
			link: ARTISTS.pop.link,
		},
		{
			block: 727156,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Sister-of-the-Coin-3-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Sister-of-the-Coin-3-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Sister-of-the-Coin-3.jpg`,
			imageWidth: 1005,
			imageHeight: 1200,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Sister of the Coin #3',
			link: ARTISTS.pop.link,
		},
		{
			block: 727155,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Sister-of-the-Coin-2-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Sister-of-the-Coin-2-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Sister-of-the-Coin-2.jpg`,
			imageWidth: 1005,
			imageHeight: 1200,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Sister of the Coin #2',
			link: ARTISTS.pop.link,
		},
		{
			block: 725272,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Cornita-and-the-Frog-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Cornita-and-the-Frog-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Cornita-and-the-Frog.jpg`,
			imageWidth: 984,
			imageHeight: 1200,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Cornita and the Frog',
			link: ARTISTS.pop.link,
		},
		{
			block: 709557,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Christine-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Christine-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Christine.jpg`,
			imageWidth: 1024,
			imageHeight: 1280,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Christine',
			link: ARTISTS.pop.link,
		},
		{
			block: 705011,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Have-Moon-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Have-Moon-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Have-Moon.jpg`,
			imageWidth: 1280,
			imageHeight: 723,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Have Moon',
			description: 'Celebrating Bitcoin 69k ATH',
			link: ARTISTS.pop.link,
		},
		{
			block: 702453,
			gThumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Soveregn-Island-1-gthumb.jpg`,
			thumbUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Soveregn-Island-1-thumb.jpg`,
			imageUrl: `${ARTISTS.pop.baseUrl}/ProofOfPaint_Soveregn-Island-1.jpg`,
			imageWidth: 932,
			imageHeight: 1280,
			attribution: ARTISTS.pop.name,
			cats: ARTISTS.pop.cats,
			title: 'Sovereign Island #1',
			link: ARTISTS.pop.link,
		},
		// REBEL MONEY
		{
			block: 803531,
			gThumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Penny-Orange-gthumb.jpg`,
			thumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Penny-Orange-thumb.jpg`,
			imageUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Penny-Orange.jpg`,
			imageWidth: 1030,
			imageHeight: 1200,
			attribution: ARTISTS.rebelmoney.name,
			cats: ARTISTS.rebelmoney.cats,
			title: 'Penny Orange',
			description: 'The Penny Orange pays homage to Bitcoin, the groundbreaking decentralised protocol that is revolutionising the way we transmit value and energy. This work draws inspiration from the Penny Black, the first adhesive postage stamp released in 1840, which transformed the postal system by introducing a flat rate of one penny for letter delivery, regardless of the distance.',
			link: `${ARTISTS.rebelmoney.link}/art/pennyorange`,
		},
		{
			block: 787377,
			gThumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Vespa-Libertas-gthumb.jpg`,
			thumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Vespa-Libertas-thumb.jpg`,
			imageUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Vespa-Libertas.jpg`,
			imageWidth: 1200,
			imageHeight: 1200,
			attribution: ARTISTS.rebelmoney.name,
			cats: ARTISTS.rebelmoney.cats,
			title: 'Vespa Libertas',
			description: 'Vespa Libertas pays tribute to the Cyber Hornets - the worldwide Bitcoin community that mines, protects, and defends the spread of a revolutionary financial system founded on freedom.',
			link: `${ARTISTS.rebelmoney.link}/art/vespa`,
		},
		{
			block: 780090,
			gThumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Matchbox-Belief-gthumb.jpg`,
			thumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Matchbox-Belief-thumb.jpg`,
			imageUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Matchbox-Belief.jpg`,
			imageWidth: 750,
			imageHeight: 750,
			attribution: ARTISTS.rebelmoney.name,
			cats: ARTISTS.rebelmoney.cats,
			title: 'Matchbox Belief',
			description: 'Drawing parallels from Christianity, Matchbox Belief explores Bitcoin not just as code, a network or a movement - but as a religion.',
			link: `${ARTISTS.rebelmoney.link}/art/matchboxbelief`,
		},
		{
			block: 752790,
			gThumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Believe-gthumb.jpg`,
			thumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Believe-thumb.jpg`,
			imageUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Believe.jpg`,
			imageWidth: 750,
			imageHeight: 1045,
			attribution: ARTISTS.rebelmoney.name,
			cats: ARTISTS.rebelmoney.cats,
			title: 'Believe',
			description: 'Drawing parallels from Christianity, Believe explores Bitcoin not just as code, a network or a movement - but as a religion.',
			link: `${ARTISTS.rebelmoney.link}/art/believe`,
		},
		{
			block: 679966,
			gThumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Moneytov-gthumb.jpg`,
			thumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Moneytov-thumb.jpg`,
			imageUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_Moneytov.jpg`,
			imageWidth: 762,
			imageHeight: 728,
			attribution: ARTISTS.rebelmoney.name,
			cats: ARTISTS.rebelmoney.cats,
			title: 'Moneytov',
			description: 'A weapon used by many freedom fighters in the past, the fire of the Molotov cocktail burns with the light of revolution. It is a tool that can be wielded by the common man, against oppressive forces with devastating effect. In Moneytov, Bitcoin is the fire that ignites the spark of the rebellion against the banks and corrupt institutions.',
			link: `${ARTISTS.rebelmoney.link}/art/moneytov`,
		},
		{
			block: 679965,
			gThumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_21-Licks-gthumb.jpg`,
			thumbUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_21-Licks-thumb.jpg`,
			imageUrl: `${ARTISTS.rebelmoney.baseUrl}/REBEL-MONEY_21-Licks.jpg`,
			imageWidth: 1000,
			imageHeight: 839,
			attribution: ARTISTS.rebelmoney.name,
			cats: ARTISTS.rebelmoney.cats,
			title: '21 Licks',
			description: 'Bitcoin is a drug. An addiction. Once it takes hold there is no going back. 21 Licks is a visual representation of the ‘Orange Pill’ journey. The blue lips of pop culture still transparent against the upside down and backward fiat system. The hard money pill Bitcoin is opaque and about to deliver a payload of truth.',
			link: `${ARTISTS.rebelmoney.link}/art/21licks`,
		},
		// RICHARD
		{
			block: 775053,
			gThumbUrl: `${ARTISTS.richard.baseUrl}/RICHARD_The-Validation-gthumb.jpg`,
			thumbUrl: `${ARTISTS.richard.baseUrl}/RICHARD_The-Validation-thumb.jpg`,
			imageUrl: `${ARTISTS.richard.baseUrl}/RICHARD_The-Validation.jpg`,
			imageWidth: 900,
			imageHeight: 530,
			attribution: ARTISTS.richard.name,
			cats: ARTISTS.richard.cats,
			title: 'The Validation',
			link: ARTISTS.richard.link,
			videoSrc: 'DwVHJgZiWDU',
		},
		{
			block: 753692,
			gThumbUrl: `${ARTISTS.richard.baseUrl}/RICHARD_All-Falls-Down-gthumb.jpg`,
			thumbUrl: `${ARTISTS.richard.baseUrl}/RICHARD_All-Falls-Down-thumb.jpg`,
			imageUrl: `${ARTISTS.richard.baseUrl}/RICHARD_All-Falls-Down.jpg`,
			imageWidth: 900,
			imageHeight: 533,
			attribution: ARTISTS.richard.name,
			cats: ARTISTS.richard.cats,
			title: 'All Falls Down [Orange Pill Edition]',
			link: ARTISTS.richard.link,
			videoSrc: 'ZsXaaTgJZzI',
		},
		{
			block: 750350,
			gThumbUrl: `${ARTISTS.richard.baseUrl}/RICHARD_Where-Is-My-Mind-gthumb.jpg`,
			thumbUrl: `${ARTISTS.richard.baseUrl}/RICHARD_Where-Is-My-Mind-thumb.jpg`,
			imageUrl: `${ARTISTS.richard.baseUrl}/RICHARD_Where-Is-My-Mind.jpg`,
			imageWidth: 900,
			imageHeight: 654,
			attribution: ARTISTS.richard.name,
			cats: ARTISTS.richard.cats,
			title: 'Where is My Mind [Orange Pill Edition] ft. John Vallis & American HODL',
			link: ARTISTS.richard.link,
			videoSrc: 'Q3aLqqWQhxY',
		},
		// STREET CYBER
		{
			block: 823700,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_823700-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_823700-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_823700.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '823700',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 816736,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816736-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816736-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816736.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '816736',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 816735,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816735-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816735-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816735.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '816735',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 816734,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816734-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816734-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816734.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '816734',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 816731,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816731-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816731-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_816731.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '816731',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 814675,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_814675-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_814675-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_814675.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '814675',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 808565,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_808565-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_808565-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_808565.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '808565',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 806785,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_806785-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_806785-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_806785.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '806785',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 801642,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_801642-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_801642-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_801642.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '801642',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 799290,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_799290-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_799290-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_799290.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '799290',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 798986,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_798986-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_798986-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_798986.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '798986',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 793719,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_793719-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_793719-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_793719.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '793719',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 789849,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_789849-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_789849-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_789849.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '789849',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 788120,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_788120-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_788120-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_788120.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '788120',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 785649,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_785649-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_785649-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_785649.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '785649',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 779129,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_779129-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_779129-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_779129.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '779129',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 778792,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_778792-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_778792-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_778792.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '778792',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 777198,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_777198-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_777198-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_777198.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '777198',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 768339,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_768339-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_768339-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_768339.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '768339',
			link: ARTISTS.streetcyber.link,
		},
		{
			block: 733361,
			gThumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_733361-gthumb.jpg`,
			thumbUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_733361-thumb.jpg`,
			imageUrl: `${ARTISTS.streetcyber.baseUrl}/STREETCYBER_733361.jpg`,
			imageWidth: 1000,
			imageHeight: 1000,
			attribution: ARTISTS.streetcyber.name,
			cats: ARTISTS.streetcyber.cats,
			title: '733361',
			link: ARTISTS.streetcyber.link,
		},
		// TIP NZ
		{
			block: 801483,
			gThumbUrl: `${ARTISTS.tipnz.baseUrl}/TIP-NZ_Break-The-Frame-gthumb.jpg`,
			thumbUrl: `${ARTISTS.tipnz.baseUrl}/TIP-NZ_Break-The-Frame-thumb.jpg`,
			imageUrl: `${ARTISTS.tipnz.baseUrl}/TIP-NZ_Break-The-Frame.jpg`,
			imageWidth: 900,
			imageHeight: 503,
			attribution: ARTISTS.tipnz.name,
			cats: ARTISTS.tipnz.cats,
			title: 'Break The Frame',
			link: ARTISTS.tipnz.link,
			videoSrc: 'PClV-3G7cs0',
		},
		{
			block: 766000,
			gThumbUrl: `${ARTISTS.tipnz.baseUrl}/TIP-NZ_Cantillionaires-Game-gthumb.jpg`,
			thumbUrl: `${ARTISTS.tipnz.baseUrl}/TIP-NZ_Cantillionaires-Game-thumb.jpg`,
			imageUrl: `${ARTISTS.tipnz.baseUrl}/TIP-NZ_Cantillionaires-Game.jpg`,
			imageWidth: 900,
			imageHeight: 511,
			attribution: ARTISTS.tipnz.name,
			cats: ARTISTS.tipnz.cats,
			title: 'Cantillionaires Game',
			link: ARTISTS.tipnz.link,
			videoSrc: 'g_eIkVxo2uc',
		},
		// YEGOR
		{
			block: 768696,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_purple-nostrich-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_purple-nostrich-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_purple-nostrich.jpg`,
			imageWidth: 893,
			imageHeight: 893,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Purple Nostrich',
			link: ARTISTS.yegor.link,
		},
		{
			block: 768240,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-hodler-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-hodler-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-hodler.jpg`,
			imageWidth: 985,
			imageHeight: 985,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Bitcoin Hodler',
			link: ARTISTS.yegor.link,
		},
		{
			block: 767942,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_stay-humble-stack-sats-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_stay-humble-stack-sats-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_stay-humble-stack-sats.jpg`,
			imageWidth: 947,
			imageHeight: 947,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Stay Humble Stack Sats',
			link: ARTISTS.yegor.link,
		},
		{
			block: 767651,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-block-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-block-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-block.jpg`,
			imageWidth: 996,
			imageHeight: 996,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Bitcoin Block',
			link: ARTISTS.yegor.link,
		},
		{
			block: 767554,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_orange-pill-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_orange-pill-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_orange-pill.jpg`,
			imageWidth: 1209,
			imageHeight: 1209,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Orange Pill',
			link: ARTISTS.yegor.link,
		},
		{
			block: 766092,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-rabbit-hole-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-rabbit-hole-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-rabbit-hole.jpg`,
			imageWidth: 806,
			imageHeight: 806,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Bitcoin Rabbit Hole',
			link: ARTISTS.yegor.link,
		},
		{
			block: 766017,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_satoshi-nakamoto-navigating-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_satoshi-nakamoto-navigating-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_satoshi-nakamoto-navigating.jpg`,
			imageWidth: 1242,
			imageHeight: 1242,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Satoshi Nakamoto Navigating Time',
			link: ARTISTS.yegor.link,
		},
		{
			block: 765889,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-time-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-time-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-time.jpg`,
			imageWidth: 1161,
			imageHeight: 1160,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Bitcoin Time',
			link: ARTISTS.yegor.link,
		},
		{
			block: 765693,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-world-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-world-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-world.jpg`,
			imageWidth: 767,
			imageHeight: 767,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Bitcoin World',
			link: ARTISTS.yegor.link,
		},
		{
			block: 764535,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_urban-bitcoin-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_urban-bitcoin-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_urban-bitcoin.jpg`,
			imageWidth: 809,
			imageHeight: 809,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Urban Bitcoin',
			link: ARTISTS.yegor.link,
		},
		{
			block: 764525,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_pleb-underground-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_pleb-underground-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_pleb-underground.jpg`,
			imageWidth: 763,
			imageHeight: 763,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Pleb Underground',
			link: ARTISTS.yegor.link,
		},
		{
			block: 764406,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_diamond-hands-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_diamond-hands-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_diamond-hands.jpg`,
			imageWidth: 1242,
			imageHeight: 1242,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Diamond Hands',
			link: ARTISTS.yegor.link,
		},
		{
			block: 762198,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-heart-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-heart-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_bitcoin-heart.jpg`,
			imageWidth: 849,
			imageHeight: 849,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Bitcoin Heart',
			link: ARTISTS.yegor.link,
		},
		{
			block: 754382,
			gThumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_silk-road-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_silk-road-thumb.jpg`,
			imageUrl: `${ARTISTS.yegor.baseUrl}/YEGOR_silk-road.jpg`,
			imageWidth: 1028,
			imageHeight: 1028,
			attribution: ARTISTS.yegor.name,
			cats: ARTISTS.yegor.cats,
			title: 'Silk Road',
			link: ARTISTS.yegor.link,
		},
		// YONAT VAKS
		{
			block: 816861,
			gThumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Difficulty-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Difficulty-thumb.jpg`,
			imageUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Difficulty.jpg`,
			imageWidth: 1280,
			imageHeight: 801,
			attribution: ARTISTS.yonatvaks.name,
			cats: ARTISTS.yonatvaks.cats,
			title: 'Why is Bitcoin difficulty so high?',
			link: 'https://www.yonatvaks.com/about-3',
			description: 'Meme culture is the artform of the internet. It serves as a conduit for spreading ideas, humor and information in a captivating way. Within the digital landscape, memes have played a pivotal role in bringing niche topics such as Bitcoin to a widespread audience. It has also fostered a sense of community and engagement around Bitcoin, ultimately contributing to its mainstream adoption. Bitcoin FUDers have often referred to Bitcoin as being a joke, unworthy of attention. In the spirit of meme culture, these paintings use that statement as leverage. Each painting is a known joke, with the beloved Bitcoin animal memes. If Bitcoin would be any animal, it would be the honey badger. The honey badger is notorious for its strength, resilience and toughness. ¨The honey badger of money¨ is probably one of the first Bitcoin memes. The bull representes those who believe the price will go up over time. The whales are those bulls that have a lot of Bitcoin. And the frog.. Pepe the frog, one of the first NFTs connected to Bitcoin and probably the biggest meme of them all. They Say Bitcoin is a Joke, but we¨ll see who gets the last laugh...',
		},
		{
			block: 812291,
			gThumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Badger-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Badger-thumb.jpg`,
			imageUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Badger.jpg`,
			imageWidth: 1052,
			imageHeight: 1280,
			attribution: ARTISTS.yonatvaks.name,
			cats: ARTISTS.yonatvaks.cats,
			title: 'Why did the honey badger cross the road?',
			link: 'https://www.yonatvaks.com/about-3',
			description: 'Meme culture is the artform of the internet. It serves as a conduit for spreading ideas, humor and information in a captivating way. Within the digital landscape, memes have played a pivotal role in bringing niche topics such as Bitcoin to a widespread audience. It has also fostered a sense of community and engagement around Bitcoin, ultimately contributing to its mainstream adoption. Bitcoin FUDers have often referred to Bitcoin as being a joke, unworthy of attention. In the spirit of meme culture, these paintings use that statement as leverage. Each painting is a known joke, with the beloved Bitcoin animal memes. If Bitcoin would be any animal, it would be the honey badger. The honey badger is notorious for its strength, resilience and toughness. ¨The honey badger of money¨ is probably one of the first Bitcoin memes. The bull representes those who believe the price will go up over time. The whales are those bulls that have a lot of Bitcoin. And the frog.. Pepe the frog, one of the first NFTs connected to Bitcoin and probably the biggest meme of them all. They Say Bitcoin is a Joke, but we¨ll see who gets the last laugh...',
		},
		{
			block: 810593,
			gThumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Bar-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Bar-thumb.jpg`,
			imageUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Bar.jpg`,
			imageWidth: 1280,
			imageHeight: 789,
			attribution: ARTISTS.yonatvaks.name,
			cats: ARTISTS.yonatvaks.cats,
			title: 'A bull, a whale and a honey badger walk into a bar.',
			link: 'https://www.yonatvaks.com/about-3',
			description: 'Meme culture is the artform of the internet. It serves as a conduit for spreading ideas, humor and information in a captivating way. Within the digital landscape, memes have played a pivotal role in bringing niche topics such as Bitcoin to a widespread audience. It has also fostered a sense of community and engagement around Bitcoin, ultimately contributing to its mainstream adoption. Bitcoin FUDers have often referred to Bitcoin as being a joke, unworthy of attention. In the spirit of meme culture, these paintings use that statement as leverage. Each painting is a known joke, with the beloved Bitcoin animal memes. If Bitcoin would be any animal, it would be the honey badger. The honey badger is notorious for its strength, resilience and toughness. ¨The honey badger of money¨ is probably one of the first Bitcoin memes. The bull representes those who believe the price will go up over time. The whales are those bulls that have a lot of Bitcoin. And the frog.. Pepe the frog, one of the first NFTs connected to Bitcoin and probably the biggest meme of them all. They Say Bitcoin is a Joke, but we¨ll see who gets the last laugh...',
		},
		{
			block: 787935,
			gThumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Herald-Drum-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Herald-Drum-thumb.jpg`,
			imageUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Herald-Drum.jpg`,
			imageWidth: 1280,
			imageHeight: 697,
			attribution: ARTISTS.yonatvaks.name,
			cats: ARTISTS.yonatvaks.cats,
			title: 'Heralds of Change',
			link: 'https://www.yonatvaks.com/theheraldsofchange',
			description: 'Musical instruments are an essential part of a culture and its foundations. A material instrument that lets us touch the immaterial, bridging the gap between the physical body and the soul. In the past, musical instruments were fundamental in religious ceremonies, agrarian festivals, seasonal rituals and the rites of the human lifecycle. Archeologists suggest music may have helped to maintain bonds between larger groups of humans, and that this may have helped the species to expand both in numbers and in geographical range. These larger social networks, bonded by music rituals, might of have provided a competitive advantage over the Neanderthals. As human civilizations around the world based their rituals, festivals, culture and calls to war, with music as a fundamental way to envoke emotion and unity, so shalll the rising Bitcoin civilization. THE NEW BITCOIN CIVILIZATION WILL HERALD THE CHANGE IN THE WORLD PARADIGM WITH IT´S VERY OWN MUSICAL INSTRUMENTS. Like the music that was played to destroy the walls of Jericho, music played with these instruments will lead to the falling of the walls of Fiat. The oldest musical instruments to be found were woodwind and percussion instruments. Following the way human culture was created, the first Bitcoin instruments shall be woodwind and percussion.',
		},
		{
			block: 726115,
			gThumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Fellowship-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Fellowship-thumb.jpg`,
			imageUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Fellowship.jpg`,
			imageWidth: 1205,
			imageHeight: 1725,
			attribution: ARTISTS.yonatvaks.name,
			cats: ARTISTS.yonatvaks.cats,
			title: 'The Fellowship of the Hodlr',
			link: 'https://www.yonatvaks.com/the-fellowship-of-the-hodlr-cloak',
			description: 'HODLRS, the hardcore believers in Bitcoin. The ones that know Bitcoin can change the system and the world. Never selling, no matter what. A cornerstone in Bitcoins mythology and the source of inspiration for this piece. So how do you portray a HODLR? I looked for something that in it\'s very essence would portray the qualities of the HODLR. Determined believers. A tribe that shares a common ideal. Humble warriors in a battle for a better world. Strong handed individuals. And then I found it. A garment suited only for the finest defenders of the citadel. The Alistane Cape is a special traditional handmade garment, worn only in  NW Spain and NE Portugal in certain occasions. It has a fascinating and mysterious long history, starring Roman legions, monks and humble shepherds and black sheep. The artistic genesis of this work has been a challenging process. As Ariadna, I had to find and pull a thread to find my way; As Jason, I ended up searching for a fleece, a black fleece. Trips of hundreds of miles, countless research and conversations with all kinds of people in a far away land.',
		},
		{
			block: 694146,
			gThumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Hodlssey-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Hodlssey-thumb.jpg`,
			imageUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Hodlssey.jpg`,
			imageWidth: 1400,
			imageHeight: 993,
			attribution: ARTISTS.yonatvaks.name,
			cats: ARTISTS.yonatvaks.cats,
			title: 'The Hodlssey - Hodlysseus and the journey back to Bithaca',
			link: 'https://www.yonatvaks.com/the-hodlssey-a-series-of-6-wax-tablets',
			description: 'Fiat was destroyed, but the trials of clever Hodlysseus had just begun. Follow the Hodlssey, his ten-year journey back to Bithaca after the long Fiat wars with perils at sea and vengeful deities.',
		},
		{
			block: 699133,
			gThumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Spanish-Guitar-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Spanish-Guitar-thumb.jpg`,
			imageUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Spanish-Guitar.jpg`,
			imageWidth: 1280,
			imageHeight: 960,
			attribution: ARTISTS.yonatvaks.name,
			cats: ARTISTS.yonatvaks.cats,
			title: 'Sound Money - Handmade Painted Spanish Classical Guitar',
			link: 'https://www.yonatvaks.com/sound-money',
			description: 'DJ Honeybadger plays the Bitcoin tune while the whale, the bull, the bear, the shark and the sheep just continue in their game of musical chairs.. forever going around in circles.. not realizing they can step out and just enjoy the music. The story begins with a guitar from the 19th Century. A guitar beyond repair that came from the times of the father of the classical guitar, Antonio de Torres. Judging by its design and where it was found, it could have been made by the master himself, or one of his contemporaries... one thing is for sure... it holds the spirit of the Stradivarius of guitars. Jesús Pérez comes from a family of Spanish classical guitar makers. He learned this traditional craft, passed on by generatons, from his father when he was growing up. Jesús was happy to join my endeavour of going back to the roots of the classical guitar and created a replica of the old guitar from the 19th century. Just as the story of Bitcoin is one of proof of work, of diligence and care, so is this guitar. I painted it with the story of the honeybadger and how he doesn´t play the musical chair game of the market. He listens to his own tunes and enjoyes the ride. Bitcoin culture is one of the important aspects of its adoption and what better way to show your culture, than through music.',
		},
		{
			block: 609098,
			gThumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Harpoon-gthumb.jpg`,
			thumbUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Harpoon-thumb.jpg`,
			imageUrl: `${ARTISTS.yonatvaks.baseUrl}/YONATVAKS_Harpoon.jpg`,
			imageWidth: 1195,
			imageHeight: 675,
			attribution: ARTISTS.yonatvaks.name,
			cats: ARTISTS.yonatvaks.cats,
			title: 'Bitcoin Whaler Basque Harpoon',
			link: 'https://www.yonatvaks.com/crypto-whaler-basque-harpoon',
			description: 'Whales, probably one of the biggest memes in Bitcoin. This piece tells the tale of a Bitcoin whaler, through the story of the Basque whalers. The Basque were the master whalers of their day. They led one of the first large scale economic enterprises of Europeans in North America. There\'s is a story of new frontiers, risk and reward - like the story of Bitcoiners. Each harpoon is made out of a Makila, a traditional Basque walking stick, a harpoon head that is an exact replica of the harpoons used by the Basque whalers and has personalized, unique oil paintings and engravings that depict the story of the person that commissions the piece. The Makila is an elegant walking stick, a Basque symbol of nobility and respect, as well as a security element. It is entirely hand-made through a manufacturing process that has been maintained over various centuries. The paintings on the upper part tell the story of the person who commissioned the piece - someone who took a risk on bitcoin, someone that started this voyage, and what that voyage is about for them. Each story is told through different symbols and images, different colors and different silver engravings.',
		},
	]
	: []
