import {
	useRef,
	useMemo,
} from 'react'
import { Flex } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import {
	scrubBlockState,
	staticModeState,
	confModeState,
	scrubbingState,
	landscapeOrientationState,
} from '../../../state'
import { BLOCK_EMBEDS } from '../../../constants'
import { BlockEmbeds } from './BlockEmbeds'

export const BlockEmbedTarget = () => {
	const staticMode = useRecoilValue(staticModeState)
	const scrubbing = useRecoilValue(scrubbingState)
	const confMode = useRecoilValue(confModeState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const targetRef = useRef(null)

	// FIND EMBED ON THIS BLOCK
	const EMBEDS = useMemo(() => BLOCK_EMBEDS, [])
	const blockEmbed = EMBEDS.find((embed) => embed.block === scrubBlock.height)
	const showGallery = !confMode && process.env.REACT_APP_SELF_HOSTED !== 'true'
	const showBlockEmbeds = staticMode
		&& showGallery
		&& blockEmbed
		&& !scrubbing
	const dimension = 150
	const dimensionString = `${dimension}px`
	const responsivePy = landscapeOrientation ? 7 : 4

	if (!showBlockEmbeds) {
		return null
	}

	return (
		<Flex
			className="block-gallery-embed-wrap"
			justify="center"
			py={responsivePy}
		>
			<Flex
				className="tc-block-embed-target"
				ref={targetRef}
				w={dimensionString}
				h={dimensionString}
				zIndex="2100"
				overflow="visible"
				animation="blurIn 0.42s ease-in"
			>
				<BlockEmbeds />
			</Flex>
		</Flex>
	)
}