import { Flex, Text } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { BITCOIN_ORANGE } from '../../constants'

export const NoMatchRoute = () => {
	return (
		<Flex
			direction="column"
			justify="center"
			align="center"
		>
			<Text
				fontSize="20px"
				fontWeight="bold"
				mb="24px"
			>
				<FormattedMessage id="app.no_match_route" />
			</Text>
			<RouterLink to="/">
				<Text color={BITCOIN_ORANGE}>
					<FormattedMessage id="app.go_home" />
				</Text>
			</RouterLink>
		</Flex>
	)
}
