import {
	useState,
	useEffect,
	useLayoutEffect,
	useRef,
	useMemo,
} from 'react'
import {
	Flex,
	Box,
	Text,
	chakra,
	shouldForwardProp,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import { motion, isValidMotionProp } from 'framer-motion'
import { gsap } from 'gsap'
import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState,
} from 'recoil'
import { differenceInMinutes } from 'date-fns'

import {
	staticModeState,
	circlesWrapWidthState,
	showBlockRippleState,
	circlesBreakpointState,
	diffBlocksVsTargetState,
	previousTimeState,
	remainingBlocksState,
	daIntroCompleteState,
	halvingIntroStartState,
	landscapeOrientationState,
} from '../../../state'
import { useAppSelector } from '../../../hooks'
import { CircleWrap } from './CircleWrap'
import { TopMark } from './TopMark'
import { TextPathCircle } from './TextPathCircle'
import { CircleIndicatorArrow } from './CircleIndicatorArrow'
import { DIFFICULTY_ADJUSTMENT_BLOCKS } from '../../../constants'
import {
	getRotationAngle,
	roundToEven,
	getBreakpointValue,
	positiveInt,
} from '../../../utils'
import type { DA } from '../../../models'
import { blocksToDiffOffset, borderWidthBreakpoints, textCircleTransformConfig } from './circles.constants'

export interface BlocksToDiffProps {
	confMode?: boolean
	da?: DA
	staticMode?: boolean
	containerWidth?: number
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const BlocksToDiff = () => {
	const intl = useIntl()
	const { reducedMotion, disableDifficultyPulse } = useAppSelector(({ settings }) => settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const staticMode = useRecoilValue(staticModeState)
	const circlesWrapWidth = useRecoilValue(circlesWrapWidthState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const diffBlocksVsTarget = useRecoilValue(diffBlocksVsTargetState)
	const setDiffBlocksVsTarget = useSetRecoilState(diffBlocksVsTargetState)
	const remainingBlocks = useRecoilValue(remainingBlocksState)
	const previousTime = useRecoilValue(previousTimeState)
	const halvingIntroStart = useRecoilValue(halvingIntroStartState)
	const [daIntroComplete, setDaIntroComplete] = useRecoilState(daIntroCompleteState)
	const [currentBlock, setCurrentBlock] = useState(0)
	const [circleAnimationDuration, setCircleAnimationDuration] = useState(0)
	const [circleClientWidth, setCircleClientWidth] = useState(0)
	const [textTransform, setTextTransform] = useState<string | undefined>(undefined)
	const [refAcquired, setRefAcquired] = useState(false)
	const circleRef = useRef<HTMLDivElement>(null)
	const diffIndicatorRef = useRef<HTMLDivElement>(null)
	const diffTextRef = useRef<HTMLDivElement>(null)
	const [red100] = useToken('colors', ['red100'])

	const showTargetBlocks = remainingBlocks <= 1008 && (diffBlocksVsTarget > 9 || diffBlocksVsTarget < -9)

	const angle = getRotationAngle(remainingBlocks / DIFFICULTY_ADJUSTMENT_BLOCKS)
	const indicatorRotatedText = angle > 100 && angle < 260
	const indicatorTextTransform = indicatorRotatedText ? 'rotate(180deg)' : undefined
	const shouldBur = staticMode || showBlockRipple
	const opacity = shouldBur ? 0.3 : 1
	const filter = shouldBur ? 'blur(4px)' : 'blur(0)'
	const transitionBlocks = 88
	const darkColorOpacity = ((remainingBlocks / transitionBlocks)).toFixed(2)
	const color = useColorModeValue('black', 'white')
	const bg = useColorModeValue('white', 'black')
	const alpha = useColorModeValue('rgba(255,255,255,0.5)', 'rgba(0,0,0,0.65)')
	const color1 = '#ff0000'
	const color2 = `rgba(255, 0, 0, ${darkColorOpacity})`
	const shouldAnimate = currentBlock > 0
		&& currentBlock < transitionBlocks
		&& !reducedMotion
		&& !disableDifficultyPulse
	const pulseColor = shouldAnimate
		? {
			borderColor: [
				color1,
				color2,
				color1,
			]
		}
		: undefined
	const pulseTransition = shouldAnimate
		? {
			duration: circleAnimationDuration,
			ease: 'easeInOut',
			repeat: Infinity,
			repeatType: 'loop',
		}
		: undefined
	const circleSize = getBreakpointValue(blocksToDiffOffset, circlesBreakpoint)
	const borderWidth = getBreakpointValue(borderWidthBreakpoints, circlesBreakpoint)
	const responsivePillBorderWidth = getBreakpointValue({ base: 1, lg: 2, jumbo: 3 }, circlesBreakpoint)
	const responsivePillWidth = getBreakpointValue({ base: '42px', sm: '48px', lg: '56px', jumbo: '62px' }, circlesBreakpoint)
	const responsivePillTop = getBreakpointValue({ base: '-9px', sm: '-10px', lg: '-15px', jumbo: '-17px' }, circlesBreakpoint)
	const responsivePillLeft = getBreakpointValue({ base: '-11px', sm: '-14px', lg: '-18px', jumbo: '-21px' }, circlesBreakpoint)
	const responsiveFontSize = getBreakpointValue({ base: 'xxs', sm: 'xs', lg: '14px', jumbo: '15px' }, circlesBreakpoint)
	const responsiveFontWeight = getBreakpointValue({ base: 'bold', sm: 'semibold', lg: 'bold' }, circlesBreakpoint)
	const textCircleTransform = useMemo(
		() => getBreakpointValue(textCircleTransformConfig, circlesBreakpoint) as string | undefined,
		[circlesBreakpoint, landscapeOrientation],
	)

	const diffToTargetString = diffBlocksVsTarget < 0
		? `${positiveInt(diffBlocksVsTarget)} fast`
		: `${positiveInt(diffBlocksVsTarget)} slow`
	const circleText = showTargetBlocks
		? `${intl.formatMessage({ id: 'circles.blocks_to_difficulty_adjust' })}${` - ${diffToTargetString}`} >`
		: `${intl.formatMessage({ id: 'circles.blocks_to_difficulty_adjust' })} >`

	useEffect(() => {
		const durationFloor = 0.2
		const newDuration = Number((((roundToEven(remainingBlocks) / transitionBlocks) * 1.75) + durationFloor).toFixed(2))
		if (newDuration !== circleAnimationDuration) {
			setCircleAnimationDuration(newDuration)
		}
	}, [
		remainingBlocks,
		circleAnimationDuration,
	])

	// INIT
	useEffect(() => {
		setRefAcquired(true)
	}, [])

	// CIRCLE SIZING
	useLayoutEffect(() => {
		const setCircleWidth = () => {
			const circle = circleRef.current
			if (circle) setCircleClientWidth(circle.clientWidth)
		}
		setCircleWidth()
		setTextTransform(textCircleTransform)
		window.addEventListener('resize', setCircleWidth)
		return () => window.removeEventListener('resize', setCircleWidth)
	}, [refAcquired, textCircleTransform])

	// SET VALUES
	useEffect(() => {
		const minutesSinceLastDA = differenceInMinutes(new Date(), new Date(previousTime * 1000))
		const tenMinuteBlocksSinceLastDA = Number((minutesSinceLastDA / 10).toFixed(0))
		const remainingTargetBlocks = DIFFICULTY_ADJUSTMENT_BLOCKS - tenMinuteBlocksSinceLastDA
		const diffToTargetBlocks = remainingBlocks - remainingTargetBlocks

		if (currentBlock !== remainingBlocks) {
			setCurrentBlock(remainingBlocks)
			setDiffBlocksVsTarget(diffToTargetBlocks)
			return
		}
		return
	}, [
		currentBlock,
		remainingBlocks,
		previousTime,
	])

	// ANIMATE INTRO
	useLayoutEffect(() => {
		const ctx = gsap.context(() => {
			if (halvingIntroStart && !daIntroComplete) {
				gsap.from(diffIndicatorRef.current, {
					rotation: '0_ccw',
					duration: 2,
					ease: 'easeIn',
					delay: 0.5,
					onComplete: () => {
						setDaIntroComplete(true)
					},
				})
				gsap.from(diffTextRef.current, {
					opacity: 0,
					duration: 1,
					ease: 'easeIn',
					delay: 0.5,
				})
			}
		})

		return () => ctx.revert()
	}, [
		halvingIntroStart,
		daIntroComplete,
	])

	return (
		<CircleWrap
			className="blocks-to-diff-circle"
			opacity={opacity}
			filter={filter}
			transition="all 0.45s ease"
		>
			<TextPathCircle
				overrideTransform={textTransform}
				containerWidth={circlesWrapWidth}
				circleWidth={circleClientWidth}
				text={circleText}
				id="diff"
			/>

			<MotionBox
				ref={circleRef}
				pos="absolute"
				top={circleSize}
				left={circleSize}
				right={circleSize}
				bottom={circleSize}
				borderRadius="50%"
				borderWidth={borderWidth}
				borderColor="red100"
				animate={pulseColor}
				// @ts-ignore
				transition={pulseTransition}
			>
				<TopMark
					staticMode={staticMode}
					color="red100"
				/>

				<Box
					ref={diffIndicatorRef}
					zIndex={1003}
					pos="absolute"
					h="50%"
					w="20px"
					top={0}
					left={0}
					right={0}
					m="auto"
					transform={`rotateZ(-${angle}deg)`}
					transformOrigin="50% 100%"
				>
					<Flex
						justifyContent="center"
						alignItems="center"
						pos="absolute"
						borderWidth={responsivePillBorderWidth}
						borderRadius="16px"
						borderColor="red100"
						boxShadow={`3px 0px 0px ${alpha}, -3px 0px 0px ${alpha}`}
						color={color}
						bg={bg}
						p="2px 4px"
						w={responsivePillWidth}
						left={responsivePillLeft}
						top={responsivePillTop}
						zIndex="1001"
					>
						<Text
							ref={diffTextRef}
							fontSize={responsiveFontSize}
							lineHeight="none"
							fontWeight={responsiveFontWeight}
							transform={indicatorTextTransform}
						>
							{remainingBlocks}
						</Text>
					</Flex>

					<CircleIndicatorArrow color={red100} />
				</Box>
			</MotionBox>
		</CircleWrap>
	)
}
