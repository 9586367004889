import {
	Flex,
	Box,
	type ResponsiveValue,
	useColorModeValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../state'
import { getBreakpointValue } from '../../utils'

interface PerspectiveProps {
	display: ResponsiveValue<'true' | 'false'>
}

export const Perspective = ({ display }: PerspectiveProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const responsiveHeight = getBreakpointValue({ base: '75%', sm: '50%' }, circlesBreakpoint)
	const responsiveBottom = getBreakpointValue({ base: '-8%', sm: 0 }, circlesBreakpoint)
	const responsiveGradient = getBreakpointValue({
		base: 'linear-gradient(180deg, #eee, white, white, #e5e4e4, #eee)',
		sm: 'linear-gradient(180deg, #fff, white, white, #e5e4e4, #fff)',
	}, circlesBreakpoint)
	const linearGradient = useColorModeValue(
		responsiveGradient,
		'linear-gradient(180deg, black, black, black, #1c1c1c, black)',
	)
	const displayPerspective = display === 'true' ? 'block' : 'none'

	return (
		<Flex
			className="perspective-plane-wrap"
			display={displayPerspective}
			pos="absolute"
			zIndex={-1}
			w="100%"
			h={responsiveHeight}
			bottom={responsiveBottom}
			left={0}
			style={{
				transform: 'translateZ(-90px)',
				transformStyle: 'preserve-3d',
			}}
		>
			<Box
				className="perspective-plane"
				pos="absolute"
				w="100%"
				h="50%"
				bottom={0}
				left={0}
				right={0}
				m="auto"
				transform="rotateX(90deg)"
				style={{
					background: linearGradient,
				}}
			/>
		</Flex>
	)
}