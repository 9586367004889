import {
	Flex,
	Box,
	Text,
	Spinner,
	useColorModeValue,
	useColorMode,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { useToken } from '@chakra-ui/react'

import { useAppSelector } from '../../../../hooks'
import {
	blockState,
	poolNameState,
	circlesBreakpointState,
	staticModeState,
	scrubBlockState,
	showBlockRippleState,
	loadingBlockHeightState,
	scrubbingState,
	shouldSetAppToBlockPathState,
	pastBlockHoverState,
} from '../../../../state'
import { Label, BitcoinSymbolEntity } from '../../../shared'
import {
	satsToBitcoin,
	getBitcoinEpoch,
	getMinerSubsidy,
	calculateDecimalPlaces,
	getBreakpointValue,
} from '../../../../utils'
import { secondaryDataFontSize } from './dataComponents.constants'
import { DataBox } from '../../../layout/sidebar/SidebarSection'

export const DataLastBlockReward = () => {
	const { colorMode } = useColorMode()
	const [lightgreen60, lightgreen80] = useToken('colors', ['lightgreen60', 'lightgreen80'])
	const { showMiningPool } = useAppSelector(({ settings }) => settings)
	const scrubbing = useRecoilValue(scrubbingState)
	const loadingBlockHeight = useRecoilValue(loadingBlockHeightState)
	const shouldSetAppToBlockPath = useRecoilValue(shouldSetAppToBlockPathState)
	const blockHeightReady = !shouldSetAppToBlockPath && !loadingBlockHeight && !scrubbing
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const staticMode = useRecoilValue(staticModeState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const poolName = useRecoilValue(poolNameState)
	const block = useRecoilValue(blockState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const pastBlockHover = useRecoilValue(pastBlockHoverState)

	const color = useColorModeValue('black', 'white')
	const spinnerColor = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.3)')
	const bg = useColorModeValue('rgba(0,0,0,0.025)', 'rgba(255,255,255,0.075)')
	const solidBg = useColorModeValue('white', 'black')
	const green = useColorModeValue(lightgreen60, lightgreen80)
	const borderColor = useColorModeValue('rgba(0,0,0,0.1)', 'rgba(255,255,255,0.1)')

	const bgOverlayDisplay = getBreakpointValue({ base: 'block', lg: 'none' }, circlesBreakpoint)
	const fontSize = getBreakpointValue(secondaryDataFontSize, circlesBreakpoint)
	const topBorderRadius = getBreakpointValue({ base: undefined, sm: '100px' }, circlesBreakpoint)
	const bottomBorderRadius = getBreakpointValue({ base: '100px', sm: undefined }, circlesBreakpoint)
	const responsivePy = getBreakpointValue({ base: '6px', xs: 1, sm: '3px', lg: 1, xl: 2 }, circlesBreakpoint)
	const responsivePx = getBreakpointValue({ base: 2, md: 4 }, circlesBreakpoint)
	const responsiveMinedByH = getBreakpointValue({ base: '14px', md: '16px', xl: '18px' }, circlesBreakpoint)
	const minedByPx = getBreakpointValue({ base: 2, md: 4 }, circlesBreakpoint)
	const responsiveMaxW = getBreakpointValue({ base: undefined, xs: '400px', md: undefined }, circlesBreakpoint)
	const responsiveSpinnerSize = getBreakpointValue({ base: 'xs', md: 'sm' }, circlesBreakpoint) as ResponsiveValue<'xs'|'sm'>
	const responsiveSpinnerMt = getBreakpointValue({ base: '-3px', md: '-2px', lg: '-1px' }, circlesBreakpoint)

	const { extras, tx_count } = block
	const emptyBlock = staticMode
		? scrubBlock.tx_count === 1
		: tx_count === 1
	const epoch = staticMode
		? scrubBlock.height ? getBitcoinEpoch(scrubBlock.height) : ''
		: block.height ? getBitcoinEpoch(block.height) : ''
	const subsidy = epoch ? getMinerSubsidy(epoch) : 50
	const digits = extras ? calculateDecimalPlaces(satsToBitcoin(extras.totalFees)) : 4
	const opacity = showBlockRipple
		? 0.21
		: pastBlockHover
			? 0.5
			: 1
	const filter = showBlockRipple
		? 'blur(4px)'
		: pastBlockHover
			? 'blur(3px)'
			: 'blur(0)'
	const fixedDigits = digits > 4 ? 4 : digits
	const lastBlockReward = staticMode
		? scrubBlock.extras && scrubBlock.extras.totalFees
			? parseFloat((Number(subsidy) + satsToBitcoin(scrubBlock.extras.totalFees)).toFixed(fixedDigits))
			: subsidy
		: extras && extras.totalFees
			? parseFloat((Number(subsidy) + satsToBitcoin(extras.totalFees)).toFixed(fixedDigits))
			: subsidy
	const lastBlockFees = extras && extras.totalFees
		? parseFloat(satsToBitcoin(extras.totalFees).toFixed(fixedDigits))
		: satsToBitcoin(0)
	const scrubBlockFees = scrubBlock.extras && scrubBlock.extras.totalFees
		? parseFloat(satsToBitcoin(scrubBlock.extras.totalFees).toFixed(fixedDigits))
		: satsToBitcoin(0)
	const minedByTransform = blockHeightReady ? 'translateY(0px)' : 'translateY(-18px)'
	const feeColor = emptyBlock || scrubBlockFees === 0 ? 'red100' : color
	const noCondition = emptyBlock || scrubBlock.extras.totalFees === 0 || block.extras.totalFees === 0
	const conditionalDisplay = colorMode === 'light' ? bgOverlayDisplay : 'none'
	const noColor = noCondition ? 'red100' : color
	const blockFees = staticMode ? scrubBlockFees : lastBlockFees
	const conditionalPoolName = scrubBlock.height === 0 ? 'Satoshi' : poolName
	const txFeesPreLabel = noCondition ? 'NO' : 'TX'

	return (
		<Flex
			className="last-block-reward"
			pos="relative"
			direction="column"
			w="100%"
			maxW={responsiveMaxW}
			align="center"
			opacity={opacity}
			filter={filter}
			transition="all 0.21s ease"
		>
			<Flex
				className="tc-rewards"
				pos="relative"
				zIndex={9}
				w="100%"
				borderRadius="100px"
				bg={solidBg}
				overflow="hidden"
			>
				<Flex
					pos="relative"
					w="100%"
					align="flex-start"
					borderWidth={2}
					borderRadius="100px"
					borderColor={borderColor}
					py={responsivePy}
					px={responsivePx}
					bg={bg}
					_after={{
						content: '""',
						w: '100%',
						h: '100%',
						pos: 'absolute',
						top: 0,
						left: 0,
						bg: solidBg,
						borderTopRadius: topBorderRadius,
						borderBottomRadius: bottomBorderRadius,
						zIndex: 1,
						display: conditionalDisplay,
					}}
				>
					<DataBox zIndex={2}>
						<Label mb={1}>
							<FormattedMessage id="nw_corner.subsidy" />
						</Label>
						
						<Text
							color={color}
							h={fontSize}
							fontSize={fontSize}
							fontWeight="normal"
							lineHeight="none"
							whiteSpace="nowrap"
							display="flex"
							align="center"
							gap="2px"
						>
							<span style={{ color: colorAlt }}><BitcoinSymbolEntity /></span>
							<b>{subsidy}</b>
						</Text>
					</DataBox>

					<Text
						pos="relative"
						color={colorAlt2}
						fontSize={fontSize}
						fontWeight="bold"
						alignSelf="stretch"
						zIndex={2}
						px={1}
					>
						+
					</Text>

					<DataBox zIndex={2}>
						<Label color={noColor} mb={1}>
							<>{txFeesPreLabel} <FormattedMessage id="data.last_block_fees.title" /></>
						</Label>

						{!blockHeightReady && (
							<Box h={fontSize} mt={responsiveSpinnerMt}>
								<Spinner size={responsiveSpinnerSize} color={spinnerColor} />
							</Box>
						)}

						{blockHeightReady && (
							<Text
								color={feeColor}
								h={fontSize}
								fontSize={fontSize}
								fontWeight="normal"
								lineHeight="none"
								whiteSpace="nowrap"
								display="flex"
								align="center"
								gap="2px"
							>
								<span style={{ color: colorAlt }}><BitcoinSymbolEntity /></span>
								<b>{blockFees}</b>
							</Text>
						)}
					</DataBox>

					<Text
						pos="relative"
						color={colorAlt2}
						fontSize={fontSize}
						fontWeight="bold"
						alignSelf="stretch"
						zIndex={2}
						px={1}
					>
						=
					</Text>

					<DataBox zIndex={2}>
						<Label mb={1}>
							Reward
						</Label>

						{!blockHeightReady && (
							<Box h={fontSize} mt={responsiveSpinnerMt}>
								<Spinner size={responsiveSpinnerSize} color={spinnerColor} />
							</Box>
						)}

						{blockHeightReady && (
							<Text
								color={color}
								h={fontSize}
								fontSize={fontSize}
								fontWeight="normal"
								lineHeight="none"
								whiteSpace="nowrap"
								display="flex"
								align="center"
								gap="2px"
							>
								<span style={{ color: colorAlt }}><BitcoinSymbolEntity /></span>
								<b>{lastBlockReward}</b>
							</Text>	
						)}
					</DataBox>
				</Flex>
			</Flex>
			
			{poolName
				&& showMiningPool
				&& (
					<Flex
						className="tc-mined-by"
						pos="absolute"
						zIndex={7}
						w="auto"
						h={responsiveMinedByH}
						top="100%"
						justify="center"
						align="center"
						gap={1}
						borderColor={borderColor}
						borderWidth={0}
						borderLeftWidth={1}
						borderRightWidth={1}
						borderBottomWidth={1}
						borderBottomRadius="lg"
						transform={minedByTransform}
						transition="all 0.21s ease"
						px={minedByPx}
						bg={bg}
						_after={{
							content: '""',
							w: '100%',
							h: '100%',
							pos: 'absolute',
							bg: solidBg,
							borderBottomRadius: 'lg',
							zIndex: 1,
							display: conditionalDisplay,
						}}
					>
						<Label
							pos="relative"
							zIndex={2}
							fontWeight="semibold"
							lineHeight="none"
							color={colorAlt}
						>
							Mined By
						</Label>
						
						<Label
							pos="relative"
							zIndex={2}
							color={green}
							fontWeight="bold"
							lineHeight="none"
							textTransform="uppercase"
						>
							&rarr; {conditionalPoolName}
						</Label>
					</Flex>
				)
			}
		</Flex>
	)
}
