import { useState, useEffect, useRef, useLayoutEffect } from 'react'
import type { FC, PropsWithChildren, SetStateAction, Dispatch } from 'react'
import { Box, Flex, type BoxProps } from '@chakra-ui/react'

type CircleWrapProps = PropsWithChildren & BoxProps

export const CircleWrap: FC<CircleWrapProps> = ({ children, ...rest }) => {
	return (
		<Box
			pos="absolute"
			top={0}
			left={0}
			right={0}
			bottom={0}
			zIndex={999}
			{...rest}
		>
			{children}
		</Box>
	)
}

interface CalWidthProps {
	confMode?: boolean
	setCircleWidth: Dispatch<SetStateAction<number>>
	calHeight: number
}
type CirclesSquareWrapProps = CalWidthProps & PropsWithChildren

export const CirclesSquareWrap: FC<CirclesSquareWrapProps> = ({
	confMode = false,
	setCircleWidth,
	calHeight,
	children,
}) => {
	const [refAcquired, setRefAcquired] = useState(false)
	const circleWrap = useRef<HTMLDivElement>(null)
	const maxCalHeight = calHeight > 920 ? 920 : calHeight

	useEffect(() => {
		setRefAcquired(true)
	}, [])

	useLayoutEffect(() => {
		const handleResize = () => {
			if (circleWrap.current) {
				setCircleWidth(circleWrap.current.offsetWidth)
			}
		}
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [setCircleWidth])

	useEffect(() => {
		if (circleWrap.current) {
			setCircleWidth(circleWrap.current.offsetWidth)
		}
	}, [refAcquired, setCircleWidth])

	return (
		<Box
			className="circle-wrap-outer"
			w={confMode ? '100%' : { base: '75%', sm: '90%', md: '100%' }}
			maxW={maxCalHeight}
		>
			<Flex
				className="circle-wrap-inner"
				ref={circleWrap}
				pos="relative"
				w="100%"
				pt="100%"
				direction="column"
				justify="center"
				transition="all 0.45s ease"
			>
				{children}
			</Flex>
		</Box>
	)
}
