import {
	useEffect,
	useLayoutEffect,
	useState,
	useRef,
} from 'react'
import type { PropsWithChildren } from 'react'
import { Flex, Box } from '@chakra-ui/react'
import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState,
} from 'recoil'

import {
	circlesWrapWidthState,
	circlesBreakpointState,
	landscapeOrientationState,
	confModeState,
} from '../../../state'
import {
	CIRCLE_SQUARE_MAX,
	MIN_LANDSCAPE_CIRCLE_HEIGHT,
	CIRCLE_BREAKS,
} from '../../../constants'
import {
	getKeyByValue,
	getBreakpointValue,
	type CircleBreakpointsStateKey,
} from '../../../utils'

export const CirclesSquareWrapV3 = ({ children }: PropsWithChildren) => {
	const [refAcquired, setRefAcquired] = useState(false)
	const [firstMeasure, setFirstMeasure] = useState(false)
	const [secondMeasure, setSecondMeasure] = useState(false)
	const [magicDimension, setMagicDimension] = useState(0)
	const [calculated, setCalculated] = useState(false)
	const [finalCalc, setFinalCalc] = useState(false)
	const [circlesWrapWidth, setCirclesWrapWidth] = useRecoilState(circlesWrapWidthState)
	const setCirclesBreakpoint = useSetRecoilState(circlesBreakpointState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const confMode = useRecoilValue(confModeState)
	const [landscapeOrientation, setLandscapeOrientation] = useRecoilState(landscapeOrientationState)

	const circlesWrapRef = useRef<HTMLDivElement>(null)

	const CONTROLS_HEIGHT = getBreakpointValue({ base: 30, sm: 40 }, circlesBreakpoint) as number
	const HEADER_FOOTER_HEIGHT = getBreakpointValue({ base: 30, sm: 40 }, circlesBreakpoint) as number
	const CONTENT_PADDING = getBreakpointValue({ base: 10, xs: 16, xl: 30, xxl: 50 }, circlesBreakpoint) as number
	const CONTENT_PADDING_PORTRAIT = getBreakpointValue({ base: 10, xs: 16, xl: 20 }, circlesBreakpoint) as number
	const SIDEBAR = landscapeOrientation
		? getBreakpointValue({ base: 400, sm: 500 }, circlesBreakpoint) as number
		: 500

	useEffect(() => {
		setRefAcquired(true)
	}, [])

	// CALCULATE APP LAYOUT MAGIC-DIMENSION
	useLayoutEffect(() => {
		const calculateMagicDimension = () => {
			const windowWidth = window.innerWidth
			const windowHeight = window.innerHeight
			const PADDING = landscapeOrientation
				? CONTENT_PADDING * 3
				: CONTENT_PADDING_PORTRAIT * 2
			const HEADER_AND_FOOTER = HEADER_FOOTER_HEIGHT * 2
			const HEADER_AND_FOOTER_AND_CONTROLS = HEADER_AND_FOOTER + CONTROLS_HEIGHT
			const FULL_SCREEN_MIN_HEIGHT = (CIRCLE_SQUARE_MAX + CONTROLS_HEIGHT + HEADER_AND_FOOTER)
			const FULL_SCREEN_MIN_WIDTH = (CIRCLE_SQUARE_MAX + PADDING + SIDEBAR)

			const CIRCLE_MAX_HEIGHT = landscapeOrientation
				? confMode
					? (windowHeight - HEADER_AND_FOOTER_AND_CONTROLS)
					: (windowHeight - HEADER_AND_FOOTER_AND_CONTROLS) < MIN_LANDSCAPE_CIRCLE_HEIGHT
						? MIN_LANDSCAPE_CIRCLE_HEIGHT
						: (windowHeight - HEADER_AND_FOOTER_AND_CONTROLS)
				: (windowHeight - HEADER_AND_FOOTER_AND_CONTROLS)

			const CIRCLE_MAX_WIDTH = (windowWidth - PADDING - SIDEBAR)
			const CIRCLE_MAX_WIDTH_PORTRAIT = (windowWidth - PADDING)

			const calculatedMagicDimension = landscapeOrientation
				? windowWidth > FULL_SCREEN_MIN_WIDTH && windowHeight > FULL_SCREEN_MIN_HEIGHT
					? CIRCLE_SQUARE_MAX
					: CIRCLE_MAX_WIDTH < CIRCLE_MAX_HEIGHT
						? CIRCLE_MAX_WIDTH < circlesWrapWidth
							? CIRCLE_MAX_WIDTH
							: circlesWrapWidth === 0
								? CIRCLE_MAX_HEIGHT
								: circlesWrapWidth
						: circlesWrapWidth === 0
							? CIRCLE_MAX_HEIGHT
							: CIRCLE_MAX_HEIGHT < circlesWrapWidth
								? CIRCLE_MAX_HEIGHT
								: circlesWrapWidth
				: CIRCLE_MAX_WIDTH_PORTRAIT < CIRCLE_SQUARE_MAX
					? CIRCLE_MAX_WIDTH_PORTRAIT
					: CIRCLE_SQUARE_MAX
			
			if (!calculated) {
				setMagicDimension(calculatedMagicDimension)
			}
			if (calculated && !finalCalc) {
				setMagicDimension(calculatedMagicDimension)
				setFinalCalc(true)
			}
		}

		calculateMagicDimension()
	}, [
		calculated,
		landscapeOrientation,
		circlesWrapWidth,
		SIDEBAR,
		CONTROLS_HEIGHT,
		HEADER_FOOTER_HEIGHT,
		CONTENT_PADDING,
		CONTENT_PADDING_PORTRAIT,
	])

	// WINDOW RESIZE TRIGGERS RECALCULATE APP LAYOUT BREAKPOINT
	useEffect(() => {
		const recalculate = () => {
			const windowWidth = window.innerWidth
			const windowHeight = window.innerHeight

			setLandscapeOrientation(windowWidth > windowHeight)
			setCalculated(false)
		}

		window.addEventListener('resize', recalculate)
		return () => window.removeEventListener('resize', recalculate)
	}, [])

	// INITIAL APP LAYOUT BREAKPOINT SET
	useLayoutEffect(() => {
		const calculateTCDimensions = () => {
			if (!firstMeasure && !calculated) {
				setFirstMeasure(true)
			}
			if (refAcquired && firstMeasure && !secondMeasure) {
				setSecondMeasure(true)
			}
			if (secondMeasure) {
				const breakpoint = getKeyByValue(CIRCLE_BREAKS, magicDimension) as CircleBreakpointsStateKey
				
				console.info('[ui]', breakpoint, magicDimension)
				setCalculated(true)
				setCirclesBreakpoint(breakpoint)
				setCirclesWrapWidth(magicDimension)
			}
		}

		calculateTCDimensions()
	}, [
		refAcquired,
		calculated,
		magicDimension,
		firstMeasure,
		secondMeasure,
	])

	return (
		<Flex
			flexShrink={1}
			className="tc-circle-restrictor"
			w="100%"
			h="100%"
			maxW={`${magicDimension}px`}
			maxH={`${magicDimension}px`}
		>
			<Box
				className="tc-circles-wrap"
				pos="relative"
				zIndex={100}
				w="100%"
				_before={{
					display: 'block',
					content: '""',
					pb: '100%',
				}}
			>
				<Flex
					ref={circlesWrapRef}
					className="tc-circles-wrap-inner"
					pos="absolute"
					align="center"
					justify="center"
					top={0}
					left={0}
					right={0}
					bottom={0}
				>
					{children}
				</Flex>
			</Box>
		</Flex>
	)
}
