import { Box, Button, useColorModeValue, type BoxProps } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { BITCOIN_ORANGE, menuLabelFontSize } from '../../../constants'
import { getBreakpointValue } from '../../../utils'

interface HalvingVideoButtonProps {
	onOpen: () => void
}

type Props = HalvingVideoButtonProps & BoxProps

export const HalvingVideoButton = ({ onOpen, ...rest }: Props) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const responsiveButtonLabelSize = getBreakpointValue(menuLabelFontSize, circlesBreakpoint)
	const buttonHeight = getBreakpointValue({ base: '30px', md: '38px' }, circlesBreakpoint)
	const buttonLineHeight = getBreakpointValue({ base: '26px', md: '36px' }, circlesBreakpoint)
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')

	const interactionStyle = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}
	return (
		<Box {...rest}>
			<Button
				className="tc-halving-video-button"
				role="group"
				onClick={onOpen}
				pos="relative"
				zIndex={1200}
				w="100%"
				size="sm"
				fontSize={responsiveButtonLabelSize}
				h={buttonHeight}
				lineHeight={buttonLineHeight}
				variant="outline"
				bg={bg}
				textTransform="uppercase"
				color={color}
				borderColor={color}
				borderWidth={2}
				borderRadius="md"
				_active={interactionStyle}
				_hover={interactionStyle}
			>
				HALVING REPLAY
			</Button>
		</Box>
	)
}
