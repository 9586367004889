import {
	Box,
	Button,
	useColorModeValue,
	chakra,
	shouldForwardProp,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { SearchIcon } from '@chakra-ui/icons'
import { useRecoilValue } from 'recoil'

import {
	circlesBreakpointState,
	editingBlockHeightState,
	scrubbingState,
	showBlockRippleState,
} from '../../../state'
import { BITCOIN_ORANGE, blurInAnimation } from '../../../constants'
import { getBreakpointValue } from '../../../utils'
import {
	buttonBreaks,
	buttonIconSize,
	buttonBottom,
} from './searchButton.constants'

interface SearchButtonProps {
	onClick: () => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const TCSearchButton = ({ onClick }: SearchButtonProps) => {
	const scrubbing = useRecoilValue(scrubbingState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const editingBlockHeight = useRecoilValue(editingBlockHeightState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const color = useColorModeValue('black', 'white')
	const bg = useColorModeValue('white', 'black')
	const bgGradient = useColorModeValue(
		'linear(to-r, white, white, rgba(0,0,0,0.05), white, white, white, white, white, white, white, white, white, white, white, white, white, white, white, white, white, white, white)',
		'linear(to-r, black, black, rgba(255,255,255,0.15), black, black, black, black, black, black, black, black, black, black, black, black, black, black, black, black, black, black, black)'
	)
	const interactionStyle = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}
	const responsiveButtonHeight = getBreakpointValue(buttonBreaks, circlesBreakpoint)
	const iconSize = getBreakpointValue(buttonIconSize, circlesBreakpoint)
	const responsiveBottom = getBreakpointValue(buttonBottom, circlesBreakpoint)
	const hidden = scrubbing || showBlockRipple
	const disabled = hidden || editingBlockHeight
	const opacity = hidden ? 0.1 : 1
	const filter = hidden ? 'blur(4px)' : 'blur(0)'

	return (
		<Button
			className="tc-search-button"
			role="group"
			onClick={onClick}
			pos="absolute"
			zIndex={1200}
			left={0}
			bottom={responsiveBottom}
			variant="outline"
			w={responsiveButtonHeight}
			h={responsiveButtonHeight}
			bg={bg}
			textTransform="uppercase"
			color={color}
			borderColor={color}
			borderWidth={2}
			borderRadius="50%"
			opacity={opacity}
			filter={filter}
			overflow="hidden"
			animation={blurInAnimation}
			disabled={disabled}
			transition="all 0.75s easeIn"
			_active={interactionStyle}
			_hover={interactionStyle}
		>
			{!hidden && (
				<Box
					pos="absolute"
					zIndex={1}
					w="100%"
					h="100%"
					borderRadius="50%"
					transform="rotate(25deg)"
				>
					<MotionBox
						pos="absolute"
						w="100%"
						h="100%"
						borderRadius="50%"
						bgGradient={bgGradient}
						backgroundSize="300%"
						style={{
							backgroundPositionX: '100%',
						}}
						animate={{
							backgroundPositionX: ['100%', '-100%'],
						}}
						// @ts-ignore
						transition={{
							duration: 4,
							ease: 'linear',
							repeat: Infinity,
							repeatType: 'loop',
						}}
						_groupHover={{
							display: 'none',
						}}
					/>
				</Box>		
			)}

			<SearchIcon
				pos="relative"
				zIndex={2}
				boxSize={iconSize}
				transition="all 0.75s easeIn"
				_groupHover={{
					transform: 'scale(1.15)'
				}}
			/>
		</Button>
	)
}
