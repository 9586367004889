import {
	useState,
	useEffect,
} from 'react'
import {
	Flex,
	chakra,
	shouldForwardProp,
	useColorMode,
	useColorModeValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { TextPathCircle } from '../../layout/circles/TextPathCircle'
import { getBreakpointValue } from '../../../utils'

type Connection = {
	readyState: number
}

interface TCStatusProps {
	connection: Connection
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

const defaultStatusColors = {
	bg: 'black',
	shadow: 'none',
	alt: 'black',
}

export const TCStatus = ({ connection }: TCStatusProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const intl = useIntl()
	const { colorMode } = useColorMode()
	const [status, setStatus] = useState(4)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [statusColors, setStatusColors] = useState<any>(defaultStatusColors)
	const [bgAnimation, setBgAnimation] = useState<string[]>([])
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [bgTransition, setBgTransition] = useState<any>(undefined)
	const { readyState } = connection
	const isConnected = readyState === 0 || readyState === 1

	const borderColor = useColorModeValue('white', 'black')
	const responsiveLedSize = getBreakpointValue({ base: 2, xxs: 3, md: '14px', lg: '14px', xl: 4 }, circlesBreakpoint)
	const responsiveLedRotatingTextLeft = getBreakpointValue({ base: '-20px', xxs: '-19px', md: '-17.5px', lg: '-17.75px', xl: '-16.5px' }, circlesBreakpoint)

	useEffect(() => {
		const getBoxShadow = (readyState: number) => {
			let backgroundColor = '#000000',
				lighterColor,
				darkerColor,
				altColor = '#000000'
			const shadowColor = colorMode === 'light' ? 'white' : 'black'
			switch (readyState) {
				case 0: // CONNECTING
					backgroundColor = '#F6E05E'
					lighterColor = '#f9e77f'
					darkerColor = '#bca51c'
					altColor = '#FEFCBF'
					break
				case 1: // OPEN
					backgroundColor = '#4ebd49'
					lighterColor = '#10e905'
					darkerColor = '#0d7c07'
					altColor = '#a3ec9f'
					break
				case 2: // CLOSING
					backgroundColor = '#e48d24'
					lighterColor = '#F6AD55'
					darkerColor = '#aa6615'
					altColor = '#ecba7f'
					break
				case 3: // CLOSED
					backgroundColor = '#ff0000'
					lighterColor = '#d03c3c'
					darkerColor = '#ac0202'
					altColor = '#ff0000'
			}

			return {
				bg: backgroundColor,
				shadow: `${shadowColor} 0 -1px 7px 1px, inset ${darkerColor} 0 -1px 9px, ${lighterColor} 0 2px 12px`,
				alt: altColor,
			}
		}

		const newColors = getBoxShadow(readyState)
		const newAnimation = readyState === 3
			? [newColors.bg, newColors.alt, '#000000']
			: [newColors.bg, newColors.alt, newColors.bg]
		const newDuration = readyState === 3 ? 0.5 : 3
		const newTransition = {
			duration: newDuration,
			ease: 'linear',
			repeat: Infinity,
			repeatType: 'loop',
		}

		setStatus(readyState)
		setStatusColors(newColors)
		setBgAnimation(newAnimation)
		setBgTransition(newTransition)
	}, [
		colorMode,
		readyState,
		status,
	])

	return (
		<Flex
			onClick={() => window.location.reload()}
			direction="column"
			justify="center"
			h={{ base: 4, xxs: 8 }}
			cursor="pointer"
			pos="relative"
			zIndex={2999}
		>
			{!isConnected && (
				<MotionBox
					pos="absolute"
					top="auto"
					left={responsiveLedRotatingTextLeft}
					right="auto"
					bottom="auto"
					m="auto"
					w="50px"
					h="50px"
					color="red100"
					animate={{ transform: 'rotate(-210deg)' }}
					// @ts-ignore
					transition={{
						duration: 6,
						ease: 'easeInOut',
						repeat: Infinity,
						repeatType: 'loop',
					}}
				>
					<TextPathCircle
						containerWidth={50}
						circleWidth={23}
						id="reload"
						text={intl.formatMessage({ id: 'app.status.press_to_reload' })}
						overrideTransform="rotate(210deg)"
						fontSize="7px"
					/>
				</MotionBox>
			)}

			<MotionBox
				zIndex={1}
				w={responsiveLedSize}
				h={responsiveLedSize}
				bg={statusColors.bg}
				borderWidth={1}
				borderColor={borderColor}
				borderRadius="50%"
				boxShadow={statusColors.shadow}
				animate={{
					backgroundColor: bgAnimation
				}}
				// @ts-ignore
				transition={bgTransition}
			/>
		</Flex>
	)
}
