import {
	useRef,
	useMemo,
} from 'react'
import {
	Flex,
	Box,
	Text,
	Image,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	CloseButton,
	Button,
	Link,
	useDisclosure,
	ResponsiveValue,
	chakra,
	shouldForwardProp,
	useColorModeValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../hooks'
import {
	scrubBlockState,
	circlesBreakpointState,
	landscapeOrientationState,
} from '../../../state'
import { BLOCK_EMBEDS, BITCOIN_ORANGE } from '../../../constants'
import { rtlLocales } from '../../../lang/messages'
import { FormattedMessage } from 'react-intl'
import { getBreakpointValue } from '../../../utils'
import { VideoPlayer } from '../video'
import { ModalOverlay } from '../../shared'

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const BlockEmbeds = () => {
	const { userLocale } = useAppSelector((state) => state.settings)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const scrubBlock = useRecoilValue(scrubBlockState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const embedRef = useRef(null)
	
	const boxShadow = useColorModeValue('0 0 40px 12px rgba(0,0,0,0.15)', '0 0 40px 12px rgba(255,255,255,0.15)')
	const boxShadow1 = useColorModeValue('0 0 36px 10px rgba(0,0,0,0.2)', '0 0 36px 10px rgba(255,255,255,0.13)')
	const boxShadow2 = useColorModeValue('0 0 40px 15px rgba(0,0,0,0.6)', '0 0 40px 15px rgba(255,255,255,0.37)')
	const boxShadow3 = useColorModeValue('0 0 36px 1px rgba(0,0,0,0.21)', '0 0 36px 1px rgba(255,255,255,0.13)')
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const EMBEDS = useMemo(() => BLOCK_EMBEDS, [])

	const embedDetailsDirection = landscapeOrientation
		? getBreakpointValue({ base: 'column', md: 'row' }, circlesBreakpoint) as ResponsiveValue<'column' | 'row'>
		: 'column'
	const embedDetailsAlign = landscapeOrientation
		? getBreakpointValue({ base: 'flex-start', md: 'center' }, circlesBreakpoint) as ResponsiveValue<'flex-start' | 'center'>
		: 'flex-start'
	const embedDescriptionTextAlign = getBreakpointValue({ base: 'center', md: 'left' }, circlesBreakpoint) as ResponsiveValue<'left' | 'center'>
	const embedDescriptionTextMb = getBreakpointValue({ base: 4, md: 0 }, circlesBreakpoint)
	const embedDetailsGap = landscapeOrientation
		? getBreakpointValue({ base: 2, md: 6 }, circlesBreakpoint)
		: getBreakpointValue({ base: 2, xl: 6 }, circlesBreakpoint)

	const descriptionMaxH = landscapeOrientation ? '100px' : '180px'
	const buttonsWidth = landscapeOrientation ? 'auto' : '100%'
	const buttonsJustify = landscapeOrientation ? undefined : 'flex-end'
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined

	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
		textDecoration: 'none',
	}

	// FIND EMBED ON THIS BLOCK
	const blockEmbed = EMBEDS.find((embed) => embed.block === scrubBlock.height)

	// IF FOUND PARSE VALUES
	const imageUrl = blockEmbed ? blockEmbed.imageUrl : ''
	const thumbUrl = blockEmbed ? blockEmbed.thumbUrl : ''
	const imageWidth = blockEmbed ? blockEmbed.imageWidth : 0
	const imageHeight = blockEmbed ? blockEmbed.imageHeight : 0
	const attribution = blockEmbed ? blockEmbed.attribution : ''
	const title = blockEmbed && blockEmbed.title ? blockEmbed.title : attribution
	const link = blockEmbed ? blockEmbed.link : ''
	const description = blockEmbed ? blockEmbed.description : ''
	const videoSrc = blockEmbed && blockEmbed.videoSrc ? blockEmbed.videoSrc : ''
	const buttonLabel = blockEmbed && blockEmbed.buttonLabel ? blockEmbed.buttonLabel : ''
	// const cats = blockEmbed && blockEmbed.cats ? blockEmbed.cats : []

	// DIMENSIONS FOR UI
	const thumbBoxSize = 150
	const thumbBoxSizePx = `${thumbBoxSize}px`
	// image orientation & ratios
	const imageLandscape = imageWidth > imageHeight
	const imageAspectRatio = imageWidth / imageHeight
	const imageAspectRatioInverse = imageHeight / imageWidth
	// thumb sizes
	const thumbBoxSmallerDimensionSize = imageLandscape
		? thumbBoxSize * imageAspectRatioInverse
		: thumbBoxSize * imageAspectRatio
	const thumbWidth = imageLandscape ? thumbBoxSize : thumbBoxSmallerDimensionSize
	const thumbHeight = imageLandscape ? thumbBoxSmallerDimensionSize : thumbBoxSize

	if (!blockEmbed) {
		return null
	}

	return (
		<Flex
			className="tc-block-embed-wrap"
			w={thumbBoxSizePx}
			h={thumbBoxSizePx}
			justify="center"
			align="center"
			transition="all 0.45s ease"
		>
			<MotionBox
				ref={embedRef}
				role="group"
				onClick={onOpen}
				w={`${thumbWidth}px`}
				h={`${thumbHeight}px`}
				overflow="hidden"
				cursor="pointer"
				borderRadius="md"
				boxShadow={boxShadow}
				animate={{
					boxShadow: [
						boxShadow1,
						boxShadow2,
						boxShadow3,
					],
				}}
				// @ts-ignore
				transition={{
					duration: 2.1,
					ease: 'linear',
					repeat: Infinity,
					repeatType: 'loop',
				}}
			>
				{videoSrc && (
					<Box
						pos="absolute"
						top={0}
						left={0}
						right={0}
						bottom={0}
						m="auto"
						w="60px"
						h="60px"
						borderWidth={2}
						borderColor="white"
						borderRadius="50%"
						bg="rgba(0,0,0,0.5)"
						transition="all 0.45s ease"
						_groupHover={{
							borderColor: BITCOIN_ORANGE,
						}}
					>
						<Box
							pos="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							m="auto"
							w={0}
							h={0}
							borderTop="10px solid transparent"
							borderBottom="10px solid transparent"
							borderLeft="18px solid white"
							transition="all 0.45s ease"
							_groupHover={{
								borderLeftColor: BITCOIN_ORANGE,
							}}
						/>
					</Box>
				)}
				<Image
					src={thumbUrl}
					alt={title}
					objectFit="cover"
				/>
			</MotionBox>

			<Modal
				key="gallery"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="full"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
					dir={direction}
				>
					<ModalHeader>
						<Flex
							align="center"
							justify="space-between"
							gap={2}
							color={colorAlt}
						>
							<Text
								color={color}
								textTransform="uppercase"
								fontSize="sm"
							>
								{attribution}
							</Text>

							<CloseButton
								onClick={onClose}
								_hover={interactionStyles}
								_active={interactionStyles}
							/>
						</Flex>
					</ModalHeader>

					<ModalBody pos="relative">
						<Flex
							pos="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							direction="column"
							justify="center"
							align="center"
						>
							{videoSrc && (
								<VideoPlayer
									videoSrc={videoSrc}
									title={title}
								/>
							)}

							{!videoSrc && (
								<Image
									src={imageUrl}
									alt={title}
									objectFit="contain"
									htmlWidth={imageWidth}
									htmlHeight={imageHeight}
									maxW="100%"
									maxH="100%"
								/>
							)}
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Flex
							className="embed-modal-footer"
							w="100%"
							direction={embedDetailsDirection}
							justify="space-between"
							align={embedDetailsAlign}
							gap={embedDetailsGap}
						>
							<Flex
								direction="column"
								justify="flex-start"
								gap={1}
								maxW="320px"
							>
								<Text
									color={color}
									fontWeight="bold"
									fontSize="md"
									overflowWrap="break-word"
								>
									{`"${title}"`}
								</Text>
							</Flex>

							<Text
								flex={1}
								textAlign={embedDescriptionTextAlign}
								fontSize="sm"
								mb={embedDescriptionTextMb}
								maxH={descriptionMaxH}
								overflowY="scroll"
							>
								{description}
							</Text>

							<Flex
								w={buttonsWidth}
								justify={buttonsJustify}
								gap={4}
							>
								<Button
									onClick={onClose}
									variant="outline"
									colorScheme="black"
									fontSize="sm"
									borderWidth={2}
									textTransform="uppercase"
									_active={interactionStyles}
									_hover={interactionStyles}
								>
									<FormattedMessage id="shared.close_button_label" />
								</Button>

								<Button
									as={Link}
									href={link}
									variant="outline"
									colorScheme="black"
									fontSize="sm"
									borderWidth={2}
									textTransform="uppercase"
									_active={interactionStyles}
									_hover={interactionStyles}
									isExternal
								>
									{buttonLabel || (<FormattedMessage id="alerts.bitcoin_dates.learn_more" />)}
								</Button>
							</Flex>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Flex>
	)
}
