import type { PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import {
	Flex,
	Text,
	useColorMode,
	useColorModeValue,
	type FlexProps,
} from '@chakra-ui/react'

import { getBreakpointValue } from '../../../utils'
import { circlesBreakpointState, landscapeOrientationState } from '../../../state'
import { STYLE_TRANSITION_ALL } from '../../../constants'

interface SidebarSectionTitleProps {
	title: string
}
interface SidebarSectionProps {
	title: string
	justify?: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	footer?: any
}
type SidebarSectionCombinedProps = SidebarSectionProps & PropsWithChildren & FlexProps

interface DataBoxProps {
	wide?: boolean
}

type DataBoxCombinedProps = DataBoxProps & FlexProps &PropsWithChildren
type DataWrapProps = PropsWithChildren & FlexProps

export const DataWrap = ({ children, ...rest }: DataWrapProps) => {
	const { colorMode } = useColorMode()
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const demandBorderColor = useColorModeValue('rgba(0,0,0,0.1)', 'rgba(255,255,255,0.1)')
	const demandBg = useColorModeValue('rgba(255,255,255.01)', 'rgba(255,255,255,0.075)')
	const solidBg = useColorModeValue('white', 'black')
	const bgOverlayDisplay = getBreakpointValue({ base: 'block', lg: 'none' }, circlesBreakpoint)

	return (
		<Flex
			pos="relative"
			direction="column"
			flex="0 1 50%"
			bg={demandBg}
			p={4}
			py={3}
			borderWidth={1}
			borderColor={demandBorderColor}
			borderRadius="lg"
			_after={{
				content: '""',
				w: '100%',
				h: '100%',
				pos: 'absolute',
				top: 0,
				left: 0,
				bg: solidBg,
				borderRadius: 'lg',
				zIndex: 1,
				display: colorMode === 'light' ? bgOverlayDisplay : 'none',
			}}
			{...rest}
		>
			{children}
		</Flex>
	)
}

export const DataBox = ({ wide = false, children, ...rest }: DataBoxCombinedProps) => {
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const landscapeWidth = getBreakpointValue({ base: '33.33%', xs: '50%', sm: wide ? '50%' : '33.33%' }, circlesBreakpoint)
	const responsiveWidth = landscapeOrientation
		? landscapeWidth
		: '50%'

	return (
		<Flex
			pos="relative"
			flex={`1 1 ${responsiveWidth}`}
			direction="column"
			align="center"
			justify="center"
			{...rest}
		>
			{children}
		</Flex>
	)
}

export const SidebarSectionTitle = ({ title }: SidebarSectionTitleProps) => {
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.3)')
	const textColor = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const responsiveSectionLabelSize = getBreakpointValue({ base: '9px', xs: '10px', lg: '11px', xl: '12px', xxxl: '13px' }, circlesBreakpoint)
	const height = 4
	const borderTopStyles = {
		content: '""',
		position: 'absolute',
		width: '100%',
		top: '8px',
		borderTopWidth: 1,
		borderColor: color,
		zIndex: 0,
	}
	
	return (
		<Flex
			pos="relative"
			w="100%"
			h={height}
			flexShrink={0}
			top={0}
			mb={3}
			_after={borderTopStyles}
		>
			<Flex
				pos="relative"
				h={height}
				top={0}
				left={0}
				right={0}
				m="auto"
				px={5}
				justify="center"
				align="center"
				bg={bg}
				borderRadius="30px"
				borderWidth={1}
				borderColor={color}
				zIndex={1}
			>
				<Text
					display="flex"
					align="center"
					fontSize={responsiveSectionLabelSize}
					lineHeight="14px"
					fontWeight="bold"
					color={textColor}
					textTransform="uppercase"
					transition={STYLE_TRANSITION_ALL}
				>
					{title}
				</Text>
			</Flex>
		</Flex>
	)
}

export const SidebarSection = ({
	title,
	justify = 'space-between',
	footer,
	children,
	...rest
}: SidebarSectionCombinedProps) => {
	return (
		<Flex
			direction="column"
			pos="relative"
			w="100%"
			mb={8}
			{...rest}
		>
			<SidebarSectionTitle title={title} />

			<Flex
				justify={justify}
				align="flex-start"
			>
				{children}
			</Flex>

			{footer && (
				<Flex justify="center" mt={3}>
					{footer}
				</Flex>
			)}
		</Flex>
	)
}
