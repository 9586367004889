import {
	Flex,
	useColorModeValue,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { useIntl } from 'react-intl'

import { useAppSelector } from '../../../hooks'
import {
	staticModeState,
	showBlockRippleState,
	editingBlockHeightState,
	circlesBreakpointState,
	circlesWrapWidthState,
	landscapeOrientationState,
	confModeState,
	blockState,
	scrubbingState,
	supplyIntroCompleteState,
} from '../../../state'
import {
	DataNextHalving,
	DataNetworkHashrate,
	DataCurrentDifficulty,
	DataBlockTime,
	DataLast24Hrs,
	DataNextDiffAdj,
	DataReachableNodes,
	DataChainstate,
	PrevDiffAdjust,
	DataChainstateFooter,
	DataBlockTimeLifetime,
	DataLastBlockReward,
} from '../../features/calendar/data-components'
import {
	// TCCalculator,
	// TCSupporters,
	// TCAlarm,
	TCGallery,
	TCShop,
	HalvingVideoButton,
	BlockEmbedTarget,
} from '../../features'
import { getBreakpointValue, getBlocksToHalving } from '../../../utils'
import {
	MIN_LANDSCAPE_CIRCLE_HEIGHT,
	blurInAnimation,
	blurInFastAnimation,
} from '../../../constants'
import {
	DataBox,
	DataWrap,
	SidebarSection,
} from './SidebarSection'
import { FeesSection } from '../../features/calendar/fees/FeesSection'
import { MempoolSection } from '../../features/calendar/mempool/MempoolSection'
import { BlockTimer } from '../circles'

interface TCSidebarProps {
	// eslint-disable-next-line no-unused-vars
	onSearchFutureBlock: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	onSearchBlock: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	onScrubChangeEnd: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	setScrubValue: (x: number) => void
	onOpenBlockDetailsModal: () => void
	onOpenHalvingVideo: () => void
	calAlertOpen: boolean
}

export const TCSidebarV5 = ({
	onSearchBlock,
	onScrubChangeEnd,
	setScrubValue,
	onSearchFutureBlock,
	onOpenHalvingVideo,
	calAlertOpen,
}: TCSidebarProps) => {
	const intl = useIntl()
	const { showFees } = useAppSelector(({ settings }) => settings)
	const scrubbing = useRecoilValue(scrubbingState)
	const staticMode = useRecoilValue(staticModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const editingBlockHeight = useRecoilValue(editingBlockHeightState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const circlesWrapWidth = useRecoilValue(circlesWrapWidthState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const confMode = useRecoilValue(confModeState)
	const block = useRecoilValue(blockState)
	const blocksToHalving = getBlocksToHalving(block.height)
	const supplyIntroComplete = useRecoilValue(supplyIntroCompleteState)

	const shouldBlur = staticMode || showBlockRipple || editingBlockHeight || calAlertOpen
	const opacity = shouldBlur ? 0 : 1
	const filter = shouldBlur ? 'blur(4px)' : 'blur(0)'
	const buttonsOpacity = showBlockRipple ? 0.21 : 1
	const buttonsFilter = showBlockRipple ? 'blur(4px)' : 'blur(0)'
	const colorAlt = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.3)')

	const headerHeight = getBreakpointValue({ base: 30, sm: 40 }, circlesBreakpoint) as number
	const HEADER_AND_FOOTER = headerHeight * 2
	const MIN_LANSCAPE_CONTENT_HEIGHT = MIN_LANDSCAPE_CIRCLE_HEIGHT + HEADER_AND_FOOTER
	const sidebarH = landscapeOrientation
		? (window.innerHeight - HEADER_AND_FOOTER) < MIN_LANSCAPE_CONTENT_HEIGHT
			? MIN_LANSCAPE_CONTENT_HEIGHT
			: (window.innerHeight - HEADER_AND_FOOTER)
		: 'auto'
	const maxW = landscapeOrientation
		? getBreakpointValue({ base: '400px', sm: '500px' }, circlesBreakpoint)
		: getBreakpointValue({ base: '100%', lg: '680px' }, circlesBreakpoint)
	const dataGap = getBreakpointValue({ base: 0, lg: 8, xxl: 10 }, circlesBreakpoint)
	const buttonsWrap = landscapeOrientation
		? getBreakpointValue({ base: 'nowrap' }, circlesBreakpoint) as ResponsiveValue<'wrap' | 'nowrap'>
		: getBreakpointValue({ base: 'wrap', sm: 'nowrap' }, circlesBreakpoint) as ResponsiveValue<'wrap' | 'nowrap'>
	const buttonsMt = landscapeOrientation
		? getBreakpointValue({ base: '2', lg: '0' }, circlesBreakpoint)
		: getBreakpointValue({ base: '2', lg: 4 }, circlesBreakpoint)
	const buttonFlex = getBreakpointValue({ base: '0 1 48%', xs: '1 1 25%' }, circlesBreakpoint)
	const mobileBlockDetailsDisplay = getBreakpointValue({ base: 'flex', sm: 'none' }, circlesBreakpoint) as ResponsiveValue<'flex' | 'none'>
	const responsiveBlockDetailPt = getBreakpointValue({ base: '6px', sm: 0 }, circlesBreakpoint)
	const responsiveBlockDetailPl = getBreakpointValue({ base: '2px', sm: 0 }, circlesBreakpoint)
	const feesMempoolMb = getBreakpointValue({ base: 6, lg: 6, xxl: 8, xxxl: 10, jumbo: 12 }, circlesBreakpoint)
	const sidebarSection1Mb = getBreakpointValue({ base: 4,  xl: 6, xxl: 7, jumbo: 8 }, circlesBreakpoint)
	const sidebarSection2Mb = getBreakpointValue({ base: 6, lg: 6, xxl: 8, xxxl: 10, jumbo: 12 }, circlesBreakpoint)
	const sidebarSection3Mb = getBreakpointValue({ base: 6, lg: 4 }, circlesBreakpoint)
	const sidebarSection3Gap = getBreakpointValue({ base: 4, lg: 6, xxl: 10 }, circlesBreakpoint)
	const responsiveMx = landscapeOrientation ? undefined : 'auto'
	const responsivePt = landscapeOrientation ? 0 : 2
	const showGallery = !confMode && process.env.REACT_APP_SELF_HOSTED !== 'true'

	if (circlesWrapWidth === 0) return null

	return (
		<Flex
			className="tc-sidebar"
			direction="column"
			justify="space-between"
			alignSelf="flex-start"
			pos="relative"
			zIndex={111}
			w="100%"
			maxW={maxW}
			h={sidebarH}
			pt={responsivePt}
			mx={responsiveMx}
		>
			<Flex
				w="100%"
				flexGrow={0}
				flexShrink={1}
				direction="column"
				justify="space-between"
				overflowY="auto"
				overflowX="hidden"
				animation={blurInAnimation}
			>
				<Flex
					className="mobile-block-details"
					display={mobileBlockDetailsDisplay}
					justify="space-between"
					align="center"
					w="100%"
					gap={2}
					pt={responsiveBlockDetailPt}
					pl={responsiveBlockDetailPl}
					mb={4}
				>
					<BlockTimer />

					{supplyIntroComplete && !editingBlockHeight && (
						<Flex
							w="100%"
							justify="center"
							mb={3}
							animation={blurInFastAnimation}
						>
							<DataLastBlockReward />
						</Flex>
					)}
				</Flex>

				{!scrubbing && (
					<BlockEmbedTarget />
				)}

				<Flex
					w="100%"
					direction="column"
					align="center"
					justify="center"
					gap={dataGap}
					opacity={opacity}
					filter={filter}
					transition="all 0.45s ease"
				>
					<Flex
						className="tc-sidebar-data"
						direction="column"
						w="100%"
					>
						{showFees && (
							<DataWrap
								flex="0 1 100%"
								w="100%"
								mb={feesMempoolMb}
							>
								<FeesSection />

								<MempoolSection />
							</DataWrap>
						)}
						
						<SidebarSection
							title="Block Production"
							mb={sidebarSection1Mb}
						>
							<DataBox>
								<DataBlockTime />
							</DataBox>

							<DataBox>
								<DataNetworkHashrate />	
							</DataBox>
						
							<DataBox>
								<DataLast24Hrs />
							</DataBox>
						</SidebarSection>

						<SidebarSection
							title={intl.formatMessage({ id: 'data.difficulty.title' })}
							mb={sidebarSection1Mb}
						>
							<DataBox>
								<PrevDiffAdjust />
							</DataBox>

							<DataBox>
								<Flex
									align="center"
									pos="absolute"
									top={0}
									left="-10px"
									bottom={0}
									m="auto"
									h="16px"
									fontSize="2xl"
									fontWeight="bold"
									color={colorAlt}
								>
									<span style={{ marginTop: '-8px' }}>&rarr;</span>
								</Flex>

								<DataCurrentDifficulty />
							</DataBox>
							
							<DataBox>
								<DataNextDiffAdj />
							</DataBox>
						</SidebarSection>

						<SidebarSection
							title="CHAINSTATE"
							footer={<DataChainstateFooter />}
							mb={sidebarSection2Mb}
						>
							<DataChainstate />
						</SidebarSection>

						<Flex
							w="100%"
							justify="space-between"
							mb={sidebarSection3Mb}
							gap={sidebarSection3Gap}
						>
							<DataWrap>
								<SidebarSection
									title="Network"
									mb={0}
									zIndex={2}
								>
									<DataBox>
										<DataReachableNodes />
									</DataBox>
								</SidebarSection>
							</DataWrap>

							<DataWrap>
								<SidebarSection
									title={intl.formatMessage({ id: 'app.title.timechain' })}
									mb={0}
									zIndex={2}
								>
									<DataBox>
										<DataBlockTimeLifetime />
									</DataBox>
								</SidebarSection>
							</DataWrap>
						</Flex>

						{blocksToHalving < 105001 && (
							<DataBox wide>
								<DataNextHalving onSearchFutureBlock={onSearchFutureBlock} />
							</DataBox>
						)}
					</Flex>
				</Flex>

				{/* <BlockEmbedTarget
					positionDirection="top"
					display={blockEmbedTargetDisplay}
				/> */}
			</Flex>

			<Flex
				pos="relative"
				w="100%"
				direction="column"
				gap={2}
				mt={buttonsMt}
				opacity={buttonsOpacity}
				filter={buttonsFilter}
			>	
				<Flex
					w="100%"
					direction="column"
					gap={2}
				>
					<Flex
						justify="center"
						wrap={buttonsWrap}
						gap={2}
					>
						<HalvingVideoButton onOpen={onOpenHalvingVideo} flex={buttonFlex} />

						{showGallery && (
							<TCGallery
								onSearchBlock={onSearchBlock}
								onScrubChangeEnd={onScrubChangeEnd}
								setScrubValue={setScrubValue}
								flex={buttonFlex}
							/>
						)}

						{/* <TCAlarm flex={buttonFlex} /> */}

						{/* <TCCalculator flex="1 1 25%" /> */}
						
						{showGallery && (
							<TCShop flex={buttonFlex} />
						)}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	)
}
