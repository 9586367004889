export const textCircleTransformConfig = {
	base: 'rotate(-90deg) translateX(2.1%) translateY(1.1%)',
	xxs: 'rotate(-90deg) translateX(1.9%) translateY(1.1%)',
	xs: 'rotate(-90deg) translateX(1.4%) translateY(0.9%)',
	sm: 'rotate(-90deg) translateX(1.2%) translateY(0.6%)',
	md: 'rotate(-90deg) translateX(1%) translateY(0.7%)',
	lg: 'rotate(-90deg) translateX(1.7%) translateY(0.85%)',
	xl: 'rotate(-90deg) translateX(1.5%) translateY(0.75%)',
	xxl: 'rotate(-90deg) translateX(1.3%) translateY(0.75%)',
	xxxl: 'rotate(-90deg) translateX(1.25%) translateY(0.65%)',
	jumbo: 'rotate(-90deg) translateX(1.35%) translateY(0.65%)',
}

export const textCircle2TransformConfig = {
	base: 'rotate(-90deg) translateX(2.1%) translateY(1.1%)',
	xxs: 'rotate(-109deg) translateX(0.8%) translateY(0.3%)',
	xs: 'rotate(-106.5deg) translateX(0.7%) translateY(0.3%)',
	sm: 'rotate(-102.75deg) translateX(0.6%) translateY(0.2%)',
	md: 'rotate(-102.25deg) translateX(0.5%) translateY(0.15%)',
	lg: 'rotate(-102deg) translateX(0.5%) translateY(0.15%)',
	xl: 'rotate(-101deg) translateX(0.4%) translateY(0.15%)',
	xxl: 'rotate(-100.75deg) translateX(0.5%) translateY(0.15%)',
	xxxl: 'rotate(-100.4deg) translateX(0.5%) translateY(0.15%)',
	jumbo: 'rotate(-99.5deg) translateX(0.4%) translateY(0.15%)',
}
export const textCircle2TransformConfigPortrait = {
	base: 'rotate(-111deg) translateX(0.7%) translateY(0.4%)',
	xxs: 'rotate(-107deg) translateX(0.6%) translateY(0.3%)',
	xs: 'rotate(-104.5deg) translateX(0.6%) translateY(0.3%)',
	sm: 'rotate(-103.5deg) translateX(0.5%) translateY(0.2%)',
	md: 'rotate(-102.25deg) translateX(0.5%) translateY(0.15%)',
	lg: 'rotate(-102deg) translateX(0.5%) translateY(0.15%)',
	xl: 'rotate(-101deg) translateX(0.4%) translateY(0.15%)',
	xxl: 'rotate(-100.75deg) translateX(0.5%) translateY(0.15%)',
	xxxl: 'rotate(-100.4deg) translateX(0.5%) translateY(0.15%)',
	jumbo: 'rotate(-99.5deg) translateX(0.4%) translateY(0.15%)',
}

export const blocksToHalvingShadowOffset = {
	base: '13%',
	xs: '11.5%',
	sm: '10.1%',
	md: '9.5%',
	lg: '8%',
}

export const blocksToHalvingOffset = {
	base: '11.5%',
	xs: '9%',
	sm: '5.5%',
	md: '5%',
}

export const blocksToDiffOffset = {
	base: '17.5%',
	xs: '14.5%',
	sm: '10.5%',
	md: '10%',
	xxl: '9.75%',
}

export const pastBlocksOffset = {
	base: '24%',
	xxs: '23.75%',
	xs: '20.5%',
	sm: '15.75%',
	md: '15.35%',
	lg: '15.75%',
	xl: '15.5%',
	xxl: '15%',
}

export const percentageCircleOffset = {
	base: '7.75%',
	xs: '6%',
	sm: '2.75%',
	md: '2.2%',
	xl: '2.4%',
}

export const borderWidthBreakpoints = {
	base: '4px',
	lg: '8px',
	jumbo: '12px',
}
