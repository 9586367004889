import {
	useEffect,
	useState,
} from 'react'
import {
	Flex,
	Text,
	Spinner,
	useColorModeValue,
} from '@chakra-ui/react'
import format from 'date-fns/format'
import { useRecoilValue } from 'recoil'

import {
	scrubbingState,
	staticModeState,
	circlesBreakpointState,
	scrubBlockState,
	showBlockRippleState,
} from '../../../state'
import { dateLocales } from '../../../lang/dateFnsLocales'
import { useAppSelector } from '../../../hooks'
import { getDateFormat, getBreakpointValue } from '../../../utils'
import { BITCOIN_ORANGE, blurInAnimation } from '../../../constants'
import { rtlLocales } from '../../../lang/messages'

export interface CornerSEProps {
	onOpenDatePickerModal: () => void
}
interface DateOutputProps {
	day: string
	formattedDate: string
}

const DateOutput = ({ day, formattedDate }: DateOutputProps) => {
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const color2 = useColorModeValue('rgba(0,0,0,0.7)', 'whiteAlpha.700')
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const dayFontSize = getBreakpointValue({
		base: '11px',
		xs: '12px',
		sm: '13px',
		md: '15px',
		lg: 'md',
		xl: '19px',
		xxl: '22px',
		xxxl: '24px',
		jumbo: '28px',
	}, circlesBreakpoint)
	const dateFontSize = getBreakpointValue({
		base: '16px',
		xs: '20px',
		sm: '24px',
		md: '26px',
		lg: '30px',
		xl: '32px',
		xxl: '36px',
		xxxl: '40px',
		jumbo: '46px',
	}, circlesBreakpoint)
	const responsiveGap = getBreakpointValue({ base: '2px', sm: '3px' }, circlesBreakpoint)

	return (
		<Flex
			direction="column"
			align="flex-end"
			lineHeight="none"
			textTransform="uppercase"
			gap={responsiveGap}
		>
			<Text
				fontWeight="semibold"
				fontSize={dayFontSize}
				color={color2}
				transition="all 0.21s ease"
				_groupHover={{
					color: BITCOIN_ORANGE,
				}}
			>
				{day}
			</Text>

			<Text
				fontWeight="bold"
				fontSize={dateFontSize}
				dir={direction}
			>
				{formattedDate}
			</Text>
		</Flex>
	)
}

export const CornerSE = ({
	onOpenDatePickerModal,
}: CornerSEProps) => {
	const { dateFormat, userLocale } = useAppSelector(({ settings }) => settings)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const scrubbing = useRecoilValue(scrubbingState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const [theTime, setTheTime] = useState(new Date())

	const scrubDate = new Date(scrubBlock.timestamp * 1000)
	const opacity = showBlockRipple ? 0.21 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'
	const color = useColorModeValue('black', 'white')
	const cornerPb = getBreakpointValue({ base: 2, lg: 3 }, circlesBreakpoint)

	const getDateOutput = (date: Date) => {
		return format(date, getDateFormat(dateFormat), { locale: dateLocales[userLocale] })
	}
	const getDayOutput = (date: Date) => {
		return format(date, 'EEEE', { locale: dateLocales[userLocale] })
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setTheTime(new Date())
		}, 60000)

		return () => {
			clearInterval(timer)
		}
	}, [])

	return (
		<Flex
			className="tc-corner-sw"
			pos="absolute"
			zIndex={9999}
			bottom={0}
			right={0}
			pb={cornerPb}
			direction="column"
			align="flex-end"
			justify="flex-end"
			transition="all 0.21s ease"
			opacity={opacity}
			filter={filter}
			animation={blurInAnimation}
		>
			<Flex
				role="group"
				onClick={onOpenDatePickerModal}
				cursor="pointer"
				direction="column"
				align="flex-end"
				transition="all 0.21s ease"
				color={color}
				_hover={{
					color: BITCOIN_ORANGE,
				}}
			>
				{staticMode && (
					<>
						{scrubbing && (
							<Spinner color="white" size="md" />
						)}
						{!scrubbing && (
							<DateOutput
								day={getDayOutput(scrubDate)}
								formattedDate={getDateOutput(scrubDate)}
							/>
						)}
					</>
				)}
				{!staticMode && (
					<DateOutput
						day={getDayOutput(theTime)}
						formattedDate={getDateOutput(theTime)}
					/>
				)}
			</Flex>
		</Flex>
	)
}
