import {
	useState,
	useEffect,
	useLayoutEffect,
	useRef,
	useMemo,
} from 'react'
import {
	Flex,
	Box,
	chakra,
	shouldForwardProp,
	type BoxProps,
	useColorModeValue,
	useColorMode,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { useIntl } from 'react-intl'
import {
	useRecoilValue,
	useSetRecoilState,
	useRecoilState,
} from 'recoil'
import { useParams } from 'react-router-dom'
import { gsap } from 'gsap'
import { sub, isAfter } from 'date-fns'

import {
	blockState,
	circlesWrapWidthState,
	pastBlocksState,
	pastBlockHoverState,
	pastBlockHoverTimestampState,
	staticModeState,
	showBlockRippleState,
	circlesBreakpointState,
	scrubBlockState,
	past24IntroStartState,
	past24IntroCompleteState,
	landscapeOrientationState,
	halvingIntroStartState,
} from '../../../state'
import { CircleWrap } from './CircleWrap'
import { TextPathCircle } from './TextPathCircle'
import { getPercentageInLast24Hours, getBreakpointValue } from '../../../utils'
import { BITCOIN_ORANGE } from '../../../constants'
import type { SimpleBlock } from '../../../models'
import { pastBlocksOffset } from './circles.constants'
import { TopMark } from './TopMark'

interface BlockBoxProps {
	index: number
	timestamp: number
	percentage: number
	bgIsLast: boolean
	bgAlpha: number
	blockHeight: number
	disabled: boolean
	// eslint-disable-next-line no-unused-vars
	onSearch: (x: number, setBlockPath: boolean, last24Block: boolean) => void
}

interface BlackBoxesProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	pastBlocks: any[]
	// eslint-disable-next-line no-unused-vars
	onSearchBlockHeight: (x: number, setBlockPath: boolean, last24Block: boolean) => void
	disabled: boolean
}

interface PastBlocksCircleProps {
	// eslint-disable-next-line no-unused-vars
	onSearchBlockHeight: (x: number, setBlockPath: boolean, last24Block: boolean) => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

const getAngle = (num: number) => {
	return Number(num.toFixed(4)) * 360
}

const BlockBox = ({
	onSearch,
	index,
	timestamp,
	percentage,
	bgIsLast,
	bgAlpha,
	blockHeight,
	disabled,
	...rest
}: BlockBoxProps) => {
	const [angle, setAngle] = useState(getAngle(percentage))
	const setPastBlockHover = useSetRecoilState(pastBlockHoverState)
	const setPastBlockHoverTimestamp = useSetRecoilState(pastBlockHoverTimestampState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const past24IntroComplete = useRecoilValue(past24IntroCompleteState)
	const borderColor = useColorModeValue('rgba(255,255,255,0.55)', 'rgba(0,0,0,0.45)')

	const bgColorLight = `hsl(0, 0%, ${bgAlpha * 100}%)`
	const bgColorDark = `hsl(0, 0%, ${bgAlpha * 100}%)`
	const bgColor = useColorModeValue(bgColorLight, bgColorDark)
	const boxBg = bgIsLast ? 'orange100' : bgColor

	const blockPathHeight = useParams<'blockHeight'>().blockHeight
	const onPastBlock = blockHeight === Number(blockPathHeight)
	const backgroundColor = onPastBlock ? 'green100' : boxBg

	const timestampDate = new Date(timestamp * 1000)

	const boxTop = getBreakpointValue({
		base: '-1px',
		sm: '-2px',
		lg: '-3px',
		jumbo: '-4px',
	}, circlesBreakpoint)
	const lessThan10BoxLeft = getBreakpointValue({ base: '-1px', md: '-3px' }, circlesBreakpoint)
	const lessThan4BoxLeft = getBreakpointValue({ base: '-2px', md: '-4px' }, circlesBreakpoint)
	const defaultBoxLeft = getBreakpointValue({ base: '-1px', md: '-2px' }, circlesBreakpoint)
	const blockSquareSize = getBreakpointValue({
		base: 4,
		sm: 5,
		md: 6,
		xl: 7,
		xxl: 8,
		jumbo: 9,
	}, circlesBreakpoint) as number
	const boxLeft = index < 10
		? lessThan10BoxLeft
		: index < 4
			? lessThan4BoxLeft
			: defaultBoxLeft
	const className = past24IntroComplete ? undefined : 'past-block'
	const pastBlockOpacity = past24IntroComplete ? 1 : 0
	const zIndex = 1100 + index
	const blockBoxTransform = onPastBlock ? 'scale(1.5)' : undefined
	const hoverStyles = !disabled
		? {
			transform: 'scale(2)',
			borderRightColor: 'transparent',
			width: `${blockSquareSize}px`,
			height: `${blockSquareSize}px`,
			backgroundColor: 'orange100',
		}
		: undefined
	const cursor = !disabled ? 'pointer' : undefined

	const handleMouseOver = () => {
		if (!disabled) {
			setPastBlockHover(blockHeight)
			setPastBlockHoverTimestamp(timestamp)
		}
	}
	const handleMouseOut = () => {
		if (!disabled) {
			setPastBlockHover(null)
			setPastBlockHoverTimestamp(null)
		}
	}
	const handleBlockClick = () => {
		if (!disabled) {
			onSearch(blockHeight, false, true)
			handleMouseOut()
		}
	}
	
	useEffect(() => {
		const intervalId = setInterval(() => {
			const newPercentage = getPercentageInLast24Hours(timestampDate)
			const newAngle = getAngle(newPercentage)
			setAngle(newAngle)
		}, 60000)

		return () => clearInterval(intervalId)
	}, [timestampDate])

	return (
		<Flex
			pos="absolute"
			zIndex={zIndex}
			h="50%"
			w="2px"
			top={0}
			left={0}
			right={0}
			m="auto"
			transform={`rotateZ(-${angle}deg)`}
			transformOrigin="50% 100%"
			transition="all 0.21s ease"
			_hover={{
				zIndex: zIndex + 500
			}}
			{...rest}
		>
			<Box
				className={className}
				opacity={pastBlockOpacity}
				onClick={handleBlockClick}
				pos="absolute"
				top={boxTop}
				left={boxLeft}
				w={`${blockSquareSize}px`}
				h={`${blockSquareSize - 1}px`}
				bg={backgroundColor}
				borderRight={`1px solid ${borderColor}`}
				transition="all 0.21s ease"
				transform={blockBoxTransform}
				cursor={cursor}
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
				onMouseMove={handleMouseOver}
				_hover={hoverStyles}
			/>
		</Flex>
	)
}

const BlockBoxes = ({
	pastBlocks,
	onSearchBlockHeight,
	disabled,
}: BlackBoxesProps) => {
	const { colorMode } = useColorMode()
	const pastBlocksCondition = pastBlocks.length > 0

	if (!pastBlocksCondition) {
		return null
	}

	return (
		<>
			{pastBlocks.map((block, index) => {
				const isLast = index === (pastBlocks.length - 1)
				const percentage = getPercentageInLast24Hours(new Date(block.timestamp * 1000))
				const boxAlpha = colorMode === 'light'
					? 1 - (Number(percentage.toFixed(2)) + 0.13) < 0
						? 0
						: 1 - (Number(percentage.toFixed(2)) + 0.13)
					: Number(percentage.toFixed(2)) + 0.13 > 1
						? 1
						: Number(percentage.toFixed(2)) + 0.13

				return (
					<BlockBox
						key={index}
						index={index}
						timestamp={block.timestamp}
						percentage={percentage}
						bgIsLast={isLast}
						bgAlpha={boxAlpha}
						blockHeight={block.height}
						onSearch={onSearchBlockHeight}
						disabled={disabled}
					/>
				)
			})}
		</>
	)
}

export const PastBlocksCircle = ({ onSearchBlockHeight }: PastBlocksCircleProps) => {
	const intl = useIntl()
	const pastBlocksCircleRef = useRef<HTMLDivElement>(null)
	const [circleClientWidth, setCircleClientWidth] = useState(0)
	const [textTransform, setTextTransform] = useState<string | undefined>(undefined)
	const [refAcquired, setRefAcquired] = useState(false)

	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const circlesWrapWidth = useRecoilValue(circlesWrapWidthState)
	const [pastBlocks, setPastBlocks] = useRecoilState(pastBlocksState)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const halvingIntroStart = useRecoilValue(halvingIntroStartState)
	const [past24IntroStart, setPast24IntroStart] = useRecoilState(past24IntroStartState)
	const [past24IntroComplete, setPast24IntroComplete] = useRecoilState(past24IntroCompleteState)
	const alphaColor = useColorModeValue('rgba(0,0,0,0.075)', 'rgba(255,255,255,0.13)')
	const markColor = useColorModeValue('#ddd', '#333')

	let earlierThanPastBlocks = false
	if (scrubBlock && pastBlocks && pastBlocks.length > 0) {
		earlierThanPastBlocks = scrubBlock.height < pastBlocks[0].height
	}
	const shouldBlur = (staticMode && earlierThanPastBlocks) || showBlockRipple
	const opacity = shouldBlur ? 0.3 : 1
	const filter = shouldBlur ? 'blur(4px)' : 'blur(0)'
	const textCircleLabel = past24IntroStart
		? `${intl.formatMessage({ id: 'circles.past_blocks' })} >`
		: `${intl.formatMessage({ id: 'app.loading' })}...`

	const circlePosition = getBreakpointValue(pastBlocksOffset, circlesBreakpoint)
	const textCircleArrowTop = getBreakpointValue({
		base: '-12px',
		md: '-15px',
		xl: '-17px',
		xxl: '-18px',
	}, circlesBreakpoint)
	const textCircleArrowStyles = {
		position: 'absolute',
		zIndex: 1000,
		height: 0,
		width: 0,
		top: textCircleArrowTop,
		left: '1px',
		right: '0',
		margin: 'auto',
	} as BoxProps
	const textCircleTransform = useMemo(
		() => getBreakpointValue({
			base: 'rotate(-90deg) translateX(1%) translateY(1.1%)',
			xxs: 'rotate(-90deg) translateX(0.8%) translateY(1%)',
			xs: 'rotate(-90deg) translateX(0.7%) translateY(0.85%)',
			sm: 'rotate(-90deg) translateX(0.8%) translateY(0.9%)',
			md: 'rotate(-90deg) translateX(0.8%) translateY(0.9%)',
			lg: 'rotate(-90deg) translateX(1%) translateY(1%)',
			xl: 'rotate(-90deg) translateX(0.8%) translateY(0.8%)',
			xxl: 'rotate(-90deg) translateX(0.8%) translateY(0.6%)',
			xxxl: 'rotate(-90deg) translateX(0.6%) translateY(0.7%)',
		}, circlesBreakpoint) as string | undefined,
		[circlesBreakpoint, landscapeOrientation],
	)

	useEffect(() => {
		setRefAcquired(true)
	}, [])

	useEffect(() => {
		if (circleClientWidth > 0) {
			setTextTransform(textCircleTransform)
		}
	}, [
		circlesBreakpoint,
		circleClientWidth,
		textCircleTransform,
	])

	useEffect(() => {
		const setCircleWidth = () => {
			if (refAcquired && pastBlocksCircleRef.current) {
				setCircleClientWidth(pastBlocksCircleRef.current.clientWidth)
			}
		}
		setCircleWidth()
		window.addEventListener('resize', setCircleWidth)
		return () => window.removeEventListener('resize', setCircleWidth)
	}, [
		refAcquired,
		pastBlocksCircleRef,
		circlesBreakpoint,
	])

	useEffect(() => {
		const intervalId = setInterval(() => {	
			const twentyFourHoursAgo = sub(new Date(), { hours: 24 })

			const filteredPastBlocks = pastBlocks.filter((item: SimpleBlock) => {
				const itemTimestamp = new Date(item.timestamp * 1000)
				return isAfter(itemTimestamp, twentyFourHoursAgo)
			})

			if (pastBlocks.length !== filteredPastBlocks.length) {
				console.info('[24h blocks] interval filter')
				// eslint-disable-next-line 
				setPastBlocks((prevPastBlocks) => [...filteredPastBlocks])
			}
		}, 30000)

		return () => clearInterval(intervalId)
	}, [
		block,
		pastBlocks,
	])
	
	useLayoutEffect(() => {
		const rollOutCondition = pastBlocksCircleRef.current
			&& pastBlocks.length > 0
			&& halvingIntroStart
			&& !past24IntroComplete

		const ctx = gsap.context(() => {
			if (rollOutCondition) {
				setPast24IntroStart(true)
				gsap.to('.past-block', {
					opacity: 1,
					delay: 0.5,
					stagger: {
						each: -0.01,
						from: 0,
					},
					onComplete: () => {
						setPast24IntroComplete(true)
					},
				})
			}
		}, pastBlocksCircleRef)

		return () => ctx.revert()
	}, [
		pastBlocks,
		halvingIntroStart,
		past24IntroComplete,
		pastBlocksCircleRef,
	])

	return (
		<CircleWrap
			className="past-blocks-circle"
			opacity={opacity}
			filter={filter}
			zIndex={1200}
		>
			<TextPathCircle
				overrideTransform={textTransform}
				containerWidth={circlesWrapWidth}
				circleWidth={circleClientWidth}
				text={textCircleLabel}
				id="pastBlocks"
			/>

			<Box
				pos="absolute"
				zIndex={1001}
				top={circlePosition}
				left={circlePosition}
				right={circlePosition}
				bottom={circlePosition}
				borderRadius="50%"
				borderWidth="2px"
				borderColor={alphaColor}
			/>

			<Box
				ref={pastBlocksCircleRef}
				pos="absolute"
				zIndex={1001}
				top={circlePosition}
				left={circlePosition}
				right={circlePosition}
				bottom={circlePosition}
				borderRadius="50%"
			>
				{!past24IntroStart && (
					<MotionBox
						borderLeft="3px solid transparent"
						borderRight="3px solid transparent"
						borderTop={`12px solid ${BITCOIN_ORANGE}`}
						{...textCircleArrowStyles}
						animate={{
							opacity: [1, 0.3],
						}}
						// @ts-ignore
						transition={{
							duration: 0.5,
							ease: 'linear',
							repeat: Infinity,
							repeatType: 'loop',
						}}
					/>
				)}
				
				{past24IntroStart && (
					<TopMark
						staticMode={staticMode}
						color={markColor}
						disableBlur
						zIndex={999}
						mt="3px"
					/>
				)}

				<BlockBoxes
					pastBlocks={pastBlocks}
					onSearchBlockHeight={onSearchBlockHeight}
					disabled={earlierThanPastBlocks}
				/>
			</Box>
		</CircleWrap>
	)
}
