import { useEffect, useState } from 'react'
import {
	Button,
	Flex,
	Text,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../hooks'
import {
	appInitializedState,
	circlesBreakpointState,
	landscapeOrientationState,
	confModeState,
	showBlockRippleState,
} from '../../../state'
import {
	LanguageSetterButton,
	TCConfModeModal,
	CurrencySetter,
} from '../../features'
import {
	blurInAnimation,
	PADDING_BREAKPOINTS,
	BITCOIN_ORANGE,
} from '../../../constants'
import { getBreakpointValue } from '../../../utils'
import { rtlLocales } from '../../../lang/messages'

export const TCFooter = () => {
	const [mt, setMt] = useState(0)
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const appInitialized = useRecoilValue(appInitializedState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const confMode = useRecoilValue(confModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const color = useColorModeValue('black', 'white')
	const colorAlt3 = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.15)')
	const versionColor = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const {
		isOpen: isOpenConfMode,
		onOpen: onOpenConfMode,
		onClose: onCloseConfMode,
	} = useDisclosure()
	const px = getBreakpointValue(PADDING_BREAKPOINTS, circlesBreakpoint)
	const versionNum = process.env.REACT_APP_VERSION
	const justify = landscapeOrientation ? 'space-between' : 'center'
	const height = getBreakpointValue({ base: '30px', sm: '40px' }, circlesBreakpoint)
	const opacity = showBlockRipple ? 0.3 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'

	useEffect(() => {
		if (!landscapeOrientation) {
			const marginTop = getBreakpointValue({ base: 4, sm: 6, lg: 8 }, circlesBreakpoint) as number
			if (marginTop) setMt(marginTop)
		}

	}, [circlesBreakpoint, landscapeOrientation])

	return (
		<Flex
			className="tc-footer"
			flex="0 0 auto"
			pos="relative"
			zIndex={999}
			w="100%"
			h={height}
			color={color}
			justify={justify}
			align="center"
			gap={6}
			px={px}
			mt={mt}
			opacity={opacity}
			filter={filter}
			animation={blurInAnimation}
		>
			{appInitialized && confMode && (
				<>
					<Button
						onClick={onOpenConfMode}
						color={colorAlt3}
						borderColor={colorAlt3}
						variant="outline"
						size="xs"
						borderWidth={2}
						transition="all 0.21s ease"
						_hover={{
							borderColor: BITCOIN_ORANGE,
							color: BITCOIN_ORANGE,
						}}
						_active={{
							borderColor: BITCOIN_ORANGE,
							color: BITCOIN_ORANGE,
							bgColor: 'whiteAlpha.500',
						}}
					>
						CONF
					</Button>

					<TCConfModeModal
						isOpenConfMode={isOpenConfMode}
						onCloseConfMode={onCloseConfMode}
					/>
				</>
			)}

			{appInitialized && (
				<>
					<Flex align="center" gap={6}>
						{!confMode && (
							<>
								<Flex
									className="tc-footer-language-select-wrap"
									align="center"
									gap={1}
									dir={direction}
								>
									<LanguageSetterButton />
								</Flex>

								<Flex
									className="tc-footer-currency-select-wrap"
									align="center"
									gap={1}
									dir={direction}
								>
									<CurrencySetter />
								</Flex>
							</>
						)}
					</Flex>
					
					<Text
						display="inline-block"
						textAlign="center"
						fontWeight="bold"
						fontSize="10px"
						color={versionColor}
						m={0}
					>
						v{versionNum}
					</Text>
				</>
			)}
		</Flex>
	)
}
