import type { FC, PropsWithChildren } from 'react'
import {
	Flex,
	Box,
	useColorModeValue,
	type ResponsiveValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import {
	TCHeader,
	TCFooter,
	HalvingCountdown,
} from './index'
import {
	circlesBreakpointState,
	landscapeOrientationState,
	contentHeightState,
	appInitializedState,
} from '../../state'
import { getBreakpointValue } from '../../utils'
import {
	PADDING_BREAKPOINTS,
	PADDING_BREAKPOINTS_PORTRAIT,
} from '../../constants'
import { Perspective } from './Perspective'

export const MasterWrap: FC<PropsWithChildren> = ({ children }) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const contentHeight = useRecoilValue(contentHeightState)
	const appInitialized = useRecoilValue(appInitializedState)

	const bg = useColorModeValue('linear-gradient(#eee, #eee, #fff, #fff)', 'black')
	const contentDirection = landscapeOrientation ? 'row' : 'column'
	const responsiveJustify = getBreakpointValue({ base: 'flex-start', md: 'space-between' }, circlesBreakpoint) as ResponsiveValue<'space-between' | 'flex-start'>
	const responsiveGapContent = landscapeOrientation
		? getBreakpointValue(PADDING_BREAKPOINTS, circlesBreakpoint)
		: getBreakpointValue(PADDING_BREAKPOINTS_PORTRAIT, circlesBreakpoint)
	const contentPx = landscapeOrientation
		? getBreakpointValue(PADDING_BREAKPOINTS, circlesBreakpoint)
		: getBreakpointValue(PADDING_BREAKPOINTS_PORTRAIT, circlesBreakpoint)
	const responsiveMasterOverflow = landscapeOrientation
		? undefined
		: 'auto'
	const landscapeOverflowCondition = getBreakpointValue({ base: 'true', xs: 'false' }, circlesBreakpoint)
	const responsiveContentOverflow = landscapeOrientation
		? landscapeOverflowCondition === 'true'
			? 'auto'
			: undefined
		: undefined
	const responsiveContentHeight = landscapeOrientation ? contentHeight : undefined
	const responsivePaddingTop = landscapeOrientation ? 0 : 2
	const consitionalPos = landscapeOrientation ? 'none' : 'block'
	const countdownDisplay = getBreakpointValue({ base: consitionalPos, sm: 'none' }, circlesBreakpoint) as ResponsiveValue<'block' | 'none'>
	const perspectiveDisplay = landscapeOrientation ? 'true' : 'false'

	return (
		<Flex
			className="tc-master-wrap"
			direction="column"
			align="center"
			justify={responsiveJustify}
			bg={bg}
			minH="100vh"
			w="100vw"
			p="env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)"
			overflowY={responsiveMasterOverflow}
			overflowX="hidden"
		>
			<TCHeader />

			<Box display={countdownDisplay}>
				<HalvingCountdown posTop={0} />
			</Box>

			<Flex
				className="tc-inner-content-wrap"
				w="100%"
				h={responsiveContentHeight}
				flex="1 0 auto"
				pos="relative"
				direction={contentDirection}
				justify={responsiveJustify}
				align="center"
				gap={responsiveGapContent}
				px={contentPx}
				pt={responsivePaddingTop}
				overflowX={responsiveContentOverflow}
				style={{
					perspective: '800px',
					perspectiveOrigin: 'top center',
				}}
			>
				{appInitialized && (
					<Perspective display={perspectiveDisplay} />
				)}

				{children}
			</Flex>

			<TCFooter />
		</Flex>
	)
}