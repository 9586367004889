import {
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState,
} from 'recoil'
import { useToken } from '@chakra-ui/react'

import { CircleWrap } from './CircleWrap'
import {
	blockState,
	circlesBreakpointState,
	circlesWrapWidthState,
	landscapeOrientationState,
	scrubBlockState,
	showBlockRippleState,
	staticModeState,
	supplyIntroCompleteState,
	supplyIntroStartState,
} from '../../../state'
import { TextPathCircle } from './TextPathCircle'
import {getBreakpointValue, getSupplyIssued } from '../../../utils'
import {
	percentageCircleOffset,
	textCircle2TransformConfig,
	textCircle2TransformConfigPortrait,
} from './circles.constants'
import { Box, useColorModeValue } from '@chakra-ui/react'

interface NotchProps {
	percent: number
}

export const PercentageSupply = () => {
	const [lightorange80, lightorange60] = useToken('colors', ['lightorange80', 'lightorange60'])
	const [textTransform, setTextTransform] = useState<string | undefined>(undefined)
	const [circleClientWidth, setCircleClientWidth] = useState(0)
	const [refAcquired, setRefAcquired] = useState(false)
	const [percentage, setPercentage] = useState(0)
	
	const circleRef = useRef<HTMLDivElement>(null)

	const setSupplyIntroStart = useSetRecoilState(supplyIntroStartState)
	const [supplyIntroComplete, setSupplyIntroComplete] = useRecoilState(supplyIntroCompleteState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const circlesWrapWidth = useRecoilValue(circlesWrapWidthState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const staticMode = useRecoilValue(staticModeState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	
	const bgColor = useColorModeValue('rgba(0,0,0,0.15)', 'rgba(255,255,255,0.25)')
	const color = useColorModeValue(lightorange80, lightorange60)
	const notchBgColor = useColorModeValue('#ddd', '#333')

	const circleSize = getBreakpointValue(percentageCircleOffset, circlesBreakpoint)
	const notchH = getBreakpointValue({ base: '12px', md: '18px', jumbo: '21px' }, circlesBreakpoint)
	const notchTop = getBreakpointValue({ base: '-6px', md: '-9px', jumbo: '-11px' }, circlesBreakpoint)
	const notchWidth = getBreakpointValue({ base: '2px', jumbo: '3px' }, circlesBreakpoint)
	const dotSize = getBreakpointValue({ base: '6px', sm: '8px', jumbo: '10px' }, circlesBreakpoint)
	const dotTop = getBreakpointValue({ base: '3px', sm: '4px', jumbo: '5px' }, circlesBreakpoint)
	const responsiveStrokeWidth = getBreakpointValue({ base: 2, sm: 1.25, jumbo: 1.5 }, circlesBreakpoint)

	const blockHeight = block.height
	const scrubBlockHeight = scrubBlock.height
	const supply = staticMode 
		? getSupplyIssued(scrubBlockHeight)
		: getSupplyIssued(blockHeight)
	const percentageCalculated = supply ? supply.supplyIssuedPercent * 100 : 0

	const opacity = showBlockRipple ? 0.3 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'

	const Circle = () => {
		const strokeDashOffset = Number(100 - percentage).toFixed(3)

		return (
			<circle
				strokeDasharray={100}
				strokeDashoffset={strokeDashOffset}
				pathLength="100"
				r={250}
				cx={250}
				cy={250}
				fill="none"
				stroke={color}
				strokeWidth={responsiveStrokeWidth}
				style={{
					transform: 'rotate(-90deg)',
					transformOrigin: 'center center',
				}}
			/>
		)
	}

	const BgCircle = () => {
		return (
			<circle
				r={250}
				cx={250}
				cy={250}
				fill="none"
				stroke={bgColor}
				strokeWidth={1}
				strokeDasharray={4}
			/>
		)
	}

	const Notch = ({ percent }: NotchProps) => {
		const angle = 360 * (percent / 100)
		const notchBg = percentage >= percent
			? color
			: notchBgColor

		return (
			<Box
				pos="absolute"
				zIndex={999}
				top={0}
				left={0}
				right={0}
				m="auto"
				w="20px"
				h="50%"
				transform={`rotateZ(${angle}deg)`}
				transformOrigin="50% 100%"
			>
				<Box
					pos="absolute"
					top={notchTop}
					left={0}
					right={0}
					m="auto"
					w={notchWidth}
					h={notchH}
					bg={notchBg}
				/>
			</Box>
		)
	}

	useEffect(() => {
		setRefAcquired(true)
	}, [])

	useEffect(() => {
		const newSupply = getSupplyIssued(scrubBlockHeight)
		const newSupplyCalculated = newSupply ? newSupply.supplyIssuedPercent * 100 : 0
		if (supplyIntroComplete) {
			setPercentage(newSupplyCalculated)
		}
	}, [
		staticMode,
		supplyIntroComplete,
		scrubBlockHeight,
	])

	useEffect(() => {
		if (!supplyIntroComplete && percentageCalculated > 0) {
			setSupplyIntroStart(true)
			const startNumber = 0
			const endNumber = percentageCalculated
			const time = 1500
			if (startNumber < endNumber) {
				const interval = time / (endNumber - startNumber)
				const id = setInterval(() => {
					if (percentage < endNumber) {
						setPercentage(percentage + 1)
						return
					} else {
						clearInterval(id)
						setSupplyIntroComplete(true)
						return
					}
				}, interval)
				return () => clearInterval(id)
			}
		}
	}, [
		supplyIntroComplete,
		percentageCalculated,
		percentage,
	])

	useLayoutEffect(() => {
		const setCircleWidth = () => {
			const circle = circleRef.current
			if (circle) setCircleClientWidth(circle.clientWidth)
		}
		const config = landscapeOrientation
			? textCircle2TransformConfig
			: textCircle2TransformConfigPortrait
		const newTextCircleTransform = getBreakpointValue(config, circlesBreakpoint) as string | undefined

		setCircleWidth()
		setTextTransform(newTextCircleTransform)
		window.addEventListener('resize', setCircleWidth)
		return () => window.removeEventListener('resize', setCircleWidth)
	}, [
		refAcquired,
		circlesBreakpoint,
		landscapeOrientation,
	])
    
	return (
		<CircleWrap
			className="supply-percentage-circle-wrap"
			opacity={opacity}
			filter={filter}
			transition="all 0.45s ease"
		>
			<TextPathCircle
				overrideTransform={textTransform}
				containerWidth={circlesWrapWidth}
				circleWidth={circleClientWidth}
				text="supply % >"
				id="supply"
			/>

			<Box
				className="supply-percentage-circle-dot"
				pos="absolute"
				zIndex={999}
				top={`calc(${circleSize} - ${dotTop})`}
				left={0}
				right={0}
				m="auto"
				h={dotSize}
				w={dotSize}
				borderRadius="50%"
				bg={color}
			/>

			<Box
				className="supply-percentage-circle-box"
				ref={circleRef}
				pos="absolute"
				top={circleSize}
				left={circleSize}
				right={circleSize}
				bottom={circleSize}
			>
				<svg
					className="supply-percentage-circle"
					viewBox="0 0 500 500"
					style={{
						position: 'absolute',
						zIndex: 998,
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						margin: 'auto',
						width: '100%',
						height: '100%',
						overflow: 'visible',
						opacity: 1,
					}}
				>
					<BgCircle />

					<Circle />
				</svg>

				<Notch percent={50} />
				<Notch percent={75} />
				<Notch percent={87.5} />
				<Notch percent={93.75} />

				{blockHeight > 1049999 && (
					<Notch percent={96.875} />
				)}
			</Box>
		</CircleWrap>
	)
}
