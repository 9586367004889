import { useMemo } from 'react'
import {
	Routes,
	Route,
	Outlet,
	Navigate,
	useParams,
} from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { IntlProvider } from 'react-intl'
import { useRecoilValue } from 'recoil'
import PullToRefresh from 'react-simple-pull-to-refresh'

import { landscapeOrientationState, confModeState } from '../../state'
import { useAppSelector } from '../../hooks'
import { TCHead } from './TCHead'
import { MasterWrap } from '../layout'
import TCAppV3 from '../../TCAppV3'
import { NoMatchRoute } from './NoMatchRoute'
import { theme } from '../../theme'
import getMessages, { type UserLocale } from '../../lang/messages'

const LocaleLayout = () => {
	const { userLocalePath } = useParams<'userLocalePath'>()
	const { userLocale } = useAppSelector(({ settings }) => settings) || 'en'
	// NEW LOCALE UPDATE HERE
	const localesPattern = /^ar|ca|de|en|es|fr|gr|it|pl|pt|ru|sv|tr|zh$/
	const isValidLocale = localesPattern.test(`${userLocalePath}`)

	return isValidLocale
		? <Outlet />
		: <Navigate to={`/${userLocale}`} replace />
}

export const RoutedApp = () => {
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const safeUserLocale = userLocale ? userLocale : 'en'
	const localeMessages = useMemo(
		() => userLocale
			? getMessages(userLocale)
			: getMessages('en' as UserLocale),
		[userLocale],
	)
	const confMode = useRecoilValue(confModeState)
	const showGallery = !confMode && process.env.REACT_APP_SELF_HOSTED !== 'true'

	return (
		<IntlProvider
			messages={localeMessages}
			locale="en"
			defaultLocale="en"
		>
			<TCHead locale={userLocale} />
			<ChakraProvider theme={theme}>
				<PullToRefresh
					isPullable={!landscapeOrientation}
					onRefresh={async () => window.location.reload()}
					pullingContent={''}
					refreshingContent={''}
				>
					<MasterWrap>
						<Routes>
							<Route path="/" element={<LocaleLayout />}>
								{/* Redirect Routes */}
								<Route path="/" element={<Navigate to={`/${safeUserLocale}`} replace />} />
								<Route path="/undefined" element={<Navigate to={`/${safeUserLocale}`} replace />} />
								<Route path="/conference" element={<Navigate to={`/${safeUserLocale}/conference`} replace />} />
								{/* <Route path="/calculator" element={<Navigate to={`/${safeUserLocale}/calculator`} replace />} /> */}
								{showGallery && (
									<Route path="/gallery" element={<Navigate to={`/${safeUserLocale}/gallery`} replace />} />
								)}
								
								{/* Primary App Routes */}
								<Route path=":userLocalePath/" element={<TCAppV3 />}>
									<Route index element={<TCAppV3 />} />
									<Route path="block/:blockHeight" element={<TCAppV3 />} />
									<Route path="conference" element={<TCAppV3 />} />
									{/* <Route path="calculator" element={<TCAppV3 />} /> */}
									{showGallery && (
										<Route path="gallery" element={<TCAppV3 />} />
									)}
								</Route>
								<Route path="*" element={<NoMatchRoute />} />
							</Route>
						</Routes>
					</MasterWrap>
				</PullToRefresh>
			</ChakraProvider>
		</IntlProvider>
	)
}
