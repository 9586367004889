import {
	Text,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'

import {
	blockState,
	currentDifficultyState,
	prevDiffDateState,
	circlesBreakpointState,
	previousRetargetState,
} from '../../../../state'
import { Label } from '../../../shared'
import { useAppSelector } from '../../../../hooks'
import {
	abbreviateNumberHash,
	positiveInt,
	getBreakpointValue,
	getTimeAgo,
} from '.././../../../utils'
import { primaryDataFontSize } from './dataComponents.constants'
import { DIFFICULTY_ADJUSTMENT_BLOCKS } from '../../../../constants'

export const PrevDiffAdjust = () => {
	const intl = useIntl()
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const prevDiffDate = useRecoilValue(prevDiffDateState)
	const [green100, lightgreen80] = useToken('colors', ['green100', 'lightgreen80'])
	const previousRetarget = useRecoilValue(previousRetargetState)

	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const green = useColorModeValue(lightgreen80, green100)
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const retarget = parseFloat(previousRetarget.toFixed(2))
	const prevDate = prevDiffDate ? new Date(prevDiffDate * 1000) : ''
	const ago = intl.formatMessage({ id: 'block_height.ago' })
	const timeAgo = prevDate ? getTimeAgo(prevDate, userLocale, ago) : ''
	const prevColor = retarget > 0
		? green
		: 'red100'

	return (
		<>
			<Label>
				<FormattedMessage id="data.prev_diff_adj.title" />
			</Label>

			<Text
				lineHeight={fontSize}
				my={1}
				fontSize={fontSize}
				fontWeight="bold"
				whiteSpace="nowrap"
				display="flex"
				color={prevColor}
				align="center"
				gap="2px"
			>
				{retarget > 0 && (
					<span>&uarr;</span>
				)}
				{retarget < 0 && (
					<span>&darr;</span>
				)}
				{positiveInt(retarget)}%
			</Text>

			{prevDate && (
				<Label color={colorAlt} fontWeight="semibold">
					<b>{timeAgo}</b>
				</Label>
			)}
		</>
	)
}

export const DataCurrentDifficulty = () => {
	const block = useRecoilValue(blockState)
	const currentDifficulty = useRecoilValue(currentDifficultyState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const difficulty = abbreviateNumberHash(currentDifficulty)
	const diffEpoch = Math.floor(((block.height / DIFFICULTY_ADJUSTMENT_BLOCKS))) + 1

	return (
		<>
			<Label>
				EPOCH: <b>{diffEpoch}</b>
			</Label>

			<Text
				lineHeight={fontSize}
				my={1}
				color={color}
				fontSize={fontSize}
				fontWeight="bold"
				whiteSpace="nowrap"
				display="flex"
				flexGrow={0}
				align="center"
			>
				{difficulty}
			</Text>

			<Label
				color={colorAlt}
				fontWeight="semibold"
				mb={2}
			>
				<FormattedMessage id="data.difficulty.hashes_per_block" />
			</Label>
		</>
	)
}
