import { useEffect, useState } from 'react'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import {
	Box,
	Flex,
	Spinner,
	Text,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'

import { useAppSelector } from '../../../hooks'
import {
	blockState,
	circlesBreakpointState,
	loadingBlockHeightState,
	scrubBlockState,
	scrubbingState,
	shouldSetAppToBlockPathState,
	showBlockRippleState,
	staticModeState,
	editingBlockHeightState,
	supplyIntroCompleteState,
	pastBlockHoverState,
	pastBlockHoverTimestampState,
	confModeState,
} from '../../../state'
import {
	getAmPm,
	getAvgBlockTime,
	getBreakpointValue,
	getTimeAgo,
	getTimeFormat,
} from '../../../utils'
import { Label } from '../../shared'
import { secondaryDataFontSize } from '../../features/calendar/data-components/dataComponents.constants'
import { blurInFastAnimation } from '../../../constants'
import { dateLocales } from '../../../lang/dateFnsLocales'

interface CircleProps {
	stroke: string
}

export const BlockTimer = () => {
	const intl = useIntl()
	const [theTime, setTheTime] = useState(new Date())
	const { userLocale, timeFormat } = useAppSelector(({ settings }) => settings)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const confMode = useRecoilValue(confModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const editingBlockHeight = useRecoilValue(editingBlockHeightState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const pastBlockHover = useRecoilValue(pastBlockHoverState)
	const pastBlockHoverTimestamp = useRecoilValue(pastBlockHoverTimestampState)
	const block = useRecoilValue(blockState)
	
	const scrubbing = useRecoilValue(scrubbingState)
	const loadingBlockHeight = useRecoilValue(loadingBlockHeightState)
	const shouldSetAppToBlockPath = useRecoilValue(shouldSetAppToBlockPathState)
	const supplyIntroComplete = useRecoilValue(supplyIntroCompleteState)
	
	const [
		green100,
		lightgreen60,
		lightgreen40,
		orange100,
		lightorange40,
		lightorange60,
		red,
		lightred40,
		lightred60,
	] = useToken('colors', [
		'green100',
		'lightgreen60',
		'lightgreen40',
		'orange100',
		'lightorange40',
		'lightorange60',
		'red100',
		'lightred40',
		'lightred60',
	])
	const fontSize = getBreakpointValue(secondaryDataFontSize, circlesBreakpoint)
	const bg = useColorModeValue('white', 'black')
	const textColor = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const startColor = useColorModeValue('#ddd', '#333')
	const scrubCircleColor = useColorModeValue('rgba(0,0,0,0.1)', 'rgba(255,255,255,0.1)')
	const colorModeGreen = useColorModeValue(green100, green100)
	const colorModeGreen2 = useColorModeValue(lightgreen60, lightgreen40)
	const colorModeOrange = useColorModeValue(orange100, orange100)
	const colorModeOrange2 = useColorModeValue(lightorange60, lightorange40)
	const colorModeRed2 = useColorModeValue(lightred60, lightred40)
	const spinnerColor = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const circleBgBase = useColorModeValue('white', 'rgba(255,255,255,0.075)')
	const circleBgSm = useColorModeValue('rgba(0,0,0,0.025)', 'rgba(255,255,255,0.075)') 
	const circleBg = getBreakpointValue({ base: circleBgBase, sm: circleBgSm }, circlesBreakpoint)
	const topMarkWidth = getBreakpointValue({ base: '3px', sm: '4px', lg: '6px' }, circlesBreakpoint)
	const topMarkHeight = getBreakpointValue({ base: '12px', sm: '16px', lg: '18px' }, circlesBreakpoint)
	const topMarkTop = getBreakpointValue({ base: '-6px', sm: '-8px', lg: '-9px' }, circlesBreakpoint)
	const responsiveTimerWidth = getBreakpointValue({
		base: '27%',
		sm: '24%',
		lg: '24.55%',
		xl: '26%',
	}, circlesBreakpoint)

	const ago = intl.formatMessage({ id: 'block_height.ago' })
	const scrubDate = scrubBlock.timestamp ? new Date(scrubBlock.timestamp * 1000) : ''
	const scrubTimeAgo = scrubDate ? getTimeAgo(scrubDate, userLocale, ago) : ''
	const pastBlockDate = pastBlockHoverTimestamp ? new Date(pastBlockHoverTimestamp * 1000) : ''
	const pastBlockDay = pastBlockDate ? format(pastBlockDate, 'EEEE', { locale: dateLocales[userLocale] }) : ''
	const pastBlockHoverTime = pastBlockDate ? format(pastBlockDate, getTimeFormat(timeFormat)) : ''
	const pastBlockHoverAmPm = pastBlockDate ? getAmPm(pastBlockDate, timeFormat) : ''

	const { timestamp } = block
	const secondsSinceLastBlock = Number((Number(differenceInSeconds(theTime, new Date(timestamp * 1000)))).toFixed(0))
	const multiple = Math.floor(secondsSinceLastBlock / 600)
	const secondsRemainder = secondsSinceLastBlock % 600
	const percentage = Number((secondsRemainder / 600).toFixed(4)) * 100
	const blockHeightReady = supplyIntroComplete
		&& !shouldSetAppToBlockPath
		&& !loadingBlockHeight
		&& !scrubbing
	const opacity = editingBlockHeight
		? 0
		: showBlockRipple
			? 0.125
			: 1
	const filter = showBlockRipple || editingBlockHeight ? 'blur(4px)' : 'blur(0)'

	const timeSinceLastBlock = getAvgBlockTime(secondsSinceLastBlock * 1000)

	const bgColor = () => {
		switch (multiple) {
			case 0:
				return startColor
			case 1:
				return colorModeGreen
			case 2:
				return colorModeGreen2
			case 3:
				return colorModeOrange
			case 4:
				return colorModeOrange2
			case 5:
				return red
			case 6: 
				return colorModeRed2
			default:
				return multiple < 0
					? startColor
					: multiple > 6 && multiple % 2 === 0
						? red
						: colorModeRed2
		}
	}
	const topMarkBg = staticMode || !blockHeightReady
		? 'transparent'
		: bgColor()

	const BgCircle = ({ stroke }: CircleProps) => {
		return (
			<circle
				r={50}
				cx={50}
				cy={50}
				fill="none"
				stroke={stroke}
				strokeWidth={3}
			/>
		)
	}

	const Circle = ({ stroke }: CircleProps) => {
		const strokeDashOffset = Number(100 - percentage).toFixed(2)

		return (
			<circle
				strokeDasharray={100}
				strokeDashoffset={strokeDashOffset}
				pathLength="100"
				r={50}
				cx={50}
				cy={50}
				fill="none"
				stroke={stroke}
				strokeWidth={3}
				style={{
					transition: 'all 0.21s ease',
					position: 'relative',
					zIndex: 1,
				}}
			/>
		)
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setTheTime(new Date())
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [])

	if (!supplyIntroComplete || confMode) {
		return null
	}

	return (
		<Flex
			className="block-timer-wrap"
			pos="relative"
			flexShrink={0}
			w={responsiveTimerWidth}
			borderRadius="50%"
			zIndex={1999}
			bg={blockHeightReady ? bg : 'transparent'}
			opacity={opacity}
			filter={filter}
			animation={blurInFastAnimation}
			_after={{
				content: '""',
				w: '100%',
				h: '100%',
				pos: 'absolute',
				bg: circleBg,
				borderRadius:'50%',
				zIndex: 1,
			}}
		>
			<Flex
				pos="relative"
				zIndex={2}
				w="100%"
				pb="100%"
			>
				{!pastBlockHover && (
					<Box
						pos="absolute"
						h={topMarkHeight}
						w={topMarkWidth}
						top={topMarkTop}
						left={0}
						right={0}
						m="auto"
						opacity={opacity}
						filter={filter}
						bg={topMarkBg}
						animation="blurIn 0.42s ease-in"
					/>
				)}

				{!blockHeightReady && (
					<>
						<Spinner
							size="lg"
							color={spinnerColor}
							pos="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							m="auto"
						/>

						<svg
							className="block-timer"
							viewBox="0 0 100 100"
							style={{
								position: 'absolute',
								zIndex: 1000,
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								margin: 'auto',
								width: '100%',
								height: '100%',
								overflow: 'visible',
								opacity: 1,
							}}
						>
							<circle
								r={50}
								cx={50}
								cy={50}
								fill="none"
								stroke={scrubCircleColor}
								strokeWidth={3}
							/>
						</svg>
					</>
				)}

				{!staticMode
					&& blockHeightReady
					&& !editingBlockHeight
					&& !pastBlockHover
					&& (
						<>
							<Flex
								direction="column"
								justify="center"
								align="center"
								pos="absolute"
								top={0}
								left={0}
								right={0}
								bottom={0}
								zIndex={2}
								animation="blurIn 0.42s ease-in"
							>
								<Label>Last Block</Label>
								
								{secondsSinceLastBlock < 1 && (
									<Spinner size="sm" my={1} />
								)}

								{secondsSinceLastBlock > 0 && (
									<Text
										align="center"
										color={textColor}
										fontSize={fontSize}
										fontWeight="bold"
										lineHeight="none"
										my={1}
									>
										{timeSinceLastBlock}
									</Text>
								)}

								<Label
									fontWeight="semibold"
									color={colorAlt}
								>
									{ago}
								</Label>
								
								{/* <Flex display={detailButtonDisplay}>
									<DetailButton />
								</Flex> */}
							</Flex>

							<svg
								className="timer"
								viewBox="0 0 100 100"
								style={{
									position: 'absolute',
									zIndex: 1000,
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									margin: 'auto',
									width: '100%',
									height: '100%',
									transform: 'rotate(-90deg)',
									overflow: 'visible',
									opacity: 1,
								}}
							>
								{/* {secondsSinceLastBlock < 1 && (
									<BgCircle stroke={startColor} />
								)} */}

								{multiple === 0 && (
									<>
										<BgCircle stroke={startColor} />

										<Circle stroke={colorModeGreen} />
									</>
								)}

								{multiple === 1 && (
									<>
										<BgCircle stroke={colorModeGreen} />

										<Circle stroke={colorModeGreen2} />
									</>
								)}

								{multiple === 2 && (
									<>
										<BgCircle stroke={colorModeGreen2} />

										<Circle stroke={colorModeOrange} />
									</>
								)}
								
								{multiple === 3 && (
									<>
										<BgCircle stroke={colorModeOrange} />

										<Circle stroke={colorModeOrange2} />
									</>
								)}
								
								{multiple === 4 && (
									<>
										<BgCircle stroke={colorModeOrange2} />

										<Circle stroke={red} />
									</>
								)}

								{multiple === 5 && (
									<>
										<BgCircle stroke={red} />

										<Circle stroke={colorModeRed2} />
									</>
								)}

								{multiple > 5 && (
									<>
										<BgCircle stroke={colorModeRed2} />

										<Circle stroke={red} />
									</>
								)}

								{multiple > 6 && multiple % 2 === 1 && (
									<>
										<BgCircle stroke={red} />

										<Circle stroke={colorModeRed2} />
									</>
								)}

								{multiple > 6 && multiple % 2 === 0 && (
									<>
										<BgCircle stroke={colorModeRed2} />

										<Circle stroke={red} />
									</>
								)}
							</svg>
						</>
					)}
				
				{staticMode
					&& blockHeightReady
					&& !editingBlockHeight
					&& !pastBlockHover
					&& (
						<>
							<Flex
								direction="column"
								justify="center"
								align="center"
								pos="absolute"
								top={0}
								left={0}
								right={0}
								bottom={0}
							>
								<Text
									align="center"
									color={textColor}
									fontSize={fontSize}
									fontWeight="bold"
									lineHeight="none"
									my={1}
								>
									{scrubTimeAgo}
								</Text>
							</Flex>

							<svg
								className="timer"
								viewBox="0 0 100 100"
								style={{
									position: 'absolute',
									zIndex: 1000,
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									margin: 'auto',
									width: '100%',
									height: '100%',
									overflow: 'visible',
									opacity: 1,
								}}
							>
								<circle
									r={50}
									cx={50}
									cy={50}
									fill="none"
									stroke={startColor}
									strokeWidth={3}
								/>
							</svg>
						</>
					)}

				{pastBlockHover && (
					<>
						<Flex
							direction="column"
							justify="center"
							align="center"
							pos="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
						>
							<Text
								align="center"
								color={orange100}
								fontSize={fontSize}
								fontWeight="bold"
								textTransform="uppercase"
								lineHeight="none"
								my={1}
							>
								{pastBlockHoverTime} {pastBlockHoverAmPm}<br/><span style={{ color: colorAlt, fontSize: '75%' }}>{pastBlockDay}</span>
							</Text>
						</Flex>

						<svg
							className="timer"
							viewBox="0 0 100 100"
							style={{
								position: 'absolute',
								zIndex: 1000,
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								margin: 'auto',
								width: '100%',
								height: '100%',
								overflow: 'visible',
								opacity: 1,
							}}
						>
							<circle
								r={50}
								cx={50}
								cy={50}
								fill="none"
								stroke={startColor}
								strokeWidth={3}
							/>
						</svg>
					</>
				)}
			</Flex>
		</Flex>
	)
}