import { useEffect, useState } from 'react'
import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState,
} from 'recoil'
import axios, { type AxiosResponse } from 'axios'
import mempoolJS from '@mempool/mempool.js'
import { differenceInHours, format } from 'date-fns'
import { useSearchParams, useLocation } from 'react-router-dom'
import { usePrevious } from '@uidotdev/usehooks'

import { useAppSelector, useAppDispatch } from '../../hooks'
import { setFullNewBlockRipple } from '../features/settings/settingsReducer'
import { getHashrateAverages } from '../../utils'
import {
	connectionState,
	appInitStartTimeState,
	blockState,
	scrubBlockState,
	// blockTxsState,
	showBlockDropState,
	showBlockRippleState,
	currentDifficultyState,
	prevDiffDateState,
	estimatedRetargetDateState,
	difficultyChangeState,
	remainingBlocksState,
	previousRetargetState,
	previousTimeState,
	timeAvgState,
	economyFeeState,
	fastestFeeState,
	hashrateAveragesState,
	maxMempoolState,
	mempoolMinFeeState,
	mempoolSizeState,
	mempoolUsageState,
	mempoolBlocksState,
	txVBytesPerSecondState,
	pastBlocksState,
	confModeState,
	confImgState,
	confImgTopState,
	confUpperMessageState,
	confLowerMessageState,
	nodesState,
	chainHeightState,
	totalAdressesState,
	totalUtxosState,
	totalVbState,
	totalDiskState,
	totalTxnsCountState,
	staticModeState,
} from '../../state'
import {
	MEMPOOL_SPACE_API_V1_URL,
	BLOCKCHAIN_INFO_BASE_URL,
	CORS_PROXY_BASE_URL,
	BITNODES_API_URL,
	TIMECHAININDEX_API_URL,
} from '../../constants'
import { abbreviateMempoolSize } from '../../utils'
import type {
	Block,
	DA,
	Fees,
	MempoolInfo,
} from '../../models'

export const AppData = () => {
	const now = new Date()
	const dispatch = useAppDispatch()
	const { fullNewBlockRipple } = useAppSelector(({ settings }) => settings)
	const [connection, setConnection] = useRecoilState(connectionState)
	const [reconnecting, setReconnecting] = useState(false)
	const [pastBlocks, setPastBlocks] = useRecoilState(pastBlocksState)
	const [block, setBlock] = useRecoilState(blockState)
	const setScrubBlock = useSetRecoilState(scrubBlockState)
	const setNodesCount = useSetRecoilState(nodesState)
	const staticMode = useRecoilValue(staticModeState)
	const [lastHashrateCall, setLastHashrateCall] = useState(now)
	const [hashrateInit, setHashrateInit] = useState(false)
	const [lastChainstateCall, setLastChainstateCall] = useState(now)
	const [chainstateInit, setChainstateInit] = useState(false)
	const setAppInitStartTime = useSetRecoilState(appInitStartTimeState)
	const [searchParams] = useSearchParams()
	const location = useLocation()

	const [retargetDate, setRetargetDate] = useState(0)
	const prevRetargetDate = usePrevious(retargetDate)
	const setEstimatedRetagetDate = useSetRecoilState(estimatedRetargetDateState)

	const [diffChange, setDiffChange] = useState(0)
	const prevDiffChange = usePrevious(diffChange)
	const setDifficultyChange = useSetRecoilState(difficultyChangeState)
	
	const [remBlocks, setRemBlocks] = useState(0)
	const prevRemBlocks = usePrevious(remBlocks)
	const setRemainingBlocks = useSetRecoilState(remainingBlocksState)
	
	const [prevRetarget, setPrevRetarget] = useState(0)
	const prevPrevRetarget = usePrevious(prevRetarget)
	const setPreviousRetarget = useSetRecoilState(previousRetargetState)

	const [prevTime, setPrevTime] = useState(0)
	const prevPrevTime = usePrevious(prevTime)
	const setPreviousTime = useSetRecoilState(previousTimeState)

	const [timeAv, setTimeAv] = useState(0)
	const prevTimeAv = usePrevious(timeAv)
	const setTimeAvg = useSetRecoilState(timeAvgState)

	const [maxMem, setMaxMem] = useState(0)
	const prevMaxMem = usePrevious(maxMem)
	const setMaxMempool = useSetRecoilState(maxMempoolState)

	const [minFee, setMinFee] = useState(0)
	const prevMinFee = usePrevious(minFee)
	const setMempoolMinFee = useSetRecoilState(mempoolMinFeeState)

	const [poolSize, setPoolSize] = useState(0)
	const prevPoolSize = usePrevious(poolSize)
	const setMempoolSize = useSetRecoilState(mempoolSizeState)

	const [poolUsage, setPoolUsage] = useState(0)
	const prevPoolUsage = usePrevious(poolUsage)
	const setMempoolUsage = useSetRecoilState(mempoolUsageState)

	const [poolBlocks, setPoolBlocks] = useState(0)
	const prevPoolBlocks = usePrevious(poolBlocks)
	const setMempoolBlocks = useSetRecoilState(mempoolBlocksState)

	const [econFee, setEconFee] = useState(0)
	const prevEconFee = usePrevious(econFee)
	const setEconomyFee = useSetRecoilState(economyFeeState)

	const [fastFee, setFastFee] = useState(0)
	const prevFastFee = usePrevious(fastFee)
	const setFastestFee = useSetRecoilState(fastestFeeState)

	const setHashrateAverages = useSetRecoilState(hashrateAveragesState)
	
	const [inflow, setInflow] = useState(0)
	const prevInflow = usePrevious(inflow)
	const setTxVBytesPerSecond = useSetRecoilState(txVBytesPerSecondState)

	const setShowBlockDrop = useSetRecoilState(showBlockDropState)
	const setShowBlockRipple = useSetRecoilState(showBlockRippleState)
	const [currenDiff, setCurrentDifficulty] = useRecoilState(currentDifficultyState)
	const [prevDiffDate, setPrevDiffDate] = useRecoilState(prevDiffDateState)
	const setConfMode = useSetRecoilState(confModeState)
	const setConfImg = useSetRecoilState(confImgState)
	const setConfImgTop = useSetRecoilState(confImgTopState)
	const setUpperMessage = useSetRecoilState(confUpperMessageState)
	const setLowerMessage = useSetRecoilState(confLowerMessageState)
	const setChainHeight = useSetRecoilState(chainHeightState)
	const setTotalAdresses = useSetRecoilState(totalAdressesState)
	const setTotalUtxos = useSetRecoilState(totalUtxosState)
	const setTotalVb = useSetRecoilState(totalVbState)
	const setTotalDisk = useSetRecoilState(totalDiskState)
	const setTotalTxnsCount = useSetRecoilState(totalTxnsCountState)

	// const setBlockTxs = useSetRecoilState(blockTxsState)
	// const selfHosted = process.env.REACT_APP_SELF_HOSTED === 'true'

	type Blk = {
		block_index: number
		hash: string
		height: number
		time: number
	}
	interface ResponseData {
		data: Blk[]
	}

	const handleGetPastBlocks = () => {
		const timestamp = (new Date()).getTime()
		const blockchainInfoRequest = `${BLOCKCHAIN_INFO_BASE_URL}/blocks/${timestamp}?format=json`
		const proxyRequest = `${CORS_PROXY_BASE_URL}?${encodeURIComponent(blockchainInfoRequest)}`
		const MAX_ATTEMPTS = 3
		const TIMEOUT = 7000

		const fetchBlocksWithRetry = async (attempts = 1) => {
			const tryMessage = attempts === MAX_ATTEMPTS
				? '[24h blocks] fetch, last attempt'
				: `[24h blocks] fetch, attempt ${attempts}`

			try {
				console.info(tryMessage)
				const response = await Promise.race([
					axios.get<ResponseData>(proxyRequest),
					new Promise((_, reject) =>
						setTimeout(() => reject(new Error('[24h blocks] timeout')), TIMEOUT)
					),
				]) as AxiosResponse<Blk[]>
				if (response.data.length === 0 && attempts < MAX_ATTEMPTS) {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							fetchBlocksWithRetry(attempts + 1)
								.then(resolve)
								.catch(reject)
						}, TIMEOUT)
					})
				}
				return response
			} catch (error) {
				if (attempts < MAX_ATTEMPTS) {
					console.info(`[24h blocks] fetch attempt ${attempts} failed.`)
					console.info('[24h blocks] retry')
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							fetchBlocksWithRetry(attempts + 1)
								.then(resolve)
								.catch(reject)
						}, 5000)
					})
				} else {
					throw error
				}
			}
		}

		fetchBlocksWithRetry()
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((response: any) => {
				console.info('[24h blocks] success count:', response.data.length)
				// eslint-disable-next-line
				const reversedBlocks = response.data.reverse()
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const formattedPastBlocks = reversedBlocks.map((block: any) => {
					return {
						height: block.height,
						id: block.hash,
						timestamp: block.time,
					}
				})
				if (formattedPastBlocks.length === 0) {
					console.error('[24h blocks] fetch failed')
				} else {
					console.info('[24h blocks] formatted blocks count:', formattedPastBlocks.length)
				}
				setPastBlocks(formattedPastBlocks)
			})
	}

	const handleGetNodes = () => {
		const RETRY_INTERVAL = 3600000

		console.info('[nodes] fetching')

		const fetchNodes = () => {
			axios.get(BITNODES_API_URL)
				.then((res) => {
					const nodesCount = res.data.results[0].total_nodes
					console.info('[nodes] success count:', nodesCount)
					setNodesCount(nodesCount)
				})
				.catch(error => {
					console.error('[nodes] error:', error)
				})
				.then(() => {
					// Schedule the next call after 1 hour
					setTimeout(fetchNodes, RETRY_INTERVAL)
				})
		}

		fetchNodes()
	}

	const handleInitializeBlocks = (blocks: Block[]) => {
		const latestBlock = blocks[blocks.length - 1]
		console.info(`[mempool ws] initialized at block ${latestBlock.height}`)

		setBlock(latestBlock)
		setScrubBlock(latestBlock)
		handleGetPastBlocks()
		handleGetNodes()
		// handleGetBlockTxs(latestBlock.id)
	}

	const handleSetBlock = (block: Block) => {
		console.info(`[mempool ws] new block ${block.height}`)
		setBlock(block)
		if (!staticMode) {
			setScrubBlock(block)
		}
		setShowBlockDrop(true)
		setShowBlockRipple(true)
		// handleGetBlockTxs(block.id)
	}

	const handleSetDa = (da: DA) => {
		const {
			estimatedRetargetDate,
			difficultyChange,
			previousRetarget,
			previousTime,
			remainingBlocks,
			timeAvg,
		} = da

		setRetargetDate(estimatedRetargetDate)
		setDiffChange(difficultyChange)
		setRemBlocks(remainingBlocks)
		setPrevRetarget(previousRetarget)
		setPrevTime(previousTime)
		setTimeAv(timeAvg)
	}

	const handleSetMempoolInfo = (mempoolInfo: MempoolInfo) => {
		const {
			maxmempool,
			mempoolminfee,
			size,
			usage,
		} = mempoolInfo

		setMaxMem(maxmempool)
		setMinFee(mempoolminfee)
		setPoolSize(size)
		setPoolUsage(usage)
	}

	const handleSetFees = (fees: Fees) => {
		const {
			economyFee,
			fastestFee,
		} = fees

		setEconFee(economyFee)
		setFastFee(fastestFee)
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSetMempoolBlocks = (mempoolBlocks: any[]) => {
		let newMempoolBlocks = 1
		if (mempoolBlocks.length > 7) {
			const mpBlocksMinusLast = mempoolBlocks.length - 1
			const mpLastBlock = mempoolBlocks[mempoolBlocks.length - 1]
			const mpLastBlockCount = mpLastBlock.blockVSize / 1000
			newMempoolBlocks = Math.round((mpBlocksMinusLast + (mpLastBlockCount / 1000)))
		} else {
			newMempoolBlocks = mempoolBlocks.length
		}

		setPoolBlocks(newMempoolBlocks)
	}

	// const handleGetBlockTxs = (hash: string) => {
	// 	const blockchainInfoRequest = `${BLOCKCHAIN_INFO_BASE_URL}/rawblock/${hash}?format=json`
	// 	const proxyRequest = `${CORS_PROXY_BASE_URL}?${encodeURIComponent(blockchainInfoRequest)}`

	// 	// TODO: Add interval retry for new block txs
	// 	axios.get(proxyRequest)
	// 		.then((res) => {
	// 			setBlockTxs(res.data.tx)
	// 		})
	// 		.catch(error => {
	// 			console.error('[24h blocks] error:', error)
	// 		})
	// }

	// STATE CHANGE EFFECTS
	useEffect(() => {
		if (format(new Date(prevRetargetDate), 'yyyy-MM-dd') !== format(new Date(retargetDate), 'yyyy-MM-dd')) {
			setEstimatedRetagetDate(retargetDate)
		}
	}, [prevRetargetDate, retargetDate])
	useEffect(() => {
		if (prevDiffChange === null || parseFloat(prevDiffChange.toFixed(2)) !== parseFloat(diffChange.toFixed(2))) {
			setDifficultyChange(diffChange)
		}
	}, [prevDiffChange, diffChange])
	useEffect(() => {
		if (prevRemBlocks !== remBlocks) {
			setRemainingBlocks(remBlocks)
		}
	}, [prevRemBlocks, remBlocks])
	useEffect(() => {
		if (prevPrevRetarget !== prevRetarget) {
			setPreviousRetarget(prevRetarget)
		}
	}, [prevPrevRetarget, prevRetarget])
	useEffect(() => {
		if (prevPrevTime !== prevTime) {
			setPreviousTime(prevTime)
		}
	}, [prevPrevTime, prevTime])
	useEffect(() => {
		if (prevTimeAv !== timeAv) {
			setTimeAvg(timeAv)
		}
	}, [prevTimeAv, timeAv])
	useEffect(() => {
		if (prevMaxMem !== maxMem) {
			setMaxMempool(maxMem)
		}
	}, [prevMaxMem, maxMem])
	useEffect(() => {
		if (prevMinFee !== minFee) {
			setMempoolMinFee(minFee)
		}
	}, [prevMinFee, minFee])
	useEffect(() => {
		if (prevPoolSize !== poolSize) {
			setMempoolSize(poolSize)
		}
	}, [prevPoolSize, poolSize])
	useEffect(() => {
		if (abbreviateMempoolSize(prevPoolUsage) !== abbreviateMempoolSize(poolUsage)) {
			setMempoolUsage(poolUsage)
		}
	}, [prevPoolUsage, poolUsage])
	useEffect(() => {
		if (prevPoolBlocks !== poolBlocks) {
			setMempoolBlocks(poolBlocks)
		}
	}, [prevPoolBlocks, poolBlocks])
	useEffect(() => {
		if (prevInflow !== inflow) {
			setTxVBytesPerSecond(inflow)
		}
	}, [prevInflow, inflow])
	useEffect(() => {
		if (prevEconFee !== econFee) {
			setEconomyFee(econFee)
		}
	}, [prevEconFee, econFee])
	useEffect(() => {
		if (prevFastFee !== fastFee) {
			setFastestFee(fastFee)
		}
	}, [prevFastFee, fastFee])

	// DEFAULT COLOR MODE
	useEffect(() => {
		if (!localStorage.getItem('chakra-ui-color-mode-default')) {
			localStorage.setItem('chakra-ui-color-mode', 'dark')
			localStorage.setItem('chakra-ui-color-mode-default', 'set')
		}
	}, [])

	// SET APP INITIALIZATION START TIME
	useEffect(() => {
		const now = new Date()
		setAppInitStartTime(now)
	}, [])

	// DEFAULT FULL BLOCK RIPPLE
	useEffect(() => {
		if (fullNewBlockRipple === undefined) {
			dispatch(setFullNewBlockRipple(true))
		}
	}, [])

	// URL PATHS
	useEffect(() => {
		const { pathname } = location

		if (pathname.includes('conference')) {
			console.info('[pathname] conference')
			setConfMode(true)
		}
	}, [location])

	// URL PARAMS
	useEffect(() => {
		if (searchParams.get('img_url')) {
			const imgUrlString = searchParams.get('img_url')
			const imgUrl = imgUrlString ? decodeURI(imgUrlString) : ''
			if (imgUrl) {
				setConfImg(imgUrl)
			}
		} else {
			setConfImg('')
		}

		if (searchParams.get('img_top')) {
			const imgTop = searchParams.get('img_top')
			if (imgTop) {
				setConfImgTop(Number(imgTop))
			}
		} else {
			setConfImgTop(0)
		}

		if (searchParams.get('upper_message')) {
			const messageString = searchParams.get('upper_message')
			const message = messageString ? decodeURI(messageString) : ''
			if (message) {
				setUpperMessage(message)
			}
		} else {
			setUpperMessage('')
		}

		if (searchParams.get('lower_message')) {
			const messageString = searchParams.get('lower_message')
			const message = messageString ? decodeURI(messageString) : ''
			if (message) {
				setLowerMessage(message)
			}
		} else {
			setLowerMessage('')
		}
	}, [searchParams])

	// 24H BLOCKS
	useEffect(() => {
		const newBlockCondition = pastBlocks.length > 0
			&& block.height !== pastBlocks[pastBlocks.length - 1]?.height
		if (newBlockCondition) {
			console.info(`[24h blocks] add new block ${block.height}`)
			const newSimpleBlock = {
				height: block.height,
				id: block.id,
				timestamp: block.timestamp,
			}
			setPastBlocks((prevPastBlocks) => [...prevPastBlocks, newSimpleBlock])
		}
	}, [
		block,
		pastBlocks,
	])

	// HASHRATE
	useEffect(() => {
		const now = new Date()
		// not initialized or more than 1 hour since last time called
		const shouldFetchHashrates = Boolean(!hashrateInit || differenceInHours(now, lastHashrateCall) > 0)

		const handleGetHashrateAndDifficulty = () => {
			console.info('[hashrates] fetch')
			axios.get(`${MEMPOOL_SPACE_API_V1_URL}/mining/hashrate/1y`)
				.then((res) => {
					const {
						currentHashrate,
						currentDifficulty,
						hashrates,
						difficulty,
					} = res.data
					const { length } = hashrates
					const yesterdayHashrate = hashrates[length - 1].avgHashrate
					const hashrateAverages = getHashrateAverages(hashrates, currentHashrate)
					const {
						sevenDayAverage,
						yesterdaySevenDayAverage,
						twoWeekAverage,
						yesterdayTwoWeekAverage,
						monthAverage,
						yesterdayMonthAverage,
						threeMonthAverage,
						yesterdayThreeMonthAverage,
					} = hashrateAverages
					const prevDiff = difficulty[difficulty.length - 1].time

					setHashrateAverages({
						current: currentHashrate,
						yesterday: yesterdayHashrate,
						sevenDay: sevenDayAverage,
						yesterdaySevenDay: yesterdaySevenDayAverage,
						twoWeek: twoWeekAverage,
						yesterdayTwoWeek: yesterdayTwoWeekAverage,
						month: monthAverage,
						yesterdayMonth: yesterdayMonthAverage,
						threeMonth: threeMonthAverage,
						yesterdayThreeMonth: yesterdayThreeMonthAverage,
					})

					if (currenDiff !== currentDifficulty) {
						setCurrentDifficulty(currentDifficulty)
					}

					if (prevDiff !== prevDiffDate) {
						setPrevDiffDate(prevDiff)
					}

					if (!hashrateInit) {
						setHashrateInit(true)
					}

					setLastHashrateCall(now)
				})
		}

		if (shouldFetchHashrates) {
			handleGetHashrateAndDifficulty()
		}
	}, [block])

	// CHAINSTATE
	useEffect(() => {
		// not initialized or more than 18 hours since last time called
		const shouldFetchChainstate = Boolean(!chainstateInit || differenceInHours(now, lastChainstateCall) > 17)

		const handleGetChainstate = () => {
			const API_ENDPOINT = `${TIMECHAININDEX_API_URL}/chainstate/summary`
	
			const fetchChainstate = () => {
				axios.get(API_ENDPOINT)
					.then((res) => {
						const { data } = res
						const {
							height,
							totaladdresses,
							totalutxos,
							totalvb,
							totaldisksize,
							totaltxnscount,
						} = data[0]
	
						console.info('[chainstate] success')
						setChainHeight(height)
						setTotalAdresses(totaladdresses)
						setTotalUtxos(totalutxos)
						setTotalVb(totalvb)
						setTotalDisk(totaldisksize)
						setTotalTxnsCount(totaltxnscount)

						if (!chainstateInit) {
							setChainstateInit(true)
						}

						setLastChainstateCall(now)
					})
					.catch(error => {
						console.error('[chainstate] error:', error)
					})
			}
			
			console.info('[chainstate] fetching')
			fetchChainstate()
		}

		if (shouldFetchChainstate) {
			handleGetChainstate()
		}
	}, [block])

	// MEMPOOL WEBSOCKET
	useEffect(() => {
		const {
			bitcoin: { websocket },
		} = mempoolJS()

		const ws = websocket.initClient({
			options: [
				'blocks',
				'stats',
				'mempool-blocks',
				'live-2h-chart',
			],
		})

		const onWSOpen = () => {
			ws.send(JSON.stringify({ action: 'init' }))
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const onWSClose = (event: any) => {
			console.error('[mempool ws] CLOSED', event)
			setConnection({ ...ws, readyState: 3 } as WebSocket)
			// TODO: reconnect automatically?
			setTimeout(() => {
				setReconnecting(true)
				setConnection(null)
			}, 3000)
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const onWSMessage = function incoming({ data }: any) {
			const res = JSON.parse(data.toString())
			const {
				blocks,
				block,
				vBytesPerSecond,
				da,
				fees,
				mempoolInfo,
			} = res
			const mempoolBlocks = res['mempool-blocks']

			if (blocks) {
				handleInitializeBlocks(blocks)
			}
			if (block) {
				handleSetBlock(block)
			}
			if (da) {
				handleSetDa(da)
			}
			if (mempoolInfo) {
				handleSetMempoolInfo(mempoolInfo)
			}
			if (fees) {
				handleSetFees(fees)
			}
			if (vBytesPerSecond && vBytesPerSecond !== undefined) {
				setInflow(vBytesPerSecond)
			}
			if (mempoolBlocks && mempoolBlocks.length) {
				handleSetMempoolBlocks(mempoolBlocks)
			}
		}

		if (connection === null) {
			const reconnectString = reconnecting ? '(reconnecting...)' : ''
			console.info(`[mempool ws] initialize ${reconnectString}`)
			ws.addEventListener('open', onWSOpen)
			ws.addEventListener('message', onWSMessage)
			ws.addEventListener('onclose', onWSClose)

			setConnection(ws)
			if (reconnecting) {
				setReconnecting(false)
			}
		}

		return () => {
			console.info('[mempool ws] cleanup')
			ws.removeEventListener('open', onWSOpen)
			ws.removeEventListener('message', onWSMessage)
			ws.removeEventListener('onclose', onWSClose)
			connection?.close()
		}
	}, [])

	return null
}
