export const DATA_LAST_BLOCK_FEES = 'lastBlockFees'
const DATA_TITLE_LAST_BLOCK_FEES = 'data.last_block_fees.title'
const DATA_TITLE_FULL_LAST_BLOCK_FEES = 'data.last_block_fees.title_full'

export const DATA_LAST_BLOCK_REWARD = 'lastBlockReward'
const DATA_TITLE_LAST_BLOCK_REWARD = 'data.last_block_reward.title'
const DATA_TITLE_FULL_LAST_BLOCK_REWARD = 'data.last_block_reward.title_full'

export const DATA_NEXT_HALVING = 'nextHalving'
const DATA_TITLE_NEXT_HALVING = 'data.next_halving.title'
const DATA_TITLE_FULL_NEXT_HALVING = 'data.next_halving.title_full'

export const DATA_NEXT_DIFF_ADJ = 'nextDiffAdj'
const DATA_TITLE_NEXT_DIFF_ADJ = 'data.next_diff_adj.title'
const DATA_TITLE_FULL_NEXT_DIFF_ADJ = 'data.next_diff_adj.title_full'

export const DATA_PREVIOUS_DIFF_ADJ = 'prevDiffAdj'
const DATA_TITLE_PREVIOUS_DIFF_ADJ = 'data.prev_diff_adj.title'
const DATA_TITLE_FULL_PREVIOUS_DIFF_ADJ = 'data.prev_diff_adj.title_full'

export const DATA_CURRENT_DIFFICULTY = 'currentDiff'
const DATA_TITLE_CURRENT_DIFFICULTY = 'data.difficulty.title'
const DATA_TITLE_FULL_CURRENT_DIFFICULTY = 'data.difficulty.title_full'

export const DATA_BLOCK_TIME = 'blockTime'
const DATA_TITLE_BLOCK_TIME = 'data.block_time.title'
const DATA_TITLE_FULL_BLOCK_TIME = 'data.block_time.title_full'

export const DATA_NETWORK_HASHRATE = 'hashrate'
const DATA_TITLE_NETWORK_HASHRATE = 'data.hashrate.title'
const DATA_TITLE_FULL_NETWORK_HASHRATE = 'data.hashrate.title_full'

export const DATA_REACHABLE_NODES = 'nodes'
const DATA_TITLE_REACHABLE_NODES = 'data.nodes.title'
const DATA_TITLE_FULL_REACHABLE_NODES = 'data.nodes.title_full'

export const DATA_LIGHTNING_CAPACITY = 'lightningCapacity'
const DATA_TITLE_LIGHTNING_CAPACITY = 'data.lightning_network.title'
const DATA_TITLE_FULL_LIGHTNING_CAPACITY = 'data.lightning_network.title_full'

export type Data = {
	id: string
	title: string
	fullTitle: string
}

export const DATA_OPTIONS = [
	{
		id: DATA_BLOCK_TIME,
		title: DATA_TITLE_BLOCK_TIME,
		fullTitle: DATA_TITLE_FULL_BLOCK_TIME,
	},
	{
		id: DATA_NEXT_DIFF_ADJ,
		title: DATA_TITLE_NEXT_DIFF_ADJ,
		fullTitle: DATA_TITLE_FULL_NEXT_DIFF_ADJ,
	},
	{
		id: DATA_PREVIOUS_DIFF_ADJ,
		title: DATA_TITLE_PREVIOUS_DIFF_ADJ,
		fullTitle: DATA_TITLE_FULL_PREVIOUS_DIFF_ADJ,
	},
	{
		id: DATA_CURRENT_DIFFICULTY,
		title: DATA_TITLE_CURRENT_DIFFICULTY,
		fullTitle: DATA_TITLE_FULL_CURRENT_DIFFICULTY,
	},
	{
		id: DATA_NETWORK_HASHRATE,
		title: DATA_TITLE_NETWORK_HASHRATE,
		fullTitle: DATA_TITLE_FULL_NETWORK_HASHRATE,
	},
	{
		id: DATA_NEXT_HALVING,
		title: DATA_TITLE_NEXT_HALVING,
		fullTitle: DATA_TITLE_FULL_NEXT_HALVING,
	},
	{
		id: DATA_LAST_BLOCK_FEES,
		title: DATA_TITLE_LAST_BLOCK_FEES,
		fullTitle: DATA_TITLE_FULL_LAST_BLOCK_FEES,
	},
	{
		id: DATA_LAST_BLOCK_REWARD,
		title: DATA_TITLE_LAST_BLOCK_REWARD,
		fullTitle: DATA_TITLE_FULL_LAST_BLOCK_REWARD,
	},
	{
		id: DATA_REACHABLE_NODES,
		title: DATA_TITLE_REACHABLE_NODES,
		fullTitle: DATA_TITLE_FULL_REACHABLE_NODES,
	},
	{
		id: DATA_LIGHTNING_CAPACITY,
		title: DATA_TITLE_LIGHTNING_CAPACITY,
		fullTitle: DATA_TITLE_FULL_LIGHTNING_CAPACITY,
	},
]
