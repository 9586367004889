import type { MouseEventHandler } from 'react'
import {
	Box,
	Button,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { BITCOIN_ORANGE, menuLabelFontSize } from '../../../constants'
import { getBreakpointValue } from '../../../utils'

interface InfoButtonProps {
	onClick: MouseEventHandler<HTMLDivElement>
}

export const TCInfoButton = ({ onClick, ...rest }: InfoButtonProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const color = useColorModeValue('black', 'white')
	const bg = useColorModeValue('#eee', 'black')
	const interactionStyle = {
		color: BITCOIN_ORANGE,
	}
	const responsiveButtonLabelSize = getBreakpointValue(menuLabelFontSize, circlesBreakpoint)
	const buttonHeight = getBreakpointValue({ base: '30px', md: '38px' }, circlesBreakpoint)
	const buttonLineHeight = getBreakpointValue({ base: '26px', md: '36px' }, circlesBreakpoint)
	const buttonWrapFlex = getBreakpointValue({ base: '1 1 50%', md: '1 1 20%' }, circlesBreakpoint)

	return (
		<Box
			className="tc-info-button-wrap"
			flex={buttonWrapFlex}
			onClick={onClick}
			cursor="pointer"
			role="group"
			pos="relative"
			zIndex={1015}
			{...rest}
		>
			<Button
				w="100%"
				size="sm"
				variant="outline"
				bg={bg}
				fontSize={responsiveButtonLabelSize}
				fontWeight="bold"
				height={buttonHeight}
				lineHeight={buttonLineHeight}
				textTransform="uppercase"
				py="0"
				px="12px"
				color={color}
				borderColor={color}
				borderWidth={0}
				borderRadius="md"
				_active={interactionStyle}
				_hover={interactionStyle}
				_groupHover={interactionStyle}
			>
				<FormattedMessage id="info.button_label" />
			</Button>
		</Box>
	)
}