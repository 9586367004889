import {
	Box,
	Text,
	Spinner,
	useColorModeValue,
} from '@chakra-ui/react'
import format from 'date-fns/format'
import { useRecoilValue } from 'recoil'

import {
	estimatedRetargetDateState,
	difficultyChangeState,
	circlesBreakpointState,
	remainingBlocksState,
} from '../../../../state'
import { useAppSelector } from '../../../../hooks'
import { dateLocales } from '../../../../lang/dateFnsLocales'
import { Label } from '../../../shared'
import {
	positiveInt,
	getDateFormat,
	getBreakpointValue,
} from '../../../../utils'
import { primaryDataFontSize } from './dataComponents.constants'

const NextDiffAdj = () => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const remainingBlocks = useRecoilValue(remainingBlocksState)
	const difficultyChange = useRecoilValue(difficultyChangeState)

	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const color = useColorModeValue('black', 'white')

	const nextChangeEst = difficultyChange ? parseFloat(difficultyChange.toFixed(2)) : 0
	const shouldWait = remainingBlocks > 2000
	
	return (
		<>
			<Text
				lineHeight={fontSize}
				my={1}
				display="flex"
				color={color}
				fontSize={fontSize}
				fontWeight="semibold"
				gap="2px"
			>
				{nextChangeEst > 0 && !shouldWait && (
					<span>&uarr;</span>
				)}
				{nextChangeEst < 0 && !shouldWait && (
					<span>&darr;</span>
				)}
				{nextChangeEst !== 0 && !shouldWait && `${positiveInt(nextChangeEst)}%`}
				{nextChangeEst === 0 && !shouldWait && '-'}
			</Text>

			{shouldWait && (
				<Box fontSize={fontSize} fontWeight="bold">
					&nbsp;<Spinner size="sm" speed="1s" color="gray" />&nbsp;
				</Box>
			)}
		</>
	)
}

const NextDiffEstDate = () => {
	const { dateFormat, userLocale } = useAppSelector(({ settings }) => settings)
	const estimatedRetargetDate = useRecoilValue(estimatedRetargetDateState)

	const labelColor = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255, 0.7)')

	const estDate = estimatedRetargetDate ? new Date(estimatedRetargetDate) : null
	const formattedEstDate = estDate
		? format(estDate, getDateFormat(dateFormat), { locale: dateLocales[userLocale] })
		: ''

	return (
		<>
			{estDate && (
				<Label color={labelColor} fontWeight="semibold">
					~ {formattedEstDate}
				</Label>
			)}
		</>
	)
}

export const DataNextDiffAdj = () => {
	return (
		<>
			<Label>
				NEXT ADJUST
			</Label>

			<NextDiffAdj />

			<NextDiffEstDate />
		</>
	)
}
