export const getRotationAngle = (angleRatio: number) => angleRatio * 360

export type CircleBreakpointsStateKey = 'base' | 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'jumbo'

export type CircleBreakpointsStateValue = string | number
// eslint-disable-next-line no-unused-vars
type CircleBreakpointsStateObject = { [K in CircleBreakpointsStateKey]?: CircleBreakpointsStateValue }

export const getBreakpointValue = (obj: CircleBreakpointsStateObject, key: CircleBreakpointsStateKey): CircleBreakpointsStateValue | undefined => {
	const orderedKeys: CircleBreakpointsStateKey[] = ['base', 'xxxs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'jumbo']
	const keyIndex = orderedKeys.indexOf(key)

	for (let i = keyIndex; i >= 0; i--) {
		if (orderedKeys[i] in obj) {
			return obj[orderedKeys[i]]
		}
	}

	return undefined
}
