import {
	useEffect,
	useState,
	useMemo,
	type ChangeEvent,
} from 'react'
import {
	Flex,
	Box,
	Text,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	Image,
	type ResponsiveValue,
	type FlexProps,
	Select,
	useColorModeValue,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { FormattedMessage } from 'react-intl'
import LazyLoad from 'react-lazy-load'
import { useLocation, useNavigate } from 'react-router-dom'

import { blockState, circlesBreakpointState, landscapeOrientationState } from '../../../state'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import {
	BLOCK_EMBEDS,
	BITCOIN_ORANGE,
	CATEGORY_COLORS,
	type BlockEmbed,
	GalleryFilter,
} from '../../../constants'
import { TCGalleryButton } from './TCGalleryButton'
import { rtlLocales } from '../../../lang/messages'
import { GalleryIcon } from '../../svg'
import {
	getBreakpointValue,
	groupByProperty,
	sortByProperty,
	collectUniqueValues,
} from '../../../utils'
import { Label, ModalOverlay } from '../../shared'
import { setUserGalleryFilter } from '../settings/settingsReducer'

interface GalleryCategoryLabelProps {
	label: string
}

interface TCGalleryProps {
	// eslint-disable-next-line no-unused-vars
	onSearchBlock: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	onScrubChangeEnd: (x: number) => void
	// eslint-disable-next-line no-unused-vars
	setScrubValue: (x: number) => void
}

interface GalleryFilterOptionProps {
	label: string
	value: string
}

type TCGalleryCombinedProps = TCGalleryProps & FlexProps

export const GalleryCategoryLabel = ({ label, ...rest }: GalleryCategoryLabelProps) => {
	const bgColor = CATEGORY_COLORS[label]

	return (
		<Box
			fontSize="xxs"
			fontWeight="bold"
			textTransform="uppercase"
			textAlign="center"
			color="white"
			borderRadius="20px"
			bgColor={bgColor}
			px={3}
			{...rest}
		>
			{label}
		</Box>
	)
}

const GalleryFilterOption = ({ label, value }: GalleryFilterOptionProps) => {
	return (
		<option value={value}>{label}</option>
	)
}

export const TCGallery = ({
	onSearchBlock,
	onScrubChangeEnd,
	setScrubValue,
	...rest
}: TCGalleryCombinedProps) => {
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useAppDispatch()
	const { userLocale, userGalleryFilter } = useAppSelector((state) => state.settings)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const block = useRecoilValue(blockState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const [filteredEmbeds, setFilteredEmbeds] = useState<BlockEmbed[]>([])
	
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const galleryThumbSize = getBreakpointValue({ base: 82, md: 98 }, circlesBreakpoint)
	const galleryListThumbSize = getBreakpointValue({ base: 60, md: 80 }, circlesBreakpoint)
	const blockFontSize = getBreakpointValue({ base: '14px', md: '15px' }, circlesBreakpoint)
	const galleryArtistHeaderDirection = getBreakpointValue({ base: 'column', md: 'row' }, circlesBreakpoint) as ResponsiveValue<'row' | 'column'>

	const groupedEmbeds = useMemo(
		() => groupByProperty(BLOCK_EMBEDS, 'attribution'),
		[],
	)
	const groupedDescEmbeds = groupedEmbeds.slice().reverse()
	const listedEmbeds = useMemo(
		() => sortByProperty(BLOCK_EMBEDS, 'block') as BlockEmbed[],
		[],
	)
	const listedDescEmbeds = listedEmbeds.slice().reverse()

	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}
	const galleryThumbJustify = landscapeOrientation ? undefined : 'center'
	const galleryThumbSizeBorderString = `${Number(galleryThumbSize) + 2}px`
	const galleryListThumbSizeBorderString = `${Number(galleryListThumbSize) + 2}px`
	const isGroupedByArtist = filteredEmbeds.length > 0
		&& (userGalleryFilter === 'ASC' || userGalleryFilter === 'DESC')
	const isBlockList = filteredEmbeds.length > 0
		&& (userGalleryFilter === 'BLOCK_ASC' || userGalleryFilter === 'BLOCK_DESC')

	const handleOpenGallery = () => {
		navigate(`/${userLocale}/gallery`)
	}

	const handleCloseGallery = () => {
		onClose()
		onScrubChangeEnd(block.height)
		setScrubValue(block.height)
	}

	const handleGoToEmbedBlock = (embedBlock: number) => {
		if (embedBlock < block.height) onSearchBlock(embedBlock)
		onClose()
	}

	const galleryFilterChange = (filter: GalleryFilter, manual = false) => {
		if (manual) {
			dispatch(setUserGalleryFilter(filter))
		}
		switch(filter) {
			case 'ASC':
				setFilteredEmbeds(groupedEmbeds)
				break
			case 'DESC':
				setFilteredEmbeds(groupedDescEmbeds)
				break
			case 'BLOCK_ASC':
				setFilteredEmbeds(listedEmbeds)
				break
			case 'BLOCK_DESC':
				setFilteredEmbeds(listedDescEmbeds)
				break
			default:
				setFilteredEmbeds(groupedEmbeds)
		}
	}

	const handleGalleryFilter = (x: ChangeEvent<HTMLSelectElement>) => {
		const newFilter = x.target.value as GalleryFilter
		galleryFilterChange(newFilter, true)
	}

	useEffect(() => {
		if (location.pathname.includes('/gallery')) {
			onOpen()
		}
	}, [location])

	useEffect(() => {
		if (!userGalleryFilter) {
			galleryFilterChange('ASC')
			dispatch(setUserGalleryFilter('ASC'))
		} else {
			galleryFilterChange(userGalleryFilter)
		}
	}, [])

	return (
		<>
			<TCGalleryButton
				onClick={handleOpenGallery}
				{...rest}
			/>

			<Modal
				key="gallery"
				isOpen={isOpen}
				onClose={handleCloseGallery}
				isCentered
				motionPreset="slideInBottom"
				size="3xl"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					color={color}
					borderRadius="lg"
					zIndex={3334}
					dir={direction}
				>
					<ModalHeader pb={0}>
						<Flex justify="space-between">
							<Flex
								align="center"
								gap="1"
								color={colorAlt}
							>
								<GalleryIcon width={20} />

								<Text
									fontSize="xs"
									textTransform="uppercase"
								>
									GALLERY
								</Text>
							</Flex>
							
							<Flex
								align="center"
								gap={{ base: 2, md: 3 }}
								color={BITCOIN_ORANGE}
							>
								<Box pt={1}>
									<Label>Sort:</Label>
								</Box>

								<Select
									size={{ base: 'xs', md: 'sm' }}
									onChange={handleGalleryFilter}
									value={userGalleryFilter}
									borderColor={colorAlt}
								>
									<GalleryFilterOption
										label="Artist/Author (ASC)"
										value="ASC"
									/>
									<GalleryFilterOption
										label="Artist/Author (DESC)"
										value="DESC"
									/>
									<GalleryFilterOption
										label="Block (ASC)"
										value="BLOCK_ASC"
									/>
									<GalleryFilterOption
										label="Block (DESC)"
										value="BLOCK_DESC"
									/>
								</Select>
							</Flex>
						</Flex>
					</ModalHeader>

					<ModalBody mt={4}>
						<Flex
							direction="column"
							maxH="500px"
							overflowY="auto"
						>
							{
								isOpen
								&& isGroupedByArtist
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								&& filteredEmbeds.map((group: any) => {
									const { key, values } = group
									const artistCats = collectUniqueValues(values, 'cats')

									return (
										<Flex
											key={key}
											direction="column"
											borderWidth={1}
											borderColor="whiteAlpha.500"
											borderRadius="md"
											bg="whiteAlpha.100"
											p={4}
											pt={2}
											mb={10}
										>
											<Flex
												flexDirection={galleryArtistHeaderDirection}
												align="center"
												justify="space-between"
												mb={4}
											>
												<Text
													fontWeight="bold"
													my={2}
												>
													{key}
												</Text>

												<Flex gap={2}>
													{artistCats.map((cat) => {
														return (
															<GalleryCategoryLabel key={cat} label={cat} />
														)
													})}
												</Flex>
											</Flex>

											<Flex
												justify={galleryThumbJustify}
												wrap="wrap"
												gap={4}
											>
												{values.map((embed: BlockEmbed, index: number) => {
													const { block, title, gThumbUrl } = embed
													return (
														<Flex
															key={index}
															onClick={() => handleGoToEmbedBlock(block)}
															direction="column"
															role="group"
															cursor="pointer"
														>
															<Text
																fontSize={blockFontSize}
																lineHeight="none"
																mb={1}
																transition="all 0.45s ease"
																_groupHover={{
																	color: BITCOIN_ORANGE,
																}}
															>
																{block}
															</Text>

															<Box
																w={galleryThumbSizeBorderString}
																h={galleryThumbSizeBorderString}
																borderRadius="md"
																borderWidth={1}
																transition="all 0.45s ease"
																_groupHover={{
																	borderColor: BITCOIN_ORANGE,
																}}
															>
																<LazyLoad height={galleryThumbSize} offset={400}>
																	<Image
																		title={title}
																		flex={0}
																		boxSize={`${galleryThumbSize}px`}
																		htmlHeight={`${galleryThumbSize}px`}
																		htmlWidth={`${galleryThumbSize}px`}
																		src={gThumbUrl}
																		objectFit="cover"
																		borderRadius="md"
																	/>
																</LazyLoad>
															</Box>
														</Flex>
													)

												})}
											</Flex>
										</Flex>
									)
								})
							}

							{
								isOpen
								&& isBlockList
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								&& (
									<Flex direction="column">
										{filteredEmbeds.map((embed: BlockEmbed, index: number) => {
											const {
												block,
												title,
												gThumbUrl,
												attribution,
											} = embed

											return (
												<Flex
													key={index}
													align="center"
													onClick={() => handleGoToEmbedBlock(block)}
													role="group"
													cursor="pointer"
													gap={4}
													mb={2}
													p={2}
													borderWidth={1}
													borderColor="whiteAlpha.500"
													borderRadius="md"
													_hover={{
														borderColor: BITCOIN_ORANGE,
													}}
												>
													<Text
														w={{ base: '18%', md: '14%' }}
														fontSize={blockFontSize}
														lineHeight="none"
														transition="all 0.45s ease"
														_groupHover={{
															color: BITCOIN_ORANGE,
														}}
													>
														{block}
													</Text>
													
													<Box w={{ base: '20%', md: '15%' }}>
														<Box
															w={galleryListThumbSizeBorderString}
															h={galleryListThumbSizeBorderString}
															borderRadius="md"
															borderWidth={1}
															transition="all 0.45s ease"
															_groupHover={{
																borderColor: BITCOIN_ORANGE,
															}}
														>
															<LazyLoad height={galleryListThumbSize} offset={400}>
																<Image
																	title={title}
																	flex={0}
																	boxSize={`${galleryListThumbSize}px`}
																	htmlHeight={`${galleryListThumbSize}px`}
																	htmlWidth={`${galleryListThumbSize}px`}
																	src={gThumbUrl}
																	objectFit="cover"
																	borderRadius="md"
																/>
															</LazyLoad>
														</Box>
													</Box>

													<Text
														flexGrow={1}
														fontSize={blockFontSize}
														lineHeight="none"
														fontWeight="bold"
														transition="all 0.45s ease"
														_groupHover={{
															color: BITCOIN_ORANGE,
														}}
													>
														{attribution}
													</Text>
												</Flex>
											)
										})}
									</Flex>
								)
							}
						</Flex>
					</ModalBody>

					<ModalFooter mt={2}>
						<Flex
							w="100%"
							justify="space-between"
							align="center"
						>
							<Text
								color="whiteAlpha.500"
								fontSize="xs"
								textTransform="uppercase"
							>
								TOTAL: {BLOCK_EMBEDS.length} items
							</Text>

							<Button
								onClick={handleCloseGallery}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<FormattedMessage id="shared.close_button_label" />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
