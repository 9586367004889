export const HEADER_FOOTER_HEIGHT = 40
export const CIRCLE_SQUARE_MAX = 1200
export const MIN_LANDSCAPE_CIRCLE_HEIGHT = 240

export const CIRCLE_BREAKS = {
	base: 0,
	xxxs: 200,
	xxs: 300,
	xs: 400,
	sm: 500,
	md: 600,
	lg: 660,
	xl: 760,
	xxl: 840,
	xxxl: 900,
	jumbo: 1050,
}

export const PADDING_BREAKPOINTS = {
	base: '10px',
	xs: '16px',
	xl: '30px',
	xxl: '50px',
}
export const PADDING_BREAKPOINTS_PORTRAIT = {
	base: '10px',
	xs: '16px',
	xl: '20px',
}
