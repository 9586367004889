import {
	useEffect,
	useState,
} from 'react'
import {
	Flex,
	Text,
	Spinner,
	useColorModeValue,
} from '@chakra-ui/react'
import format from 'date-fns/format'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../hooks'
import {
	scrubBlockState,
	scrubbingState,
	staticModeState,
	circlesBreakpointState,
	showBlockRippleState,
} from '../../../state'
import { getTimeFormat, getAmPm, getBreakpointValue } from '../../../utils'
import { BITCOIN_ORANGE, blurInAnimation } from '../../../constants'

export interface CornerSWProps {
	// scrubBlock: Block
	onOpenDatePickerModal: () => void
}

export const CornerSW = ({
	// scrubBlock,
	onOpenDatePickerModal,
}: CornerSWProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const { timeFormat } = useAppSelector((state: any) => state.settings)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const scrubbing = useRecoilValue(scrubbingState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const [theTime, setTheTime] = useState(new Date())

	const scrubDate = new Date(scrubBlock.timestamp * 1000)
	const opacity = showBlockRipple ? 0.21 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'
	const color = useColorModeValue('black', 'white')
	const color2 = useColorModeValue('rgba(0,0,0,0.7)', 'whiteAlpha.700')

	const timeSize = getBreakpointValue({
		base: '24px',
		xxs: '30px',
		xs: '34px',
		sm: '36px',
		md: '40px',
		lg: '48px',
		xl: '52px',
		xxl: '60px',
		xxxl: '64px',
		jumbo: '78px',
	}, circlesBreakpoint)
	const amPmFontSize = getBreakpointValue({
		base: 'xs',
		xs: '16px',
		sm: 'sm',
		md: 'md',
		lg: 'lg',
		xl: 'xl',
		xxl: '2xl',
		jumbo: '3xl',
	}, circlesBreakpoint)
	const amPmLineHeight = getBreakpointValue({
		base: '16px',
		xs: '19px',
		sm: '21px',
		md: '20px',
		lg: '24px',
		xl: '29px',
		xxl: '32px',
		jumbo: '40px',
	}, circlesBreakpoint)
	const cornerPb = getBreakpointValue({ base: 2, lg: 3 }, circlesBreakpoint)

	useEffect(() => {
		const timer = setInterval(() => {
			setTheTime(new Date())
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [])

	return (
		<Flex
			role="group"
			className="tc-corner-sw"
			pos="absolute"
			zIndex={2000}
			bottom={0}
			left={0}
			pb={cornerPb}
			onClick={onOpenDatePickerModal}
			direction="column"
			justify="flex-end"
			color={color}
			cursor="pointer"
			transition="all 0.21s ease"
			opacity={opacity}
			filter={filter}
			animation={blurInAnimation}
			_hover={{
				color: BITCOIN_ORANGE,
			}}
		>
			{staticMode && scrubbing && (
				<Spinner color={color} size="md" />
			)}
			<Flex align="flex-end">
				<Text
					fontSize={timeSize}
					fontWeight="semibold"
					lineHeight="none"
				>
					{staticMode && !scrubbing && (format(scrubDate, getTimeFormat(timeFormat)))}
					{!staticMode && format(theTime, getTimeFormat(timeFormat))}
				</Text>

				<Text
					color={color2}
					ml={1}
					fontSize={amPmFontSize}
					fontWeight="semibold"
					lineHeight={amPmLineHeight}
					transition="all 0.21s ease"
					_groupHover={{
						color: BITCOIN_ORANGE,
					}}
				>
					{staticMode && !scrubbing && getAmPm(scrubDate, timeFormat)}
					{!staticMode && getAmPm(theTime, timeFormat)}
				</Text>
			</Flex>
		</Flex>
	)
}
