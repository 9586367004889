import type { PropsWithChildren } from 'react'
import {
	Flex,
	Image,
	Modal,
	ModalContent,
	ModalFooter,
	ModalBody,
	Button,
	useDisclosure,
	useColorModeValue,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'

import type { UserLocale } from '../../../lang/messages'
import { setUserLocale } from '../settings/settingsReducer'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { CloseButtonLabel, Label, ModalOverlay } from '../../shared'
import { BITCOIN_ORANGE, menuLabelFontSize } from '../../../constants'
import { rtlLocales } from '../../../lang/messages'
import { getBreakpointValue } from '../../../utils'
import { circlesBreakpointState } from '../../../state'

interface LanguageButtonProps {
	onClick: () => void
}

type LanguageOptionsProps = PropsWithChildren & { value: string }

export const LanguageSetterButton = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const intl = useIntl()
	const navigate = useNavigate()
	// @ts-ignore
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const dispatch = useAppDispatch()
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255, 0.5)')
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const labelFontSize = getBreakpointValue(menuLabelFontSize, circlesBreakpoint)

	// NEW LOCALE UPDATE HERE
	const AR = intl.formatMessage({ id: 'i18n.aria_label.ar' }).toUpperCase()
	const CA = intl.formatMessage({ id: 'i18n.aria_label.ca' }).toUpperCase()
	const DE = intl.formatMessage({ id: 'i18n.aria_label.de' }).toUpperCase()
	const EN = intl.formatMessage({ id: 'i18n.aria_label.en' }).toUpperCase()
	const ES = intl.formatMessage({ id: 'i18n.aria_label.es' }).toUpperCase()
	const FR = intl.formatMessage({ id: 'i18n.aria_label.fr' }).toUpperCase()
	const GR = intl.formatMessage({ id: 'i18n.aria_label.gr' }).toUpperCase()
	const IT = intl.formatMessage({ id: 'i18n.aria_label.it' }).toUpperCase()
	const PL = intl.formatMessage({ id: 'i18n.aria_label.pl' }).toUpperCase()
	const PT = intl.formatMessage({ id: 'i18n.aria_label.pt' }).toUpperCase()
	const RU = intl.formatMessage({ id: 'i18n.aria_label.ru' }).toUpperCase()
	const SV = intl.formatMessage({ id: 'i18n.aria_label.sv' }).toUpperCase()
	const TR = intl.formatMessage({ id: 'i18n.aria_label.tr' }).toUpperCase()
	const ZH = intl.formatMessage({ id: 'i18n.aria_label.zh' }).toUpperCase()

	const interactionStyles = {
		borderColor: BITCOIN_ORANGE,
		color: BITCOIN_ORANGE,
	}

	const LANGUAGE_FLAG_MAP = {
		ar: '🇸🇦',
		ca: <Image
			src="/images/flags/catalan-current.png"
			htmlHeight="9px"
			htmlWidth="18px"
			mr={1}
		/>,
		de: '🇩🇪',
		en: '🇺🇸',
		es: '🇪🇸',
		fr: '🇫🇷',
		gr: '🇬🇷',
		it: '🇮🇹',
		pl: '🇵🇱',
		pt: '🇧🇷',
		ru: '🇷🇺',
		sv: '🇸🇪',
		tr: '🇹🇷',
		zh: '🇨🇳',
	}

	const LANGUAGE_LABEL_MAP = {
		ar: AR,
		ca: CA,
		de: DE,
		en: EN,
		es: ES,
		fr: FR,
		gr: GR,
		it: IT,
		pl: PL,
		pt: PT,
		ru: RU,
		sv: SV,
		tr: TR,
		zh: ZH,
	}

	const handleLocaleChange = (newValue: UserLocale) => {
		dispatch(setUserLocale(newValue))
		navigate(`/${newValue}`)
	}

	const LanguageButton = ({ onClick }: LanguageButtonProps) => {
		const flag = LANGUAGE_FLAG_MAP[userLocale]
		const label = LANGUAGE_LABEL_MAP[userLocale]
		return (
			<Button
				onClick={onClick}
				variant="unstyled"
				size="sm"
				role="group"
				aria-label={intl.formatMessage({ id: 'settings.locale.language' })}
			>
				<Flex
					align="center"
					gap={1}
				>
					{flag}

					<Label
						fontWeight="bold"
						mr="4px"
						fontSize={labelFontSize}
						color="currentColor"
						transition="all 0.21s ease"
						_groupHover={{
							color: BITCOIN_ORANGE,
						}}
					>
						{label}
					</Label>
				</Flex>
			</Button>
		)
	}

	const LanguageOptionV3 = ({ value, children, ...rest }: LanguageOptionsProps) => {
		const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
		const borderColor = value === userLocale ? 'green100' : colorAlt
		const borderSize = value === userLocale ? 2 : 1
		const buttonInteractionStyles = {
			borderColor: value === userLocale ? 'green100' : BITCOIN_ORANGE,
			color: value === userLocale ? color : BITCOIN_ORANGE,
		}
		const cursor = value === userLocale ? 'default' : 'pointer'

		return (
			<Button
				dir={direction}
				flex="1 1 45%"
				onClick={() => {
					onClose()
					handleLocaleChange(value as UserLocale)
				}}
				variant="outline"
				size="sm"
				color={color}
				borderWidth={borderSize}
				borderColor={borderColor}
				cursor={cursor}
				_hover={buttonInteractionStyles}
				_active={buttonInteractionStyles}
				{...rest}
			>
				{children}
			</Button>
		)
	}

	// NEW LOCALE UPDATE HERE
	return (
		<>
			<LanguageButton onClick={onOpen} />

			<Modal
				key="language"
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				motionPreset="slideInBottom"
				size="sm"
			>
				<ModalOverlay />

				<ModalContent
					bg={bg}
					borderWidth={1}
					borderColor={color}
					borderRadius="lg"
					zIndex={3334}
				>
					<ModalBody mt={4}>
						<Flex
							direction="row"
							justify="center"
							wrap="wrap"
							maxHeight="356px"
							overflowY="auto"
							gap={3}
						>
							<LanguageOptionV3
								value="ar"
								aria-label={AR}
							>
								🇸🇦 {AR}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="ca"
								aria-label={CA}
							>
								<Image
									src="/images/flags/catalan-current.png"
									htmlHeight="9px"
									htmlWidth="18px"
									mr={1}
								/> {CA}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="de"
								aria-label={DE}
							>
								🇩🇪 {DE}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="en"
								aria-label={EN}
							>
								🇺🇸 {EN}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="es"
								aria-label={ES}
							>
								🇪🇸 {ES}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="fr"
								aria-label={FR}
							>
								🇫🇷 {FR}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="gr"
								aria-label={GR}
							>
								🇬🇷 {GR}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="it"
								aria-label={FR}
							>
								🇮🇹 {IT}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="pl"
								aria-label={PL}
							>
								🇵🇱 {PL}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="pt"
								aria-label={PT}
							>
								🇧🇷 {PT}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="ru"
								aria-label={RU}
							>
								🇷🇺 {RU}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="sv"
								aria-label={SV}
							>
								🇸🇪 {SV}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="tr"
								aria-label={TR}
							>
								🇹🇷 {TR}
							</LanguageOptionV3>

							<LanguageOptionV3
								value="zh"
								aria-label={ZH}
							>
								🇨🇳 {ZH}
							</LanguageOptionV3>
						</Flex>
					</ModalBody>

					<ModalFooter>
						<Flex
							w="100%"
							justify="flex-end"
							align="flex-end"
							color={color}
						>
							<Button
								onClick={onClose}
								variant="outline"
								colorScheme="black"
								fontSize="sm"
								textTransform="uppercase"
								_active={interactionStyles}
								_hover={interactionStyles}
							>
								<CloseButtonLabel />
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
