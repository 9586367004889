import {
	Flex,
	Text,
	useColorModeValue,
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { differenceInMilliseconds } from 'date-fns'

import {
	blockState,
	circlesBreakpointState,
	timeAvgState,
} from '../../../../state'
import { getAvgBlockTime, getBreakpointValue } from '../../../../utils'
import { Label } from '../../../shared'
import { primaryDataFontSize } from './dataComponents.constants'
import { GENESIS_BLOCK_DATE } from '../../../../constants'

const AvgBlockTime = () => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const timeAvg = useRecoilValue(timeAvgState)

	const blockTime = getAvgBlockTime(timeAvg)
	const color = useColorModeValue('black', 'white')
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)

	return (
		<Text
			lineHeight={fontSize}
			my={1}
			color={color}
			fontSize={fontSize}
			fontWeight="bold"
		>
			<>{blockTime}</>
		</Text>
	)
}

export const DataBlockTime = () => {
	const labelColor = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255, 0.7)')

	return (
		<>
			<Label>
				<FormattedMessage id="data.block_time.title" />
			</Label>

			<AvgBlockTime />

			<Label color={labelColor} fontWeight="semibold">
				<FormattedMessage id="data.block_time.target" />: <FormattedMessage id="data.block_time.10minutes.abbr" />
			</Label>
		</>
	)
}

export const DataBlockTimeLifetime = () => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const block = useRecoilValue(blockState)
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const colorAlt3 = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.3)')
	const now = new Date()
	const msSinceGenesis = differenceInMilliseconds(now, new Date(GENESIS_BLOCK_DATE))
	const genesisBlockTime = getAvgBlockTime(msSinceGenesis / block.height)
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)

	return (
		<Flex
			direction="column"
			align="center"
		>
			<Label>
				SINCE GENESIS
			</Label>
			
			<Text
				color={color}
				fontSize={fontSize}
				fontWeight="bold"
				lineHeight="none"
				whiteSpace="nowrap"
				display="flex"
				align="center"
				gap="3px"
				my={1}
			>
				{genesisBlockTime}
			</Text>
			
			<Label fontWeight="semibold" color={colorAlt}>
				<FormattedMessage id="data.block_time.title" />
			</Label>

			<Label
				color={colorAlt3}
				fontWeight="semibold"
				mt={1}
			>
				<FormattedMessage id="data.block_time.target" />: <span style={{ color: colorAlt2 }}><FormattedMessage id="data.block_time.10minutes.abbr" /></span>
			</Label>
		</Flex>
	)
}
