import {
	Flex,
	Text,
	Link,
	Modal,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Button,
	Image,
	useColorModeValue,
} from '@chakra-ui/react'
import type { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useRecoilValue } from 'recoil'
import { format } from 'date-fns'

import {
	staticModeState,
	blockState,
	scrubBlockState,
} from '../../../../state'
// import { TCTxs } from '../../../features'
import {
	Label,
	ModalOverlay,
	BitcoinSymbolEntity,
} from '../../../shared'
import { MempoolSpaceLogo } from '../../../svg'
import { BITCOIN_ORANGE } from '../../../../constants'
import {
	abbreviateString,
	abbreviateNumberBlockSize,
	satsToBitcoin,
} from '../../../../utils'

interface BlockDetailModalProps {
    isOpen: boolean
    onClose: () => void
}

interface BlockDataRowProps {
	label: string
	value: string | number | ReactElement
}

const BlockDataRow = ({ label, value }: BlockDataRowProps) => {
	const color = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	return (
		<Flex
			flex="1 1 50%"
			direction="column"
			align="center"
		>
			<Label color={color}>
				{label}
			</Label>
			<Text fontWeight="bold">{value}</Text>
		</Flex>
	)
}

export const BlockDetailsModal = ({
	isOpen,
	onClose,
}: BlockDetailModalProps) => {
	const staticMode = useRecoilValue(staticModeState)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const bg = useColorModeValue('white', 'black')
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const colorAlt2 = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')

	const blockHeight = block.height
	const scrubBlockHeight = scrubBlock.height

	const blockDetailsId = staticMode ? scrubBlock.id : block.id
	const blockSize = staticMode
		? abbreviateNumberBlockSize(scrubBlock.size)
		: abbreviateNumberBlockSize(block.size)
	const txCount = staticMode
		? scrubBlock.tx_count
		: block.tx_count

	const height = staticMode
		? scrubBlockHeight
		: blockHeight
			? blockHeight
			: 0
	const timestamp = staticMode
		? scrubBlock.timestamp
		: block.timestamp
	const avgFeeRate = staticMode
		? scrubBlock.extras.avgFeeRate
		: block.extras.avgFeeRate
	const feeRange = staticMode
		? scrubBlock.extras.feeRange
		: block.extras.feeRange
	const feeRangeString = feeRange && feeRange[0]
		? `${feeRange[0].toFixed(0)} - ${feeRange[feeRange.length - 1].toFixed(0)} s/vB`
		: ''
	const totalFees = staticMode
		? satsToBitcoin(scrubBlock.extras.totalFees)
		: satsToBitcoin(block.extras.totalFees)
	const blockReward = staticMode
		? satsToBitcoin(scrubBlock.extras.reward)
		: satsToBitcoin(block.extras.reward)
	const mempoolBaseUrl = `https://mempool.space/block/${blockDetailsId}`
	const bitfeedBaseUrl = `https://bitfeed.live/block/height/${scrubBlockHeight ?? blockHeight}`

	const getDateOutput = (date: Date) => {
		return format(date, 'yyyy-MM-dd HH:mm')
	}

	return (
		<Modal
			key="blockLink"
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			motionPreset="slideInBottom"
			size="md"
		>
			<ModalOverlay />

			<ModalContent
				bg={bg}
				borderWidth={1}
				borderColor={color}
				color={color}
				borderRadius="lg"
				zIndex={3334}
			>
				<ModalHeader pb={0}>
					<Flex align="center" gap="1">
						<ExternalLinkIcon color={colorAlt2} />
						<Text
							color={colorAlt}
							fontSize="xs"
							textTransform="uppercase"
						>
							<FormattedMessage id="future.block" /> {height}
						</Text>
					</Flex>
				</ModalHeader>

				<ModalBody py={10}>
					<Flex direction="column" align="center" gap={10}>
						<Flex direction="column" w="100%" gap={2}>
							<BlockDataRow
								label="Block Hash (ID)"
								value={abbreviateString(String(blockDetailsId), 8)}
							/>

							<BlockDataRow
								label="Timestamp"
								value={getDateOutput(new Date(timestamp * 1000))}
							/>

							<Flex>
								<BlockDataRow
									label="TX Count"
									value={txCount}
								/>

								<BlockDataRow
									label="Avg Fee Rate"
									value={`${avgFeeRate} s/vB`}
								/>
							</Flex>

							<Flex>
								<BlockDataRow
									label="Size"
									value={blockSize || ''}
								/>

								{feeRangeString && (
									<BlockDataRow
										label="Fee Rate Range"
										value={feeRangeString}
									/>
								)}
							</Flex>

							<Flex>
								<BlockDataRow
									label="Subsidy + Fees"
									value={<><BitcoinSymbolEntity /> {blockReward}</>}
								/>

								<BlockDataRow
									label="Fees"
									value={<><BitcoinSymbolEntity /> {totalFees}</>}
								/>
							</Flex>
						</Flex>
						
						{/* <TCTxs /> */}

						<Flex
							direction="column"
							justify="space-beteween"
							align="center"
							gap={4}
						>
							<Label color={colorAlt} style={{ display: 'flex', alignItems: 'center' }}>
								More Detail <ExternalLinkIcon mb="2px" ml={1} />
							</Label>

							<Link
								href={mempoolBaseUrl}
								bg="black"
								p={1}
								isExternal
							>
								<MempoolSpaceLogo width={160} />
							</Link>

							<Link
								href={bitfeedBaseUrl}
								isExternal
							>
								<Image src="/images/bitfeed-logo.jpg" boxSize="200" height="auto" />
							</Link>
						</Flex>
					</Flex>
				</ModalBody>

				<ModalFooter>
					<Flex
						w="100%"
						justify="flex-end"
						align="center"
						gap={8}
					>
						<Button
							onClick={onClose}
							flexShrink={0}
							variant="outline"
							colorScheme="black"
							fontSize="sm"
							textTransform="uppercase"
							_active={{
								borderColor: BITCOIN_ORANGE,
								color: BITCOIN_ORANGE,
							}}
							_hover={{
								borderColor: BITCOIN_ORANGE,
								color: BITCOIN_ORANGE,
							}}
						>
							<FormattedMessage id="shared.close_button_label" />
						</Button>
					</Flex>
				</ModalFooter>

			</ModalContent>
		</Modal>
	)
}