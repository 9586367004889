import {
	useState,
	useEffect,
	useLayoutEffect,
	useRef,
	type ChangeEventHandler,
	type KeyboardEvent,
} from 'react'
import {
	Flex,
	Box,
	Text,
	Input,
	Button,
	Spinner,
	chakra,
	shouldForwardProp,
	type ResponsiveValue,
	useColorModeValue,
} from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import { CloseIcon, SearchIcon } from '@chakra-ui/icons'
import { FormattedMessage } from 'react-intl'
import { useRecoilState, useRecoilValue } from 'recoil'

import {
	blockState,
	scrubbingState,
	showBlockDropState,
	showBlockRippleState,
	staticModeState,
	editingBlockHeightState,
	circlesWrapWidthState,
	circlesBreakpointState,
	scrubBlockState,
	pastDateSearchingState,
	confModeState,
	loadingBlockHeightState,
	shouldSetAppToBlockPathState,
	pastBlockHoverState,
	supplyIntroCompleteState,
} from '../../../../state'
import {
	getBreakpointValue,
	numbersOnly,
} from '../../../../utils'
import { Label } from '../../../shared'
import { BITCOIN_ORANGE } from '../../../../constants'
import { BlockHeightDetails } from './BlockHeight.components'

interface BlockHeightProps {
	// eslint-disable-next-line
	onSearchBlockHeight: (value: number, settingBlockPath?: boolean, last24Block?: boolean) => void
	// eslint-disable-next-line
	onSearchFutureBlock: (value: number) => void
	onOpenBlockDetailsModal: () => void
	// eslint-disable-next-line
	onScrubChangeEnd: (x: number) => void
	// eslint-disable-next-line
	setScrubValue: (x: number) => void
}

const MotionBox = chakra(motion.div, {
	shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export const BlockHeight = ({
	onSearchBlockHeight,
	onSearchFutureBlock,
	onOpenBlockDetailsModal,
	onScrubChangeEnd,
	setScrubValue,
}: BlockHeightProps) => {
	const confMode = useRecoilValue(confModeState)
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const staticMode = useRecoilValue(staticModeState)
	const scrubbing = useRecoilValue(scrubbingState)
	const showBlockDrop = useRecoilValue(showBlockDropState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const circlesW = useRecoilValue(circlesWrapWidthState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const pastDateSearching = useRecoilValue(pastDateSearchingState)
	const supplyIntroComplete = useRecoilValue(supplyIntroCompleteState)
	const [editingBlockHeight, setEditingBlockHeight] = useRecoilState(editingBlockHeightState)
	const [loadingBlockHeight, setLoadingBlockHeight] = useRecoilState(loadingBlockHeightState)
	const shouldSetAppToBlockPath = useRecoilValue(shouldSetAppToBlockPathState)
	const pastBlockHover = useRecoilValue(pastBlockHoverState)
	const [blockHeightWrapSize, setBlockHeightWrap] = useState<number | undefined>(undefined)
	const [heightInputValue, setHeightInputValue] = useState('')
	const [counter, setCounter] = useState(0)
	const wrapRef = useRef<HTMLDivElement>(null)

	const editButtonFontSize = getBreakpointValue({ base: '11px', md: '14px', lg: '16px' }, circlesBreakpoint)
	const editButtonsDirection = getBreakpointValue({ base: 'column-reverse', sm: 'row' }, circlesBreakpoint) as ResponsiveValue<'column-reverse' | 'row'>
	const editButtonsLeft = getBreakpointValue({ base: '22%', sm: 0 }, circlesBreakpoint)
	const editButtonsGap = getBreakpointValue({ base: 2, sm: 3 }, circlesBreakpoint)
	const editButtonSize = getBreakpointValue({ base: 'sm', sm: 'md' }, circlesBreakpoint) as ResponsiveValue<'sm' | 'md'>
	const responsiveTitleMb = getBreakpointValue({ base: '2px', sm: '-4px',  md: '-5px', xxl: '-8px', jumbo: '-6px' }, circlesBreakpoint)
	const detailsPos = getBreakpointValue({ base: 'absolute', sm: 'relative' }, circlesBreakpoint) as ResponsiveValue<'absolute' | 'relative'>
	const topBottomHeight = getBreakpointValue({ base: 'auto', sm: '20px' }, circlesBreakpoint)
	const detailsTop = getBreakpointValue({ base: '100%', sm: 'auto' }, circlesBreakpoint)
	const responsiveSize = confMode
		? getBreakpointValue({
			base: '23px',
			xxxs: '30px',
			xxs: '40px',
			xs: '50px',
			sm: '74px',
			md: '90px',
			lg: '110px',
			xl: '120px',
			xxl: '128px',
			xxxl: '132px',
			jumbo: '148px',
		}, circlesBreakpoint)
		: getBreakpointValue({
			base: '23px',
			xxxs: '30px',
			xxs: '38px',
			xs: '52px',
			sm: '70px',
			md: '84px',
			lg: '98px',
			xl: '108px',
			xxl: '118px',
			xxxl: '124px',
			jumbo: '150px',
		}, circlesBreakpoint)
	const labelColor = useColorModeValue('black', 'white')
	const spinnerColor = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const textColor = showBlockDrop && !staticMode
		? useColorModeValue('white', 'black')
		: useColorModeValue('black', 'white')

	const { height, timestamp } = block
	const { height: scrubBlockHeight, timestamp: scrubTimestamp} = scrubBlock
	const textScale = showBlockDrop && !staticMode ? 'scale(0.01)' : 'scale(1)'
	const blockHeightWrapSides = '20%'
	const blockHeightReady = !shouldSetAppToBlockPath && !loadingBlockHeight
	const date = timestamp ? new Date(timestamp * 1000) : ''
	const scrubDate = scrubTimestamp ? new Date(scrubTimestamp * 1000) : ''
	const detailsReady = date || scrubDate
	const detailsCondition = supplyIntroComplete
		&& !showBlockDrop
		&& !showBlockRipple
		&& !editingBlockHeight
		&& !scrubbing
		&& detailsReady
	const detailsOpacity = pastBlockHover ? 0.5 : 1
	const detailsFilter = pastBlockHover ? 'blur(3px)' : 'blur(0)'

	const bigBlockNumber = staticMode
		? pastBlockHover
			? pastBlockHover
			: scrubBlockHeight
		: pastBlockHover
			? pastBlockHover
			: height

	const layoutEdit = false
	const blinkDuration = showBlockRipple ? 0.5 : 0.625

	const handleEditBlockHeight = () => {
		setHeightInputValue('')
		setEditingBlockHeight(true)
	}

	const handleCancelEditBlockHeight = () => {
		setEditingBlockHeight(false)
		setHeightInputValue('')
		onScrubChangeEnd(height)
		setScrubValue(height)
	}

	const handleSubmitBlockHeight = () => {
		const heightInputValueNum = parseInt(heightInputValue)

		setLoadingBlockHeight(true)
		if (heightInputValueNum <= height) {
			onSearchBlockHeight(heightInputValueNum)
		} else {
			onSearchFutureBlock(heightInputValueNum)
		}
		setEditingBlockHeight(false)
		setHeightInputValue('')
	}

	const handleHeightInputChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
		const { value } = event.currentTarget
		const newValueParsed = parseInt(value)

		if (value === '') {
			setHeightInputValue(value)
		}
		if (!isNaN(newValueParsed) && newValueParsed >= 0) {
			const cleanValue = String(numbersOnly(value))
			setHeightInputValue(cleanValue)
		}
	}

	const handleKeyPress = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			handleSubmitBlockHeight()
		}
	}

	useLayoutEffect(() => {
		const handleResize = () => {
			if (wrapRef.current) {
				const h = wrapRef.current.clientHeight
				setBlockHeightWrap(h)
			}
		}
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [setBlockHeightWrap])

	useEffect(() => {
		if (wrapRef.current) {
			const h = wrapRef.current.clientHeight
			setBlockHeightWrap(h)
		}
	}, [setBlockHeightWrap])

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCounter((prevCounter) => prevCounter + 1)
		}, 10000)

		return () => clearInterval(intervalId)
	}, [block])

	if (circlesW === 0) return (<Spinner size="xl" />)

	return (
		<Flex
			className="tc-blockheight-wrap"
			direction="column"
			justify="center"
			pos="absolute"
			zIndex={2003}
			top={0}
			left={blockHeightWrapSides}
			right={blockHeightWrapSides}
			bottom={0}
			m="auto"
			h="18%"
			borderWidth={layoutEdit ? 1 : undefined}
			borderColor={layoutEdit ? 'purple' : undefined}
		>
			<Flex
				className="tc-blockheight-wrap-inner"
				position="relative"
				direction="column"
				align="center"
				justify="center"
			>
				{blockHeightReady && (
					<Flex
						className="tc-blockheight-title"
						justify="center"
						align="flex-end"
						w="100%"
						h={topBottomHeight}
						mb={responsiveTitleMb}
						borderWidth={layoutEdit ? 1 : undefined}
						borderColor={layoutEdit ? 'red' : undefined}
					>
						{showBlockRipple && (
							<MotionBox
								animate={{
									opacity: [1, 0.25],
								}}
								// @ts-ignore
								transition={{
									duration: blinkDuration,
									ease: 'linear',
									repeat: Infinity,
									repeatType: 'loop',
								}}
							>
								<Label
									mb="-4px"
									fontWeight="semibold"
									color={BITCOIN_ORANGE}
								>
									<FormattedMessage id="block_height.new_block" />
								</Label>
							</MotionBox>
						)}
						{!showBlockRipple && (
							<Label
								mb="-4px"
								fontWeight="semibold"
								color={showBlockRipple ? BITCOIN_ORANGE : labelColor}
							>
								{staticMode || pastBlockHover
									? <FormattedMessage id="block_height.past_block" />
									: <FormattedMessage id="block_height.current_block" />
								}
							</Label>
						)}
					</Flex>
				)}

				
				<Flex
					className="tc-blockheight"
					direction="column"
					justify="center"
					h={blockHeightWrapSize}
					maxH={responsiveSize}
					my="2px"
					color={textColor}
					transform={textScale}
					transition="all 0.25s ease"
				>
					<Box pos="relative">
						{(shouldSetAppToBlockPath || loadingBlockHeight || pastDateSearching) && (
							<Flex
								h={responsiveSize}
								align="center"
								justify="center"
							>
								<Spinner color="white" size="xl" />
							</Flex>
						)}

						{blockHeightReady && (
							<>
								{!editingBlockHeight
									&& !loadingBlockHeight
									&& !pastDateSearching
									&& (
										<Box
											ref={wrapRef}
											cursor="pointer"
											px={4}
											onClick={handleEditBlockHeight}
											_active={{
												color: BITCOIN_ORANGE,
											}}
											_hover={{
												color: BITCOIN_ORANGE,
											}}
										>
											<Text
												color={pastBlockHover ? 'orange100' : undefined}
												fontSize={responsiveSize}
												fontWeight="semibold"
												textAlign="center"
												lineHeight={responsiveSize}
												whiteSpace="nowrap"
												transition="all 0.45s ease"
												borderWidth={layoutEdit ? 1 : undefined}
												borderColor={layoutEdit ? 'green' : undefined}
											>
												{bigBlockNumber}
											</Text>
										</Box>
									)}

								{editingBlockHeight && !loadingBlockHeight && (
									<>
										<Input
											id="block-height-input"
											type="tel"
											value={heightInputValue}
											onChange={handleHeightInputChange}
											onKeyPress={handleKeyPress}
											variant="unstyled"
											fontSize={responsiveSize}
											fontWeight="semibold"
											lineHeight="none"
											maxH={responsiveSize}
											transform="translateY(-2px)"
											textAlign="center"
											autoComplete="off"
											autoFocus
										/>

										<Flex
											direction={editButtonsDirection}
											justify="center"
											pos="absolute"
											zIndex={1111}
											top="calc(100% + 10px)"
											left={editButtonsLeft}
											right={editButtonsLeft}
											gap={editButtonsGap}
										>
											<Button
												onClick={handleCancelEditBlockHeight}
												colorScheme="black"
												size={editButtonSize}
												bg="black"
												borderWidth={2}
												color={labelColor}
												borderColor={labelColor}
												fontSize={editButtonFontSize}
												textTransform="uppercase"
												_hover={{
													borderColor: BITCOIN_ORANGE,
													color: BITCOIN_ORANGE,
												}}
											>
												<CloseIcon boxSize={3} mr={1} /> <FormattedMessage id="shared.cancel" />
											</Button>

											<Button
												onClick={handleSubmitBlockHeight}
												colorScheme="black"
												size={editButtonSize}
												bg="black"
												borderWidth={2}
												color={labelColor}
												borderColor={labelColor}
												fontSize={editButtonFontSize}
												textTransform="uppercase"
												_hover={{
													borderColor: BITCOIN_ORANGE,
													color: BITCOIN_ORANGE,
												}}
												disabled={heightInputValue === ''}
											>
												<SearchIcon boxSize={4} mr={1} /> <FormattedMessage id="block_height.search" />
											</Button>
										</Flex>
									</>
								)}
							</>
						)}
					</Box>
				</Flex>
				
				{blockHeightReady && (
					<Flex
						className="tc-blockheight-details"
						key={`blockHeight-${counter}`}
						w="100%"
						h={topBottomHeight}
						borderWidth={layoutEdit ? 1 : undefined}
						borderColor={layoutEdit ? 'red' : undefined}
						pos={detailsPos}
						top={detailsTop}
						justify="center"
						align="center"
						fontWeight="bold"
						lineHeight="none"
						textTransform="uppercase"
						opacity={detailsOpacity}
						filter={detailsFilter}
						transition="all 0.21s ease"
					>
						{scrubbing && (
							<Box>
								<Spinner size="sm" color={spinnerColor} />
							</Box>
						)}
						{detailsCondition && (
							<BlockHeightDetails onOpenBlockDetailsModal={onOpenBlockDetailsModal} />
						)}
					</Flex>
				)}
			</Flex>
		</Flex>
	)
}
