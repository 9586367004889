import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { SatsSymbol } from '../../shared'
import { SATS_SYMBOL_OPTIONS } from '../../shared'
import {
	TIME_FORMAT,
	DATE_FORMAT,
	GALLERY_FILTERS,
} from '../../../constants'
import type {
	TimeFormat,
	DateFormat,
	SelectedAudioSound,
	SelectedAlarmSound,
	GalleryFilter,
} from '../../../constants'
import type { Hashrate } from '../calendar/data-components'
import type { UserLocale } from '../../../lang/messages'

export interface SettingsState {
	satsSymbol: SatsSymbol
	showMoscowTime: boolean
	showCuckBucks: boolean
	showMarketCap: boolean
	hashrateSample: Hashrate
	showData: boolean
	showFees: boolean
	feesRTL: boolean
	showMiningPool: boolean
	reducedMotion: boolean
	fullNewBlockRipple: boolean
	disableHalvingPulse: boolean
	disableDifficultyPulse: boolean
	timeFormat: TimeFormat
	dateFormat: DateFormat
	audioActive: boolean
	audioSound: SelectedAudioSound
	userLocale: UserLocale
	userAlarmBlock: number | null
	userAlarmSound: SelectedAlarmSound
	userGalleryFilter: GalleryFilter
}

// TODO: why HASHRATE_SAMPLES is undefined when I import this from DataNetworkHashrate.tsx ?
const HASHRATE_SAMPLES = {
	current: 'CURRENT',
	sevenDay: '7DAY',
	twoWeek: '2WEEK',
	oneMonth: '1MONTH',
	threeMonth: '3MONTH',
}

const initialState = {
	satsSymbol: SATS_SYMBOL_OPTIONS.sats,
	showMoscowTime: true,
	showCuckBucks: true,
	showMarketCap: true,
	hashrateSample: HASHRATE_SAMPLES.twoWeek,
	showData: true,
	showFees: true,
	feesRTL: true,
	showMiningPool: true,
	reducedMotion: false,
	fullNewBlockRipple: true,
	disableHalvingPulse: false,
	disableDifficultyPulse: false,
	timeFormat: TIME_FORMAT.h12,
	dateFormat: DATE_FORMAT.default,
	audioActive: false,
	userLocale: 'en',
	userAlarmBlock: null,
	userGalleryFilter: GALLERY_FILTERS.asc,
} as SettingsState

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setSatsSymbol: (state, action: PayloadAction<SatsSymbol>) => {
			state.satsSymbol = action.payload
		},
		setMoscowTime: (state, action: PayloadAction<boolean>) => {
			state.showMoscowTime = action.payload
		},
		setCuckBucks: (state, action: PayloadAction<boolean>) => {
			state.showCuckBucks = action.payload
		},
		setMarketCap: (state, action: PayloadAction<boolean>) => {
			state.showMarketCap = action.payload
		},
		setHashrate: (state, action: PayloadAction<Hashrate>) => {
			state.hashrateSample = action.payload
		},
		setShowData: (state, action: PayloadAction<boolean>) => {
			state.showData = action.payload
		},
		setShowFees: (state, action: PayloadAction<boolean>) => {
			state.showFees = action.payload
		},
		setFeesRTL: (state, action: PayloadAction<boolean>) => {
			state.feesRTL = action.payload
		},
		setShowMiningPool: (state, action: PayloadAction<boolean>) => {
			state.showMiningPool = action.payload
		},
		setReducedMotion: (state, action: PayloadAction<boolean>) => {
			state.reducedMotion = action.payload
		},
		setFullNewBlockRipple: (state, action: PayloadAction<boolean>) => {
			state.fullNewBlockRipple = action.payload
		},
		setDisableHalvingPulse: (state, action: PayloadAction<boolean>) => {
			state.disableHalvingPulse = action.payload
		},
		setDisableDifficultyPulse: (state, action: PayloadAction<boolean>) => {
			state.disableDifficultyPulse = action.payload
		},
		setTimeFormat: (state, action: PayloadAction<TimeFormat>) => {
			state.timeFormat = action.payload
		},
		setDateFormat: (state, action: PayloadAction<DateFormat>) => {
			state.dateFormat = action.payload
		},
		setAudioActive: (state, action: PayloadAction<boolean>) => {
			state.audioActive = action.payload
		},
		setAudioSound: (state, action: PayloadAction<SelectedAudioSound>) => {
			state.audioSound = action.payload
		},
		setUserLocale: (state, action: PayloadAction<UserLocale>) => {
			state.userLocale = action.payload
		},
		setUserAlarmBlock: (state, action: PayloadAction<number | null>) => {
			state.userAlarmBlock = action.payload
		},
		setUserAlarmSound: (state, action: PayloadAction<SelectedAlarmSound>) => {
			state.userAlarmSound = action.payload
		},
		setUserGalleryFilter: (state, action: PayloadAction<GalleryFilter>) => {
			state.userGalleryFilter = action.payload
		},
	}
})

export const {
	setSatsSymbol,
	setMoscowTime,
	setCuckBucks,
	setMarketCap,
	setHashrate,
	setShowData,
	setShowFees,
	setFeesRTL,
	setShowMiningPool,
	setReducedMotion,
	setFullNewBlockRipple,
	setDisableHalvingPulse,
	setDisableDifficultyPulse,
	setTimeFormat,
	setDateFormat,
	setAudioActive,
	setAudioSound,
	setUserLocale,
	setUserAlarmBlock,
	setUserAlarmSound,
	setUserGalleryFilter,
} = settingsSlice.actions

export default settingsSlice.reducer
