import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
	DATA_NEXT_DIFF_ADJ,
	DATA_NETWORK_HASHRATE,
	DATA_BLOCK_TIME,
} from '../../../constants'

type DataChooserStorage = string[]

const initialState = [
	DATA_BLOCK_TIME,
	DATA_NEXT_DIFF_ADJ,
	DATA_NETWORK_HASHRATE,
] as DataChooserStorage

export const dataChooserSlice = createSlice({
	name: 'dataChooser',
	initialState,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		setDataChooser: (state, action: PayloadAction<DataChooserStorage>) => {
			return action.payload
		}
	},
})

export const {
	setDataChooser,
} = dataChooserSlice.actions

export default dataChooserSlice.reducer