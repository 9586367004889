import { useState, useEffect } from 'react'
import {
	Button,
	Flex,
	Text,
	useColorModeValue,
	useToken,
} from '@chakra-ui/react'
import { ArrowUpIcon, ArrowDownIcon } from '@chakra-ui/icons'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { useAppDispatch } from '../../../../hooks'

import { hashrateAveragesState, circlesBreakpointState } from '../../../../state'
import { setHashrate } from '../../settings/settingsReducer'
import { Label } from '../../../shared'
import { useAppSelector } from '../../../../hooks'
import {
	getFormattedHashrate,
	positiveInt,
	getBreakpointValue,
} from '../../../../utils'
import { HR24, BITCOIN_ORANGE } from '../../../../constants'
import { primaryDataFontSize } from './dataComponents.constants'

export type Hashrate = 'CURRENT' | '7DAY' | '2WEEK' | '1MONTH' | '3MONTH'

export const HASHRATE_SAMPLES = {
	current: 'CURRENT',
	sevenDay: '7DAY',
	twoWeek: '2WEEK',
	oneMonth: '1MONTH',
	threeMonth: '3MONTH',
}

export const HASHRATE_SAMPLE_LABELS = {
	CURRENT: 'REAL-TIME',
	['7DAY']: '7D',
	['2WEEK']: '14D',
	['1MONTH']: '30D',
	['3MONTH']: '90D',
}

interface HashrateButtonProps {
	sample: Hashrate
	label: string
}

export const DataNetworkHashrate = () => {
	const intl = useIntl()
	const dispatch = useAppDispatch()
	const [green100, lightgreen80, red100] = useToken('colors', ['green100', 'lightgreen80', 'red100'])
	const green = useColorModeValue(lightgreen80, green100)
	const hashrateAverages = useRecoilValue(hashrateAveragesState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const fontSize = getBreakpointValue(primaryDataFontSize, circlesBreakpoint)
	const { hashrateSample } = useAppSelector(({ settings }) => settings)
	const [percent, setPercent] = useState(0)
	const [formattedHashrate, setFormattedHashrate] = useState('0')
	const {
		current,
		yesterday,
		sevenDay,
		yesterdaySevenDay,
		twoWeek,
		yesterdayTwoWeek,
		month,
		yesterdayMonth,
		threeMonth,
		yesterdayThreeMonth,
	} = hashrateAverages
	const noChange = percent === 0
	const changeString = noChange
		? `${intl.formatMessage({ id: 'shared.no_change' })}`
		: `${positiveInt(percent)}%`
	const changeTransform = noChange
		? undefined
		: 'translateX(5px) translateY(-1px)'
	const colorString = noChange
		? 'white'
		: percent < 0
			? red100
			: green
	const hashrateButtonsSize = getBreakpointValue({ base: '19px', md: '20px', xl: '22px' }, circlesBreakpoint)
	const hashrateButtonFontSize = getBreakpointValue({ base: '9px', xl: '10px' }, circlesBreakpoint)
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const getPercentArrow = () => {
		if (percent < 0) return <ArrowDownIcon />
		return <ArrowUpIcon />
	}

	const handleHashrateChange = (newValue: string) => {
		dispatch(setHashrate(newValue as Hashrate))
	}

	const HashrateButton = ({ sample, label }: HashrateButtonProps) => {
		const textColor = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.5)')
		const color = hashrateSample === sample ? BITCOIN_ORANGE : textColor
		const bg = useColorModeValue('white', 'black')

		return (
			<Button
				flexGrow={0}
				variant="outline"
				h={hashrateButtonsSize}
				w={hashrateButtonsSize}
				minW={hashrateButtonsSize}
				p={0}
				bg={bg}
				borderWidth={2}
				borderRadius="50%"
				fontSize={hashrateButtonFontSize}
				fontWeight="bold"
				lineHeight="none"
				cursor="pointer"
				color={color}
				borderColor={color}
				_active={{
					borderColor: BITCOIN_ORANGE,
					color: BITCOIN_ORANGE,
				}}
				_hover={{
					borderColor: BITCOIN_ORANGE,
					color: BITCOIN_ORANGE,
				}}
				onClick={() => handleHashrateChange(sample)}
			>
				{label}
			</Button>
		)
	}

	useEffect(() => {
		const getPosPercentage = (ratio: number) => Number(((ratio - 1) * 100).toFixed(2))
		const getNegPercentage = (ratio: number) => Number(`-${((1 - ratio) * 100).toFixed(2)}`)
		const getRatio = (sample: number, yesterdaySample: number) => sample / yesterdaySample
		const getPrefferedRatio = () => {
			if (hashrateSample === HASHRATE_SAMPLES.current) {
				return getRatio(current, yesterday)
			}
			if (hashrateSample === HASHRATE_SAMPLES.twoWeek) {
				return getRatio(twoWeek, yesterdayTwoWeek)
			}
			if (hashrateSample === HASHRATE_SAMPLES.oneMonth) {
				return getRatio(month, yesterdayMonth)
			}
			if (hashrateSample === HASHRATE_SAMPLES.threeMonth) {
				return getRatio(threeMonth, yesterdayThreeMonth)
			}
			return getRatio(sevenDay, yesterdaySevenDay)
		}
		const getPercentage = () => {
			let percentage = 0
			const ratio = getPrefferedRatio()
			if (hashrateSample === HASHRATE_SAMPLES.current) {
				if (current < yesterday) {
					percentage = getNegPercentage(ratio)
				}
				if (current > yesterday) {
					percentage = getPosPercentage(ratio)
				}
			}
			if (hashrateSample === HASHRATE_SAMPLES.sevenDay) {
				if (sevenDay < yesterdaySevenDay) {
					percentage = getNegPercentage(ratio)
				}
				if (sevenDay > yesterdaySevenDay) {
					percentage = getPosPercentage(ratio)
				}
			}
			if (hashrateSample === HASHRATE_SAMPLES.twoWeek) {
				if (twoWeek < yesterdayTwoWeek) {
					percentage = getNegPercentage(ratio)
				}
				if (twoWeek > yesterdayTwoWeek) {
					percentage = getPosPercentage(ratio)
				}
			}
			if (hashrateSample === HASHRATE_SAMPLES.oneMonth) {
				if (month < yesterdayMonth) {
					percentage = getNegPercentage(ratio)
				}
				if (month > yesterdayMonth) {
					percentage = getPosPercentage(ratio)
				}
			}
			if (hashrateSample === HASHRATE_SAMPLES.threeMonth) {
				if (threeMonth < yesterdayThreeMonth) {
					percentage = getNegPercentage(ratio)
				}
				if (threeMonth > yesterdayThreeMonth) {
					percentage = getPosPercentage(ratio)
				}
			}
			return percentage
		}
		const getHashrate = () => {
			let hashrate = ''
			if (hashrateSample === HASHRATE_SAMPLES.current) {
				hashrate = getFormattedHashrate(current)
			}
			if (hashrateSample === HASHRATE_SAMPLES.sevenDay) {
				hashrate = getFormattedHashrate(sevenDay)
			}
			if (hashrateSample === HASHRATE_SAMPLES.twoWeek) {
				hashrate = getFormattedHashrate(twoWeek)
			}
			if (hashrateSample === HASHRATE_SAMPLES.oneMonth) {
				hashrate = getFormattedHashrate(month)
			}
			if (hashrateSample === HASHRATE_SAMPLES.threeMonth) {
				hashrate = getFormattedHashrate(threeMonth)
			}
			return hashrate
		}

		setFormattedHashrate(getHashrate())
		setPercent(getPercentage())
	}, [
		hashrateSample,
		current,
		yesterday,
		sevenDay,
		yesterdaySevenDay,
		twoWeek,
		yesterdayTwoWeek,
		month,
		yesterdayMonth,
		threeMonth,
		yesterdayThreeMonth,
	])

	return (
		<>
			<Label>
				{intl.formatMessage({ id: 'data.hashrate.title' })}
			</Label>

			<Text
				lineHeight={fontSize}
				my={1}
				color={color}
				fontSize={fontSize}
				fontWeight="bold"
				whiteSpace="nowrap"
				display="flex"
				align="center"
			>
				{formattedHashrate}
			</Text>

			<Label
				pos="relative"
				color={colorAlt}
				fontWeight="semibold"
				lineHeight="none"
				transform={changeTransform}
				whiteSpace="nowrap"
			>
				<span style={{ color: colorString }}>
					<>
						{!noChange && (
							<span style={{
								color: 'currentColor',
								position: 'absolute',
								display: 'inline-block',
								fontSize: '13px',
								fontWeight: 'bold',
								left: '-13px',
								top: '-1px',
							}}>
								{getPercentArrow()}
							</span>
						)}
						{changeString}
					</>
				</span> {HR24}
			</Label>

			<Flex
				mt={2}
				mb={1}
				gap="6px"
			>
				<HashrateButton sample={HASHRATE_SAMPLES.current as Hashrate} label="0" />

				<HashrateButton sample={HASHRATE_SAMPLES.sevenDay as Hashrate} label="7" />

				<HashrateButton sample={HASHRATE_SAMPLES.twoWeek as Hashrate} label="14" />

				<HashrateButton sample={HASHRATE_SAMPLES.oneMonth as Hashrate} label="30" />

				<HashrateButton sample={HASHRATE_SAMPLES.threeMonth as Hashrate} label="90" />
			</Flex>

			<Label
				color={colorAlt}
				fontWeight="semibold"
			>
				{hashrateSample === 'CURRENT'
					? HASHRATE_SAMPLE_LABELS[hashrateSample]
					: `${HASHRATE_SAMPLE_LABELS[hashrateSample]} AVERAGE`
				}
			</Label>
		</>
	)
}
