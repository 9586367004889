import {
	Flex,
	Box,
	Text,
	Link,
	useColorModeValue,
	type ResponsiveValue,
	type BoxProps,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../../hooks'
import {
	circlesBreakpointState,
	staticModeState,
	confModeState,
	scrubBlockState,
	blockState,
} from '../../../../state'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { BITCOIN_ORANGE } from '../../../../constants'
import {
	abbreviateNumberBlockSize,
	getBreakpointValue,
	getTxsLabel,
} from '../../../../utils'
import { rtlLocales } from '../../../../lang/messages'
import { getFeeColor } from '../Lower.components'

interface BlockHeightDetailsProps {
	onOpenBlockDetailsModal: () => void
}
interface BlockLinkProps {
	onOpen?: () => void
	blockId: number
	blockHeight?: number
	scrubBlockHeight?: number
}
type MidDotProps = {
	alwaysShow? : boolean
}
type MidDotBoxProps = MidDotProps & BoxProps

export const MidDot = ({ alwaysShow = false, ...rest }: MidDotBoxProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const color = useColorModeValue('black', 'white')

	const responsiveDisplay = getBreakpointValue({ base: 'none', sm: 'inline-flex' }, circlesBreakpoint) as ResponsiveValue<'none' | 'inline-flex'>
	const responsivePx = getBreakpointValue({
		base: '3px',
		sm: '6px',
		md: 2,
		xl: 3,
	}, circlesBreakpoint)

	const display = alwaysShow ? 'inline-flex' : responsiveDisplay
	
	return (
		<Box
			display={display}
			fontWeight="bold"
			color={color}
			px={responsivePx}
			{...rest}
		>
			&middot;
		</Box>
	)
}

export const BlockLink = ({ onOpen = undefined }: BlockLinkProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const linkColor = useColorModeValue('black', 'white')

	const boxSize = getBreakpointValue({
		base: 3,
		xxs: 4,
		sm: 3,
		md: 4,
		xxl: 5,
	}, circlesBreakpoint)

	return (
		<Link
			onClick={onOpen}
			transform="translateY(-1px)"
		>
			<ExternalLinkIcon
				boxSize={boxSize}
				color={linkColor}
				transition="all 0.42s ease"
				_hover={{
					color: BITCOIN_ORANGE
				}}
			/>
		</Link>
	)
}

export const BlockHeightDetails = ({ onOpenBlockDetailsModal }: BlockHeightDetailsProps) => {
	const intl = useIntl()
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const direction = rtlLocales.includes(userLocale) ? 'rtl' : undefined
	const block = useRecoilValue(blockState)
	const scrubBlock = useRecoilValue(scrubBlockState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const staticMode = useRecoilValue(staticModeState)
	const confMode = useRecoilValue(confModeState)
	const color = useColorModeValue('black', 'white')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')

	const fontSize = {
		base: '9px',
		xxs: '10px',
		xs: '13px',
		sm: '11px',
		md: '14px',
		lg: '15px',
		xxl: '17px',
		xxxl: '18px',
		jumbo: '22px',
	}
	const responsiveSize = getBreakpointValue(fontSize, circlesBreakpoint)
	const responsiveDirection = getBreakpointValue({ base: 'column-reverse', sm: 'row' }, circlesBreakpoint) as ResponsiveValue<'column-reverse' | 'row'>
	const responsiveGap = getBreakpointValue({
		base: '2px',
		xxs: '3px',
		xs: '6px',
		sm: 0,
	}, circlesBreakpoint)
	const responsiveMt = getBreakpointValue({
		base: '2px',
		sm: '-4px',
		md: '-5px',
		xxl: '-8px',
		jumbo: '-6px',
	}, circlesBreakpoint)
	
	const {
		height: blockHeight,
		id,
		size,
		tx_count: txCount,
		extras,
	} = block
	const {
		height: scrubBlockHeight,
		id: scrubBlockId,
		size: scrubSize,
		tx_count: scrubTxCount,
		extras: scrubBlockExtras,
	} = scrubBlock
	const blockId = staticMode
		? scrubBlockId
		: id
	const blockSize = staticMode
		? abbreviateNumberBlockSize(scrubSize)
		: abbreviateNumberBlockSize(size)
	const txsCount = staticMode
		? scrubTxCount
		: txCount
	const { avgFeeRate } = staticMode
		? scrubBlockExtras
		: extras
	const feeRange = staticMode
		? scrubBlock && scrubBlock.extras
			? scrubBlock.extras.feeRange
			: []
		: block && block.extras
			? block.extras.feeRange
			: []
	const lowFee = feeRange && feeRange.length > 0
		? feeRange[0].toFixed(0)
		: 1
	const highFee = feeRange && feeRange.length > 0
		? feeRange[feeRange.length - 1].toFixed(0)
		: 1

	return (
		<Flex
			direction="column"
			align="center"
			opacity={confMode ? 0 : 1}
			mt={responsiveMt}
		>
			<Flex
				direction={responsiveDirection}
				justify="center"
				align="center"
				color={color}
				fontSize={responsiveSize}
				gap={responsiveGap}
			>
				{blockId && (
					<>
						<BlockLink
							onOpen={onOpenBlockDetailsModal}
							blockId={blockId}
							blockHeight={blockHeight}
							scrubBlockHeight={scrubBlockHeight}
						/>

						<MidDot />
					</>
				)}

				{scrubBlockHeight === 0 && (
					<Text
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
						dir={direction}
					>
						<span style={{ color: 'orange' }}>
							<FormattedMessage id="block_height.genesis_block" />
						</span>
					</Text>
				)}

				<Text color={txsCount === 1 ? 'red100' : undefined}>
					{txsCount} <span style={{ fontSize: '80%', color: txsCount === 1 ? 'red100' : colorAlt }}>{getTxsLabel(txsCount, userLocale, intl.formatMessage({ id: 'block.empty' }))}</span>
				</Text>

				{txsCount > 1 && (
					<>
						<MidDot />

						<Text>
							{blockSize}
						</Text>
					</>
				)}

				{txsCount === 1 && (
					<>
						<MidDot />
						
						<Text>
							{blockSize}
						</Text>
					</>
				)}

				{txsCount > 1 && (
					<Flex align="center">
						<MidDot />

						<Flex justify="center">
							<Flex align="baseline">
								<Text
									color={getFeeColor(avgFeeRate)}
									ml="-2px"
								>
									~{avgFeeRate}
								</Text>

								<Text
									fontSize="80%"
									fontWeight="semibold"
									color={colorAlt}
								>
									&nbsp;SATS/vB
								</Text>
							</Flex>

							{highFee !== '0' && (
								<Flex>
									<Text
										fontWeight="normal"
										color={colorAlt}
									>
										&nbsp;[
									</Text>

									<Text fontWeight="semibold">{lowFee}</Text>

									<Text
										fontWeight="normal"
										color={colorAlt}
									>
										&nbsp;-&nbsp;
									</Text>

									<Text fontWeight="semibold">{highFee}</Text>

									<Text
										fontWeight="normal"
										color={colorAlt}
									>
										]
									</Text>
								</Flex>
							)}
						</Flex>
					</Flex>
				)}
			</Flex>
		</Flex>
	)
}
