import { Flex, Box } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { circlesBreakpointState } from '../../../state'
import { TCTitle } from '../TCTitle'
import { TCStatus } from '../TCStatus'
import { getBreakpointValue } from '../../../utils'

type Connection = {
	readyState: number
}

interface TitleProps {
	connection: Connection
}

const EmptyStatusBox = () => {
	return (
		<Box w={8} alignSelf="stretch">&nbsp;</Box>
	)
}

export const TitleAndStatus = ({ connection }: TitleProps) => {
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)

	const responsiveTop = getBreakpointValue({
		base: '28%',
		xxs: '26%',
		sm: '17.75%',
		lg: '18.5%',
		xl: '18.7%',
		xxl: '18.5%',
		xxxl: '19.5%',
	}, circlesBreakpoint)
	const responsiveLeft = getBreakpointValue({ base: '30%%', xl: '36%' }, circlesBreakpoint)
	const responsiveMarginBottom = getBreakpointValue({ base: '2px', xs: 1, md: 2, lg: '10px', xl: 4 }, circlesBreakpoint)

	return (
		<Flex
			className="tc-title-and-status"
			direction="column"
			justify="center"
			align="center"
			pos="absolute"
			top={responsiveTop}
			left={responsiveLeft}
			right={responsiveLeft}
			zIndex={2000}
		>
			<Flex
				justify={{ base: 'flex-start', xxxs: 'space-between' }}
				align="center"
				mb={responsiveMarginBottom}
				h={{ base: 4, xxs: 8 }}
				gap={1}
			>
				<EmptyStatusBox />

				<TCStatus connection={connection} />

				<EmptyStatusBox />
			</Flex>

			<TCTitle />
		</Flex>
	)
}
