import { Box, useColorModeValue } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { useAppSelector } from '../../../hooks'
import { circlesBreakpointState } from '../../../state'
import { getBreakpointValue } from '../../../utils'
import { rtlLocales } from '../../../lang/messages'

export interface TextPathCircleProps {
	containerWidth: number
	circleWidth: number
	text: string
	id: string
	overrideTransform?: string
	fontSize?: string
}

const circleToPath = (containerWidth: number, circleWidth: number) => {
	const containerRadius = (containerWidth / 2) + 0.2
	const circleRadius = (circleWidth / 2) + 0.2                                          
	const circleOffset = containerWidth - circleWidth
	const circleOffsetHalf = circleOffset / 2

	return `
        M ${circleOffsetHalf},
        ${containerRadius} m ${circleWidth},
        0 a ${circleRadius},
        ${circleRadius} ${circleOffset} 1,
        1 -${(circleRadius * 2)},
        0 a ${circleRadius},
        ${circleRadius} ${circleOffset} 1,
        1 ${(circleRadius * 2)},
        0
    `
}

export const TextPathCircle = ({
	containerWidth,
	circleWidth,
	text,
	id,
	overrideTransform = '',
	fontSize = '',
}: TextPathCircleProps) => {
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const color = useColorModeValue('rgba(0,0,0,0.64)', 'rgba(255,255,255,0.64)')

	const isRtlLocale = rtlLocales.includes(userLocale)

	const memoFontSize = getBreakpointValue({
		base: '8px',
		sm: '9px',
		md: '10px',
		lg: '11px',
		xl: '12px',
		xxl: '13px',
		jumbo: '14px',
	}, circlesBreakpoint)
	const responsiveFontSize = fontSize
		? fontSize
		: memoFontSize

	const responsiveTransform = overrideTransform
		? overrideTransform
		: 'rotate(-90deg) translateX(7px) translateY(4px)'

	if (containerWidth > 0) {
		return (
			<Box
				w={containerWidth}
				h={containerWidth}
				pos="absolute"
				top={0}
				left={0}
				right={0}
				bottom={0}
				transform={responsiveTransform}
			>
				<svg viewBox={`0 0 ${containerWidth} ${containerWidth}`}>
					<defs>
						<path
							id={`textPathCircle-${id}`}
							d={circleToPath(containerWidth, circleWidth)}
						/>
					</defs>
					<text
						fill={color}
						style={{
							fontSize: responsiveFontSize,
							textTransform: 'uppercase',
							letterSpacing: isRtlLocale ? '1px' : undefined,
							fontStretch: isRtlLocale ? 'ultra-expanded' : undefined,
							unicodeBidi: isRtlLocale ? 'bidi-override' : undefined,
							direction: isRtlLocale ? 'ltr' : undefined,
						}}
					>
						<textPath
							xlinkHref={`#textPathCircle-${id}`}
							aria-label={text}
						>
							{text}
						</textPath>
					</text>
				</svg>
			</Box>
		)
	}

	return null
}
