// NEW LOCALE UPDATE HERE
import {
	ar,
	ca,
	de,
	enUS,
	es,
	fr,
	el,
	it,
	pl,
	pt,
	ru,
	sv,
	tr,
	zhCN,
} from 'date-fns/locale'

// NEW LOCALE UPDATE HERE
export const dateLocales = {
	ar: ar,
	ca: ca,
	de: de,
	en: enUS,
	es: es,
	fr: fr,
	gr: el,
	it: it,
	pl: pl,
	pt: pt,
	ru: ru,
	sv: sv,
	tr: tr,
	zh: zhCN,
}
